// Do not edit this file manually. Auto-generated by geist 'pnpm generate-logos'.

import _VercelLogoLightSvg from './vercel-logo/vercel-logo-light.svg';
import _VercelLogoDarkSvg from './vercel-logo/vercel-logo-dark.svg';
import _VercelLogotypeLightSvg from './vercel-logotype/vercel-logotype-light.svg';
import _VercelLogotypeDarkSvg from './vercel-logotype/vercel-logotype-dark.svg';
import _VercelLogotypeThinLightSvg from './vercel-logotype-thin/vercel-logotype-thin-light.svg';
import _VercelLogotypeThinDarkSvg from './vercel-logotype-thin/vercel-logotype-thin-dark.svg';
import _GeistLogoLightSvg from './geist-logo/geist-logo-light.svg';
import _GeistLogoDarkSvg from './geist-logo/geist-logo-dark.svg';
import _NextjsLogotypeLightSvg from './nextjs-logotype/nextjs-logotype-light.svg';
import _NextjsLogotypeDarkSvg from './nextjs-logotype/nextjs-logotype-dark.svg';
import _NextjsLogoLightSvg from './nextjs-logo/nextjs-logo-light.svg';
import _NextjsLogoDarkSvg from './nextjs-logo/nextjs-logo-dark.svg';
import _TurboLogotypeColorLightSvg from './turbo-logotype/turbo-logotype-color-light.svg';
import _TurboLogotypeLightSvg from './turbo-logotype/turbo-logotype-light.svg';
import _TurboLogotypeColorDarkSvg from './turbo-logotype/turbo-logotype-color-dark.svg';
import _TurboLogotypeDarkSvg from './turbo-logotype/turbo-logotype-dark.svg';
import _TurborepoLogotypeColorLightSvg from './turborepo-logotype/turborepo-logotype-color-light.svg';
import _TurborepoLogotypeLightSvg from './turborepo-logotype/turborepo-logotype-light.svg';
import _TurborepoLogotypeColorDarkSvg from './turborepo-logotype/turborepo-logotype-color-dark.svg';
import _TurborepoLogotypeDarkSvg from './turborepo-logotype/turborepo-logotype-dark.svg';
import _TurbopackLogotypeColorLightSvg from './turbopack-logotype/turbopack-logotype-color-light.svg';
import _TurbopackLogotypeLightSvg from './turbopack-logotype/turbopack-logotype-light.svg';
import _TurbopackLogotypeColorDarkSvg from './turbopack-logotype/turbopack-logotype-color-dark.svg';
import _TurbopackLogotypeDarkSvg from './turbopack-logotype/turbopack-logotype-dark.svg';
import _TurboLogoColorLightSvg from './turbo-logo/turbo-logo-color-light.svg';
import _TurboLogoLightSvg from './turbo-logo/turbo-logo-light.svg';
import _TurboLogoColorDarkSvg from './turbo-logo/turbo-logo-color-dark.svg';
import _TurboLogoDarkSvg from './turbo-logo/turbo-logo-dark.svg';
import _TurbopackLogoColorLightSvg from './turbopack-logo/turbopack-logo-color-light.svg';
import _TurbopackLogoLightSvg from './turbopack-logo/turbopack-logo-light.svg';
import _TurbopackLogoColorDarkSvg from './turbopack-logo/turbopack-logo-color-dark.svg';
import _TurbopackLogoDarkSvg from './turbopack-logo/turbopack-logo-dark.svg';
import _HyperLogoLightSvg from './hyper-logo/hyper-logo-light.svg';
import _HyperLogoDarkSvg from './hyper-logo/hyper-logo-dark.svg';
import _VzeroLogoLightSvg from './vzero-logo/vzero-logo-light.svg';
import _VzeroLogoDarkSvg from './vzero-logo/vzero-logo-dark.svg';
import _ZeroxideLightSvg from './zeroxide/zeroxide-light.svg';
import _ZeroxideDarkSvg from './zeroxide/zeroxide-dark.svg';
import _EightVcLightSvg from './eight-vc/eight-vc-light.svg';
import _EightVcDarkSvg from './eight-vc/eight-vc-dark.svg';
import _OneZeroupColorLightSvg from './one-zeroup/one-zeroup-color-light.svg';
import _OneZeroupColorDarkSvg from './one-zeroup/one-zeroup-color-dark.svg';
import _OneZeroupLightSvg from './one-zeroup/one-zeroup-light.svg';
import _OneZeroupDarkSvg from './one-zeroup/one-zeroup-dark.svg';
import _OnezeroxGenomicsColorLightSvg from './onezerox-genomics/onezerox-genomics-color-light.svg';
import _OnezeroxGenomicsColorDarkSvg from './onezerox-genomics/onezerox-genomics-color-dark.svg';
import _OnezeroxGenomicsLightSvg from './onezerox-genomics/onezerox-genomics-light.svg';
import _OnezeroxGenomicsDarkSvg from './onezerox-genomics/onezerox-genomics-dark.svg';
import _OneOnetyLightSvg from './one-onety/one-onety-light.svg';
import _OneOnetyDarkSvg from './one-onety/one-onety-dark.svg';
import _ACloudGuruLightSvg from './a-cloud-guru/a-cloud-guru-light.svg';
import _ACloudGuruDarkSvg from './a-cloud-guru/a-cloud-guru-dark.svg';
import _AeNetworksLightSvg from './ae-networks/ae-networks-light.svg';
import _AeNetworksDarkSvg from './ae-networks/ae-networks-dark.svg';
import _AaaColorLightSvg from './aaa/aaa-color-light.svg';
import _AaaColorDarkSvg from './aaa/aaa-color-dark.svg';
import _AaaLightSvg from './aaa/aaa-light.svg';
import _AaaDarkSvg from './aaa/aaa-dark.svg';
import _AbnormalLightSvg from './abnormal/abnormal-light.svg';
import _AbnormalDarkSvg from './abnormal/abnormal-dark.svg';
import _AccelColorLightSvg from './accel/accel-color-light.svg';
import _AccelLightSvg from './accel/accel-light.svg';
import _AccelDarkSvg from './accel/accel-dark.svg';
import _AchmeaColorLightSvg from './achmea/achmea-color-light.svg';
import _AchmeaColorDarkSvg from './achmea/achmea-color-dark.svg';
import _AchmeaLightSvg from './achmea/achmea-light.svg';
import _AchmeaDarkSvg from './achmea/achmea-dark.svg';
import _AdeptColorLightSvg from './adept/adept-color-light.svg';
import _AdeptLightSvg from './adept/adept-light.svg';
import _AdeptDarkSvg from './adept/adept-dark.svg';
import _AdobeColorLightSvg from './adobe/adobe-color-light.svg';
import _AdobeColorDarkSvg from './adobe/adobe-color-dark.svg';
import _AdobeLightSvg from './adobe/adobe-light.svg';
import _AdobeDarkSvg from './adobe/adobe-dark.svg';
import _AeStudioColorLightSvg from './ae-studio/ae-studio-color-light.svg';
import _AeStudioLightSvg from './ae-studio/ae-studio-light.svg';
import _AeStudioColorDarkSvg from './ae-studio/ae-studio-color-dark.svg';
import _AeStudioDarkSvg from './ae-studio/ae-studio-dark.svg';
import _AgencyQLightSvg from './agency-q/agency-q-light.svg';
import _AgencyQDarkSvg from './agency-q/agency-q-dark.svg';
import _AirbnbColorLightSvg from './airbnb/airbnb-color-light.svg';
import _AirbnbLightSvg from './airbnb/airbnb-light.svg';
import _AirbnbDarkSvg from './airbnb/airbnb-dark.svg';
import _AkqaLightSvg from './akqa/akqa-light.svg';
import _AkqaDarkSvg from './akqa/akqa-dark.svg';
import _AlgoliaColorLightSvg from './algolia/algolia-color-light.svg';
import _AlgoliaColorDarkSvg from './algolia/algolia-color-dark.svg';
import _AlgoliaLightSvg from './algolia/algolia-light.svg';
import _AlgoliaDarkSvg from './algolia/algolia-dark.svg';
import _AmericanEagleComLightSvg from './american-eagle-com/american-eagle-com-light.svg';
import _AmericanEagleComDarkSvg from './american-eagle-com/american-eagle-com-dark.svg';
import _AmherstLightSvg from './amherst/amherst-light.svg';
import _AmherstDarkSvg from './amherst/amherst-dark.svg';
import _AmplitudeColorLightSvg from './amplitude/amplitude-color-light.svg';
import _AmplitudeLightSvg from './amplitude/amplitude-light.svg';
import _AmplitudeDarkSvg from './amplitude/amplitude-dark.svg';
import _AndersenColorLightSvg from './andersen/andersen-color-light.svg';
import _AndersenColorDarkSvg from './andersen/andersen-color-dark.svg';
import _AndersenLightSvg from './andersen/andersen-light.svg';
import _AndersenDarkSvg from './andersen/andersen-dark.svg';
import _AngularLogoColorLightSvg from './angular-logo/angular-logo-color-light.svg';
import _AngularLogoColorDarkSvg from './angular-logo/angular-logo-color-dark.svg';
import _AngularLogoLightSvg from './angular-logo/angular-logo-light.svg';
import _AngularLogoDarkSvg from './angular-logo/angular-logo-dark.svg';
import _AngularLogotypeColorLightSvg from './angular-logotype/angular-logotype-color-light.svg';
import _AngularLogotypeColorDarkSvg from './angular-logotype/angular-logotype-color-dark.svg';
import _AngularLogotypeLightSvg from './angular-logotype/angular-logotype-light.svg';
import _AngularLogotypeDarkSvg from './angular-logotype/angular-logotype-dark.svg';
import _AnthropicLightSvg from './anthropic/anthropic-light.svg';
import _AnthropicDarkSvg from './anthropic/anthropic-dark.svg';
import _ApplyDigitalLightSvg from './apply-digital/apply-digital-light.svg';
import _ApplyDigitalDarkSvg from './apply-digital/apply-digital-dark.svg';
import _AppnovationLightSvg from './appnovation/appnovation-light.svg';
import _AppnovationDarkSvg from './appnovation/appnovation-dark.svg';
import _AprilLightSvg from './april/april-light.svg';
import _AprilDarkSvg from './april/april-dark.svg';
import _ArcLightSvg from './arc/arc-light.svg';
import _ArcDarkSvg from './arc/arc-dark.svg';
import _ArcherLightSvg from './archer/archer-light.svg';
import _ArcherDarkSvg from './archer/archer-dark.svg';
import _AsicsColorLightSvg from './asics/asics-color-light.svg';
import _AsicsLightSvg from './asics/asics-light.svg';
import _AsicsDarkSvg from './asics/asics-dark.svg';
import _AsmlColorLightSvg from './asml/asml-color-light.svg';
import _AsmlLightSvg from './asml/asml-light.svg';
import _AsmlDarkSvg from './asml/asml-dark.svg';
import _AstroLogoColorLightSvg from './astro-logo/astro-logo-color-light.svg';
import _AstroLogoColorDarkSvg from './astro-logo/astro-logo-color-dark.svg';
import _AstroLogoLightSvg from './astro-logo/astro-logo-light.svg';
import _AstroLogoDarkSvg from './astro-logo/astro-logo-dark.svg';
import _AstroLogotypeColorLightSvg from './astro-logotype/astro-logotype-color-light.svg';
import _AstroLogotypeColorDarkSvg from './astro-logotype/astro-logotype-color-dark.svg';
import _AstroLogotypeLightSvg from './astro-logotype/astro-logotype-light.svg';
import _AstroLogotypeDarkSvg from './astro-logotype/astro-logotype-dark.svg';
import _AsurionLightSvg from './asurion/asurion-light.svg';
import _AsurionDarkSvg from './asurion/asurion-dark.svg';
import _AsyncLightSvg from './async/async-light.svg';
import _AsyncDarkSvg from './async/async-dark.svg';
import _AthleticGreensColorLightSvg from './athletic-greens/athletic-greens-color-light.svg';
import _AthleticGreensLightSvg from './athletic-greens/athletic-greens-light.svg';
import _AthleticGreensDarkSvg from './athletic-greens/athletic-greens-dark.svg';
import _AthleticGreensAgoneColorLightSvg from './athletic-greens-agone/athletic-greens-agone-color-light.svg';
import _AthleticGreensAgoneColorDarkSvg from './athletic-greens-agone/athletic-greens-agone-color-dark.svg';
import _AthleticGreensAgoneLightSvg from './athletic-greens-agone/athletic-greens-agone-light.svg';
import _AthleticGreensAgoneDarkSvg from './athletic-greens-agone/athletic-greens-agone-dark.svg';
import _AumniLightSvg from './aumni/aumni-light.svg';
import _AumniDarkSvg from './aumni/aumni-dark.svg';
import _AuthZeroLightSvg from './auth-zero/auth-zero-light.svg';
import _AuthZeroDarkSvg from './auth-zero/auth-zero-dark.svg';
import _AutomatticLightSvg from './automattic/automattic-light.svg';
import _AutomatticDarkSvg from './automattic/automattic-dark.svg';
import _AvanadeColorLightSvg from './avanade/avanade-color-light.svg';
import _AvanadeColorDarkSvg from './avanade/avanade-color-dark.svg';
import _AvanadeLightSvg from './avanade/avanade-light.svg';
import _AvanadeDarkSvg from './avanade/avanade-dark.svg';
import _AwsColorLightSvg from './aws/aws-color-light.svg';
import _AwsColorDarkSvg from './aws/aws-color-dark.svg';
import _AwsLightSvg from './aws/aws-light.svg';
import _AwsDarkSvg from './aws/aws-dark.svg';
import _AxelArigatoLightSvg from './axel-arigato/axel-arigato-light.svg';
import _AxelArigatoDarkSvg from './axel-arigato/axel-arigato-dark.svg';
import _AxiomLightSvg from './axiom/axiom-light.svg';
import _AxiomDarkSvg from './axiom/axiom-dark.svg';
import _AzureColorLightSvg from './azure/azure-color-light.svg';
import _AzureColorDarkSvg from './azure/azure-color-dark.svg';
import _AzureLightSvg from './azure/azure-light.svg';
import _AzureDarkSvg from './azure/azure-dark.svg';
import _BananaLightSvg from './banana/banana-light.svg';
import _BananaDarkSvg from './banana/banana-dark.svg';
import _BarnebysLightSvg from './barnebys/barnebys-light.svg';
import _BarnebysDarkSvg from './barnebys/barnebys-dark.svg';
import _BarstoolSportsLightSvg from './barstool-sports/barstool-sports-light.svg';
import _BarstoolSportsDarkSvg from './barstool-sports/barstool-sports-dark.svg';
import _BasementColorLightSvg from './basement/basement-color-light.svg';
import _BasementColorDarkSvg from './basement/basement-color-dark.svg';
import _BasementLightSvg from './basement/basement-light.svg';
import _BasementDarkSvg from './basement/basement-dark.svg';
import _BayerColorLightSvg from './bayer/bayer-color-light.svg';
import _BayerColorDarkSvg from './bayer/bayer-color-dark.svg';
import _BayerLightSvg from './bayer/bayer-light.svg';
import _BayerDarkSvg from './bayer/bayer-dark.svg';
import _BcgLightSvg from './bcg/bcg-light.svg';
import _BcgDarkSvg from './bcg/bcg-dark.svg';
import _BedrockLightSvg from './bedrock/bedrock-light.svg';
import _BedrockDarkSvg from './bedrock/bedrock-dark.svg';
import _BigCommerceColorLightSvg from './big-commerce/big-commerce-color-light.svg';
import _BigCommerceLightSvg from './big-commerce/big-commerce-light.svg';
import _BigCommerceDarkSvg from './big-commerce/big-commerce-dark.svg';
import _BlackmoresLightSvg from './blackmores/blackmores-light.svg';
import _BlackmoresDarkSvg from './blackmores/blackmores-dark.svg';
import _BlitzLogoColorLightSvg from './blitz-logo/blitz-logo-color-light.svg';
import _BlitzLogoColorDarkSvg from './blitz-logo/blitz-logo-color-dark.svg';
import _BlitzLogoLightSvg from './blitz-logo/blitz-logo-light.svg';
import _BlitzLogoDarkSvg from './blitz-logo/blitz-logo-dark.svg';
import _BlitzLogotypeColorLightSvg from './blitz-logotype/blitz-logotype-color-light.svg';
import _BlitzLogotypeColorDarkSvg from './blitz-logotype/blitz-logotype-color-dark.svg';
import _BlitzLogotypeLightSvg from './blitz-logotype/blitz-logotype-light.svg';
import _BlitzLogotypeDarkSvg from './blitz-logotype/blitz-logotype-dark.svg';
import _BluecrossBlueshieldArizonaColorLightSvg from './bluecross-blueshield-arizona/bluecross-blueshield-arizona-color-light.svg';
import _BluecrossBlueshieldArizonaColorDarkSvg from './bluecross-blueshield-arizona/bluecross-blueshield-arizona-color-dark.svg';
import _BluecrossBlueshieldArizonaLightSvg from './bluecross-blueshield-arizona/bluecross-blueshield-arizona-light.svg';
import _BluecrossBlueshieldArizonaDarkSvg from './bluecross-blueshield-arizona/bluecross-blueshield-arizona-dark.svg';
import _BlushColorLightSvg from './blush/blush-color-light.svg';
import _BlushLightSvg from './blush/blush-light.svg';
import _BlushColorDarkSvg from './blush/blush-color-dark.svg';
import _BlushDarkSvg from './blush/blush-dark.svg';
import _BoltLightSvg from './bolt/bolt-light.svg';
import _BoltDarkSvg from './bolt/bolt-dark.svg';
import _BombasColorLightSvg from './bombas/bombas-color-light.svg';
import _BombasColorDarkSvg from './bombas/bombas-color-dark.svg';
import _BombasLightSvg from './bombas/bombas-light.svg';
import _BombasDarkSvg from './bombas/bombas-dark.svg';
import _BoomLightSvg from './boom/boom-light.svg';
import _BoomDarkSvg from './boom/boom-dark.svg';
import _BoseLightSvg from './bose/bose-light.svg';
import _BoseDarkSvg from './bose/bose-dark.svg';
import _BoxColorLightSvg from './box/box-color-light.svg';
import _BoxLightSvg from './box/box-light.svg';
import _BoxDarkSvg from './box/box-dark.svg';
import _BrandungLightSvg from './brandung/brandung-light.svg';
import _BrandungDarkSvg from './brandung/brandung-dark.svg';
import _BrexLightSvg from './brex/brex-light.svg';
import _BrexDarkSvg from './brex/brex-dark.svg';
import _BrioniLightSvg from './brioni/brioni-light.svg';
import _BrioniDarkSvg from './brioni/brioni-dark.svg';
import _BrowserCompanyColorLightSvg from './browser-company/browser-company-color-light.svg';
import _BrowserCompanyLightSvg from './browser-company/browser-company-light.svg';
import _BrowserCompanyDarkSvg from './browser-company/browser-company-dark.svg';
import _BrunchColorLightSvg from './brunch/brunch-color-light.svg';
import _BrunchColorDarkSvg from './brunch/brunch-color-dark.svg';
import _BrunchLightSvg from './brunch/brunch-light.svg';
import _BrunchDarkSvg from './brunch/brunch-dark.svg';
import _BrunchLogotypeColorLightSvg from './brunch-logotype/brunch-logotype-color-light.svg';
import _BrunchLogotypeColorDarkSvg from './brunch-logotype/brunch-logotype-color-dark.svg';
import _BrunchLogotypeLightSvg from './brunch-logotype/brunch-logotype-light.svg';
import _BrunchLogotypeDarkSvg from './brunch-logotype/brunch-logotype-dark.svg';
import _ByjuSLightSvg from './byju-s/byju-s-light.svg';
import _ByjuSDarkSvg from './byju-s/byju-s-dark.svg';
import _CaffeinatedCapitalLightSvg from './caffeinated-capital/caffeinated-capital-light.svg';
import _CaffeinatedCapitalDarkSvg from './caffeinated-capital/caffeinated-capital-dark.svg';
import _CaloptimaHealthColorLightSvg from './caloptima-health/caloptima-health-color-light.svg';
import _CaloptimaHealthColorDarkSvg from './caloptima-health/caloptima-health-color-dark.svg';
import _CaloptimaHealthLightSvg from './caloptima-health/caloptima-health-light.svg';
import _CaloptimaHealthDarkSvg from './caloptima-health/caloptima-health-dark.svg';
import _CampaignMonitorLightSvg from './campaign-monitor/campaign-monitor-light.svg';
import _CampaignMonitorDarkSvg from './campaign-monitor/campaign-monitor-dark.svg';
import _CandorLightSvg from './candor/candor-light.svg';
import _CandorDarkSvg from './candor/candor-dark.svg';
import _CapsuleColorLightSvg from './capsule/capsule-color-light.svg';
import _CapsuleColorDarkSvg from './capsule/capsule-color-dark.svg';
import _CapsuleLightSvg from './capsule/capsule-light.svg';
import _CapsuleDarkSvg from './capsule/capsule-dark.svg';
import _CarharttLightSvg from './carhartt/carhartt-light.svg';
import _CarharttDarkSvg from './carhartt/carhartt-dark.svg';
import _ChainguardColorLightSvg from './chainguard/chainguard-color-light.svg';
import _ChainguardColorDarkSvg from './chainguard/chainguard-color-dark.svg';
import _ChainguardLightSvg from './chainguard/chainguard-light.svg';
import _ChainguardDarkSvg from './chainguard/chainguard-dark.svg';
import _ChangeLingerieLightSvg from './change-lingerie/change-lingerie-light.svg';
import _ChangeLingerieDarkSvg from './change-lingerie/change-lingerie-dark.svg';
import _CharlesRiverVenturesColorLightSvg from './charles-river-ventures/charles-river-ventures-color-light.svg';
import _CharlesRiverVenturesColorDarkSvg from './charles-river-ventures/charles-river-ventures-color-dark.svg';
import _CharlesRiverVenturesLightSvg from './charles-river-ventures/charles-river-ventures-light.svg';
import _CharlesRiverVenturesDarkSvg from './charles-river-ventures/charles-river-ventures-dark.svg';
import _ChatbaseLightSvg from './chatbase/chatbase-light.svg';
import _ChatbaseDarkSvg from './chatbase/chatbase-dark.svg';
import _CheckoutComLightSvg from './checkout-com/checkout-com-light.svg';
import _CheckoutComDarkSvg from './checkout-com/checkout-com-dark.svg';
import _CheggColorLightSvg from './chegg/chegg-color-light.svg';
import _CheggColorDarkSvg from './chegg/chegg-color-dark.svg';
import _CheggLightSvg from './chegg/chegg-light.svg';
import _CheggDarkSvg from './chegg/chegg-dark.svg';
import _ChickFilAColorLightSvg from './chick-fil-a/chick-fil-a-color-light.svg';
import _ChickFilAColorDarkSvg from './chick-fil-a/chick-fil-a-color-dark.svg';
import _ChickFilALightSvg from './chick-fil-a/chick-fil-a-light.svg';
import _ChickFilADarkSvg from './chick-fil-a/chick-fil-a-dark.svg';
import _ChicosLightSvg from './chicos/chicos-light.svg';
import _ChicosDarkSvg from './chicos/chicos-dark.svg';
import _ChildrensNationalColorLightSvg from './childrens-national/childrens-national-color-light.svg';
import _ChildrensNationalColorDarkSvg from './childrens-national/childrens-national-color-dark.svg';
import _ChildrensNationalLightSvg from './childrens-national/childrens-national-light.svg';
import _ChildrensNationalDarkSvg from './childrens-national/childrens-national-dark.svg';
import _ChipotleColorLightSvg from './chipotle/chipotle-color-light.svg';
import _ChipotleColorDarkSvg from './chipotle/chipotle-color-dark.svg';
import _ChipotleLightSvg from './chipotle/chipotle-light.svg';
import _ChipotleDarkSvg from './chipotle/chipotle-dark.svg';
import _ChiselstrikeLightSvg from './chiselstrike/chiselstrike-light.svg';
import _ChiselstrikeDarkSvg from './chiselstrike/chiselstrike-dark.svg';
import _ChromaLightSvg from './chroma/chroma-light.svg';
import _ChromaDarkSvg from './chroma/chroma-dark.svg';
import _ClerkColorLightSvg from './clerk/clerk-color-light.svg';
import _ClerkColorDarkSvg from './clerk/clerk-color-dark.svg';
import _ClerkLightSvg from './clerk/clerk-light.svg';
import _ClerkDarkSvg from './clerk/clerk-dark.svg';
import _CloudflareColorLightSvg from './cloudflare/cloudflare-color-light.svg';
import _CloudflareLightSvg from './cloudflare/cloudflare-light.svg';
import _CloudflareColorDarkSvg from './cloudflare/cloudflare-color-dark.svg';
import _CloudflareDarkSvg from './cloudflare/cloudflare-dark.svg';
import _CoOpColorLightSvg from './co-op/co-op-color-light.svg';
import _CoOpColorDarkSvg from './co-op/co-op-color-dark.svg';
import _CoOpLightSvg from './co-op/co-op-light.svg';
import _CoOpDarkSvg from './co-op/co-op-dark.svg';
import _CodeAndTheoryLightSvg from './code-and-theory/code-and-theory-light.svg';
import _CodeAndTheoryDarkSvg from './code-and-theory/code-and-theory-dark.svg';
import _CohereColorLightSvg from './cohere/cohere-color-light.svg';
import _CohereColorDarkSvg from './cohere/cohere-color-dark.svg';
import _CohereLightSvg from './cohere/cohere-light.svg';
import _CohereDarkSvg from './cohere/cohere-dark.svg';
import _CoinDeskColorLightSvg from './coin-desk/coin-desk-color-light.svg';
import _CoinDeskColorDarkSvg from './coin-desk/coin-desk-color-dark.svg';
import _CoinDeskLightSvg from './coin-desk/coin-desk-light.svg';
import _CoinDeskDarkSvg from './coin-desk/coin-desk-dark.svg';
import _CommerceUiLightSvg from './commerce-ui/commerce-ui-light.svg';
import _CommerceUiDarkSvg from './commerce-ui/commerce-ui-dark.svg';
import _CommerceJsLightSvg from './commerce-js/commerce-js-light.svg';
import _CommerceJsDarkSvg from './commerce-js/commerce-js-dark.svg';
import _ContentfulColorLightSvg from './contentful/contentful-color-light.svg';
import _ContentfulColorDarkSvg from './contentful/contentful-color-dark.svg';
import _ContentfulLightSvg from './contentful/contentful-light.svg';
import _ContentfulDarkSvg from './contentful/contentful-dark.svg';
import _ConvexLightSvg from './convex/convex-light.svg';
import _ConvexDarkSvg from './convex/convex-dark.svg';
import _CoravinLightSvg from './coravin/coravin-light.svg';
import _CoravinDarkSvg from './coravin/coravin-dark.svg';
import _CorpayColorLightSvg from './corpay/corpay-color-light.svg';
import _CorpayColorDarkSvg from './corpay/corpay-color-dark.svg';
import _CorpayLightSvg from './corpay/corpay-light.svg';
import _CorpayDarkSvg from './corpay/corpay-dark.svg';
import _CorraLightSvg from './corra/corra-light.svg';
import _CorraDarkSvg from './corra/corra-dark.svg';
import _CountryRoadGrouipLightSvg from './country-road-grouip/country-road-grouip-light.svg';
import _CountryRoadGrouipDarkSvg from './country-road-grouip/country-road-grouip-dark.svg';
import _CovermymedsLightSvg from './covermymeds/covermymeds-light.svg';
import _CovermymedsDarkSvg from './covermymeds/covermymeds-dark.svg';
import _CruiseCriticColorLightSvg from './cruise-critic/cruise-critic-color-light.svg';
import _CruiseCriticColorDarkSvg from './cruise-critic/cruise-critic-color-dark.svg';
import _CruiseCriticLightSvg from './cruise-critic/cruise-critic-light.svg';
import _CruiseCriticDarkSvg from './cruise-critic/cruise-critic-dark.svg';
import _CrystallizeLightSvg from './crystallize/crystallize-light.svg';
import _CrystallizeDarkSvg from './crystallize/crystallize-dark.svg';
import _DagsterColorLightSvg from './dagster/dagster-color-light.svg';
import _DagsterColorDarkSvg from './dagster/dagster-color-dark.svg';
import _DagsterLightSvg from './dagster/dagster-light.svg';
import _DagsterDarkSvg from './dagster/dagster-dark.svg';
import _DashlaneColorLightSvg from './dashlane/dashlane-color-light.svg';
import _DashlaneColorDarkSvg from './dashlane/dashlane-color-dark.svg';
import _DashlaneLightSvg from './dashlane/dashlane-light.svg';
import _DashlaneDarkSvg from './dashlane/dashlane-dark.svg';
import _DatabricksColorLightSvg from './databricks/databricks-color-light.svg';
import _DatabricksColorDarkSvg from './databricks/databricks-color-dark.svg';
import _DatabricksLightSvg from './databricks/databricks-light.svg';
import _DatabricksDarkSvg from './databricks/databricks-dark.svg';
import _DatadogColorLightSvg from './datadog/datadog-color-light.svg';
import _DatadogColorDarkSvg from './datadog/datadog-color-dark.svg';
import _DatadogLightSvg from './datadog/datadog-light.svg';
import _DatadogDarkSvg from './datadog/datadog-dark.svg';
import _DatastaxLightSvg from './datastax/datastax-light.svg';
import _DatastaxDarkSvg from './datastax/datastax-dark.svg';
import _DatoColorLightSvg from './dato/dato-color-light.svg';
import _DatoColorDarkSvg from './dato/dato-color-dark.svg';
import _DatoLightSvg from './dato/dato-light.svg';
import _DatoDarkSvg from './dato/dato-dark.svg';
import _DenaLightSvg from './dena/dena-light.svg';
import _DenaDarkSvg from './dena/dena-dark.svg';
import _DescopeColorLightSvg from './descope/descope-color-light.svg';
import _DescopeColorDarkSvg from './descope/descope-color-dark.svg';
import _DescopeLightSvg from './descope/descope-light.svg';
import _DescopeDarkSvg from './descope/descope-dark.svg';
import _DesenioLightSvg from './desenio/desenio-light.svg';
import _DesenioDarkSvg from './desenio/desenio-dark.svg';
import _DevolverDigitalColorLightSvg from './devolver-digital/devolver-digital-color-light.svg';
import _DevolverDigitalColorDarkSvg from './devolver-digital/devolver-digital-color-dark.svg';
import _DevolverDigitalLightSvg from './devolver-digital/devolver-digital-light.svg';
import _DevolverDigitalDarkSvg from './devolver-digital/devolver-digital-dark.svg';
import _DexertoLightSvg from './dexerto/dexerto-light.svg';
import _DexertoDarkSvg from './dexerto/dexerto-dark.svg';
import _DialpadColorLightSvg from './dialpad/dialpad-color-light.svg';
import _DialpadColorDarkSvg from './dialpad/dialpad-color-dark.svg';
import _DialpadLightSvg from './dialpad/dialpad-light.svg';
import _DialpadDarkSvg from './dialpad/dialpad-dark.svg';
import _DiceLightSvg from './dice/dice-light.svg';
import _DiceDarkSvg from './dice/dice-dark.svg';
import _DisneyLightSvg from './disney/disney-light.svg';
import _DisneyDarkSvg from './disney/disney-dark.svg';
import _DocusaurusColorLightSvg from './docusaurus/docusaurus-color-light.svg';
import _DocusaurusColorDarkSvg from './docusaurus/docusaurus-color-dark.svg';
import _DocusaurusLightSvg from './docusaurus/docusaurus-light.svg';
import _DocusaurusDarkSvg from './docusaurus/docusaurus-dark.svg';
import _DogoLogoColorLightSvg from './dogo-logo/dogo-logo-color-light.svg';
import _DogoLogoColorDarkSvg from './dogo-logo/dogo-logo-color-dark.svg';
import _DogoLogoLightSvg from './dogo-logo/dogo-logo-light.svg';
import _DogoLogoDarkSvg from './dogo-logo/dogo-logo-dark.svg';
import _DojoLogotypeColorLightSvg from './dojo-logotype/dojo-logotype-color-light.svg';
import _DojoLogotypeColorDarkSvg from './dojo-logotype/dojo-logotype-color-dark.svg';
import _DojoLogotypeLightSvg from './dojo-logotype/dojo-logotype-light.svg';
import _DojoLogotypeDarkSvg from './dojo-logotype/dojo-logotype-dark.svg';
import _DubLightSvg from './dub/dub-light.svg';
import _DubDarkSvg from './dub/dub-dark.svg';
import _EbayColorLightSvg from './ebay/ebay-color-light.svg';
import _EbayColorDarkSvg from './ebay/ebay-color-dark.svg';
import _EbayLightSvg from './ebay/ebay-light.svg';
import _EbayDarkSvg from './ebay/ebay-dark.svg';
import _EconomistColorLightSvg from './economist/economist-color-light.svg';
import _EconomistColorDarkSvg from './economist/economist-color-dark.svg';
import _EconomistLightSvg from './economist/economist-light.svg';
import _EconomistDarkSvg from './economist/economist-dark.svg';
import _ElasticColorLightSvg from './elastic/elastic-color-light.svg';
import _ElasticLightSvg from './elastic/elastic-light.svg';
import _ElasticColorDarkSvg from './elastic/elastic-color-dark.svg';
import _ElasticDarkSvg from './elastic/elastic-dark.svg';
import _ElevenlabsLightSvg from './elevenlabs/elevenlabs-light.svg';
import _ElevenlabsDarkSvg from './elevenlabs/elevenlabs-dark.svg';
import _ElkjopColorLightSvg from './elkjop/elkjop-color-light.svg';
import _ElkjopColorDarkSvg from './elkjop/elkjop-color-dark.svg';
import _ElkjopLightSvg from './elkjop/elkjop-light.svg';
import _ElkjopDarkSvg from './elkjop/elkjop-dark.svg';
import _EmberColorLightSvg from './ember/ember-color-light.svg';
import _EmberColorDarkSvg from './ember/ember-color-dark.svg';
import _EmberLightSvg from './ember/ember-light.svg';
import _EmberDarkSvg from './ember/ember-dark.svg';
import _EncompassHealthColorLightSvg from './encompass-health/encompass-health-color-light.svg';
import _EncompassHealthColorDarkSvg from './encompass-health/encompass-health-color-dark.svg';
import _EncompassHealthLightSvg from './encompass-health/encompass-health-light.svg';
import _EncompassHealthDarkSvg from './encompass-health/encompass-health-dark.svg';
import _EndearColorLightSvg from './endear/endear-color-light.svg';
import _EndearColorDarkSvg from './endear/endear-color-dark.svg';
import _EndearLightSvg from './endear/endear-light.svg';
import _EndearDarkSvg from './endear/endear-dark.svg';
import _EnsoColorLightSvg from './enso/enso-color-light.svg';
import _EnsoLightSvg from './enso/enso-light.svg';
import _EnsoDarkSvg from './enso/enso-dark.svg';
import _ErieInsuranceColorLightSvg from './erie-insurance/erie-insurance-color-light.svg';
import _ErieInsuranceColorDarkSvg from './erie-insurance/erie-insurance-color-dark.svg';
import _ErieInsuranceLightSvg from './erie-insurance/erie-insurance-light.svg';
import _ErieInsuranceDarkSvg from './erie-insurance/erie-insurance-dark.svg';
import _EventbriteColorLightSvg from './eventbrite/eventbrite-color-light.svg';
import _EventbriteColorDarkSvg from './eventbrite/eventbrite-color-dark.svg';
import _EventbriteLightSvg from './eventbrite/eventbrite-light.svg';
import _EventbriteDarkSvg from './eventbrite/eventbrite-dark.svg';
import _ExtraSpaceStorageColorLightSvg from './extra-space-storage/extra-space-storage-color-light.svg';
import _ExtraSpaceStorageColorDarkSvg from './extra-space-storage/extra-space-storage-color-dark.svg';
import _ExtraSpaceStorageLightSvg from './extra-space-storage/extra-space-storage-light.svg';
import _ExtraSpaceStorageDarkSvg from './extra-space-storage/extra-space-storage-dark.svg';
import _FaunaLightSvg from './fauna/fauna-light.svg';
import _FaunaDarkSvg from './fauna/fauna-dark.svg';
import _FeyLightSvg from './fey/fey-light.svg';
import _FeyDarkSvg from './fey/fey-dark.svg';
import _FfwColorLightSvg from './ffw/ffw-color-light.svg';
import _FfwLightSvg from './ffw/ffw-light.svg';
import _FfwColorDarkSvg from './ffw/ffw-color-dark.svg';
import _FfwDarkSvg from './ffw/ffw-dark.svg';
import _FinaryColorLightSvg from './finary/finary-color-light.svg';
import _FinaryColorDarkSvg from './finary/finary-color-dark.svg';
import _FinaryLightSvg from './finary/finary-light.svg';
import _FinaryDarkSvg from './finary/finary-dark.svg';
import _FinsburyGloverHeringLightSvg from './finsbury-glover-hering/finsbury-glover-hering-light.svg';
import _FinsburyGloverHeringDarkSvg from './finsbury-glover-hering/finsbury-glover-hering-dark.svg';
import _FoundationLightSvg from './foundation/foundation-light.svg';
import _FoundationDarkSvg from './foundation/foundation-dark.svg';
import _FramerLightSvg from './framer/framer-light.svg';
import _FramerDarkSvg from './framer/framer-dark.svg';
import _FtdLightSvg from './ftd/ftd-light.svg';
import _FtdDarkSvg from './ftd/ftd-dark.svg';
import _FueledColorLightSvg from './fueled/fueled-color-light.svg';
import _FueledColorDarkSvg from './fueled/fueled-color-dark.svg';
import _FueledLightSvg from './fueled/fueled-light.svg';
import _FueledDarkSvg from './fueled/fueled-dark.svg';
import _GatsbyLogoColorLightSvg from './gatsby-logo/gatsby-logo-color-light.svg';
import _GatsbyLogoColorDarkSvg from './gatsby-logo/gatsby-logo-color-dark.svg';
import _GatsbyLogoLightSvg from './gatsby-logo/gatsby-logo-light.svg';
import _GatsbyLogoDarkSvg from './gatsby-logo/gatsby-logo-dark.svg';
import _GatsbyLogotypeColorLightSvg from './gatsby-logotype/gatsby-logotype-color-light.svg';
import _GatsbyLogotypeColorDarkSvg from './gatsby-logotype/gatsby-logotype-color-dark.svg';
import _GatsbyLogotypeLightSvg from './gatsby-logotype/gatsby-logotype-light.svg';
import _GatsbyLogotypeDarkSvg from './gatsby-logotype/gatsby-logotype-dark.svg';
import _GearboxLightSvg from './gearbox/gearbox-light.svg';
import _GearboxDarkSvg from './gearbox/gearbox-dark.svg';
import _GeodesicCapitalLightSvg from './geodesic-capital/geodesic-capital-light.svg';
import _GeodesicCapitalDarkSvg from './geodesic-capital/geodesic-capital-dark.svg';
import _GgvCapitalLightSvg from './ggv-capital/ggv-capital-light.svg';
import _GgvCapitalDarkSvg from './ggv-capital/ggv-capital-dark.svg';
import _GhandaLightSvg from './ghanda/ghanda-light.svg';
import _GhandaDarkSvg from './ghanda/ghanda-dark.svg';
import _GithubLogoColorLightSvg from './github-logo/github-logo-color-light.svg';
import _GithubLogoLightSvg from './github-logo/github-logo-light.svg';
import _GithubLogoDarkSvg from './github-logo/github-logo-dark.svg';
import _GithubLogotypeColorLightSvg from './github-logotype/github-logotype-color-light.svg';
import _GithubLogotypeLightSvg from './github-logotype/github-logotype-light.svg';
import _GithubLogotypeDarkSvg from './github-logotype/github-logotype-dark.svg';
import _GithubLogotypeColorDarkSvg from './github-logotype/github-logotype-color-dark.svg';
import _GlidePublishingPlatformColorLightSvg from './glide-publishing-platform/glide-publishing-platform-color-light.svg';
import _GlidePublishingPlatformLightSvg from './glide-publishing-platform/glide-publishing-platform-light.svg';
import _GlidePublishingPlatformColorDarkSvg from './glide-publishing-platform/glide-publishing-platform-color-dark.svg';
import _GlidePublishingPlatformDarkSvg from './glide-publishing-platform/glide-publishing-platform-dark.svg';
import _GlobacoreLightSvg from './globacore/globacore-light.svg';
import _GlobacoreDarkSvg from './globacore/globacore-dark.svg';
import _GlobalRetailBrandsAustraliaColorLightSvg from './global-retail-brands-australia/global-retail-brands-australia-color-light.svg';
import _GlobalRetailBrandsAustraliaColorDarkSvg from './global-retail-brands-australia/global-retail-brands-australia-color-dark.svg';
import _GlobalRetailBrandsAustraliaLightSvg from './global-retail-brands-australia/global-retail-brands-australia-light.svg';
import _GlobalRetailBrandsAustraliaDarkSvg from './global-retail-brands-australia/global-retail-brands-australia-dark.svg';
import _GncColorLightSvg from './gnc/gnc-color-light.svg';
import _GncColorDarkSvg from './gnc/gnc-color-dark.svg';
import _GncLightSvg from './gnc/gnc-light.svg';
import _GncDarkSvg from './gnc/gnc-dark.svg';
import _GohealthUrgentCareColorLightSvg from './gohealth-urgent-care/gohealth-urgent-care-color-light.svg';
import _GohealthUrgentCareColorDarkSvg from './gohealth-urgent-care/gohealth-urgent-care-color-dark.svg';
import _GohealthUrgentCareLightSvg from './gohealth-urgent-care/gohealth-urgent-care-light.svg';
import _GohealthUrgentCareDarkSvg from './gohealth-urgent-care/gohealth-urgent-care-dark.svg';
import _GoogleCloudPlatformColorLightSvg from './google-cloud-platform/google-cloud-platform-color-light.svg';
import _GoogleCloudPlatformColorDarkSvg from './google-cloud-platform/google-cloud-platform-color-dark.svg';
import _GoogleCloudPlatformLightSvg from './google-cloud-platform/google-cloud-platform-light.svg';
import _GoogleCloudPlatformDarkSvg from './google-cloud-platform/google-cloud-platform-dark.svg';
import _GoogleVenturesLightSvg from './google-ventures/google-ventures-light.svg';
import _GoogleVenturesDarkSvg from './google-ventures/google-ventures-dark.svg';
import _GraphCmsLightSvg from './graph-cms/graph-cms-light.svg';
import _GraphCmsDarkSvg from './graph-cms/graph-cms-dark.svg';
import _GreenoaksLightSvg from './greenoaks/greenoaks-light.svg';
import _GreenoaksDarkSvg from './greenoaks/greenoaks-dark.svg';
import _GridsomeLogoColorLightSvg from './gridsome-logo/gridsome-logo-color-light.svg';
import _GridsomeLogoColorDarkSvg from './gridsome-logo/gridsome-logo-color-dark.svg';
import _GridsomeLogoLightSvg from './gridsome-logo/gridsome-logo-light.svg';
import _GridsomeLogoDarkSvg from './gridsome-logo/gridsome-logo-dark.svg';
import _GridsomeLogotypeColorLightSvg from './gridsome-logotype/gridsome-logotype-color-light.svg';
import _GridsomeLogotypeColorDarkSvg from './gridsome-logotype/gridsome-logotype-color-dark.svg';
import _GridsomeLogotypeLightSvg from './gridsome-logotype/gridsome-logotype-light.svg';
import _GridsomeLogotypeDarkSvg from './gridsome-logotype/gridsome-logotype-dark.svg';
import _GroqLightSvg from './groq/groq-light.svg';
import _GroqDarkSvg from './groq/groq-dark.svg';
import _GuinnessColorLightSvg from './guinness/guinness-color-light.svg';
import _GuinnessColorDarkSvg from './guinness/guinness-color-dark.svg';
import _GuinnessLightSvg from './guinness/guinness-light.svg';
import _GuinnessDarkSvg from './guinness/guinness-dark.svg';
import _HarryRosenLightSvg from './harry-rosen/harry-rosen-light.svg';
import _HarryRosenDarkSvg from './harry-rosen/harry-rosen-dark.svg';
import _HashiCorpLightSvg from './hashi-corp/hashi-corp-light.svg';
import _HashiCorpDarkSvg from './hashi-corp/hashi-corp-dark.svg';
import _HashnodeColorLightSvg from './hashnode/hashnode-color-light.svg';
import _HashnodeLightSvg from './hashnode/hashnode-light.svg';
import _HashnodeColorDarkSvg from './hashnode/hashnode-color-dark.svg';
import _HashnodeDarkSvg from './hashnode/hashnode-dark.svg';
import _HatchColorLightSvg from './hatch/hatch-color-light.svg';
import _HatchColorDarkSvg from './hatch/hatch-color-dark.svg';
import _HatchLightSvg from './hatch/hatch-light.svg';
import _HatchDarkSvg from './hatch/hatch-dark.svg';
import _HelloAliceColorLightSvg from './hello-alice/hello-alice-color-light.svg';
import _HelloAliceColorDarkSvg from './hello-alice/hello-alice-color-dark.svg';
import _HelloAliceLightSvg from './hello-alice/hello-alice-light.svg';
import _HelloAliceDarkSvg from './hello-alice/hello-alice-dark.svg';
import _HellyHansenLogomarkLightSvg from './helly-hansen-logomark/helly-hansen-logomark-light.svg';
import _HellyHansenLogomarkDarkSvg from './helly-hansen-logomark/helly-hansen-logomark-dark.svg';
import _HellyHansenLogotypeLightSvg from './helly-hansen-logotype/helly-hansen-logotype-light.svg';
import _HellyHansenLogotypeDarkSvg from './helly-hansen-logotype/helly-hansen-logotype-dark.svg';
import _HermanMillerColorLightSvg from './herman-miller/herman-miller-color-light.svg';
import _HermanMillerColorDarkSvg from './herman-miller/herman-miller-color-dark.svg';
import _HermanMillerLightSvg from './herman-miller/herman-miller-light.svg';
import _HermanMillerDarkSvg from './herman-miller/herman-miller-dark.svg';
import _HewlettPackardEnterpriseColorLightSvg from './hewlett-packard-enterprise/hewlett-packard-enterprise-color-light.svg';
import _HewlettPackardEnterpriseColorDarkSvg from './hewlett-packard-enterprise/hewlett-packard-enterprise-color-dark.svg';
import _HewlettPackardEnterpriseLightSvg from './hewlett-packard-enterprise/hewlett-packard-enterprise-light.svg';
import _HewlettPackardEnterpriseDarkSvg from './hewlett-packard-enterprise/hewlett-packard-enterprise-dark.svg';
import _HexoColorLightSvg from './hexo/hexo-color-light.svg';
import _HexoColorDarkSvg from './hexo/hexo-color-dark.svg';
import _HexoLightSvg from './hexo/hexo-light.svg';
import _HexoDarkSvg from './hexo/hexo-dark.svg';
import _HolaComColorLightSvg from './hola-com/hola-com-color-light.svg';
import _HolaComColorDarkSvg from './hola-com/hola-com-color-dark.svg';
import _HolaComLightSvg from './hola-com/hola-com-light.svg';
import _HolaComDarkSvg from './hola-com/hola-com-dark.svg';
import _HorizontalLightSvg from './horizontal/horizontal-light.svg';
import _HorizontalDarkSvg from './horizontal/horizontal-dark.svg';
import _HubColorLightSvg from './hub/hub-color-light.svg';
import _HubColorDarkSvg from './hub/hub-color-dark.svg';
import _HubLightSvg from './hub/hub-light.svg';
import _HubDarkSvg from './hub/hub-dark.svg';
import _HugeLightSvg from './huge/huge-light.svg';
import _HugeDarkSvg from './huge/huge-dark.svg';
import _HuggingFaceColorLightSvg from './hugging-face/hugging-face-color-light.svg';
import _HuggingFaceColorDarkSvg from './hugging-face/hugging-face-color-dark.svg';
import _HuggingFaceLightSvg from './hugging-face/hugging-face-light.svg';
import _HuggingFaceDarkSvg from './hugging-face/hugging-face-dark.svg';
import _HugoLogoColorLightSvg from './hugo-logo/hugo-logo-color-light.svg';
import _HugoLogoColorDarkSvg from './hugo-logo/hugo-logo-color-dark.svg';
import _HugoLogoLightSvg from './hugo-logo/hugo-logo-light.svg';
import _HugoLogoDarkSvg from './hugo-logo/hugo-logo-dark.svg';
import _HugoLogotypeColorLightSvg from './hugo-logotype/hugo-logotype-color-light.svg';
import _HugoLogotypeColorDarkSvg from './hugo-logotype/hugo-logotype-color-dark.svg';
import _HugoLogotypeLightSvg from './hugo-logotype/hugo-logotype-light.svg';
import _HugoLogotypeDarkSvg from './hugo-logotype/hugo-logotype-dark.svg';
import _HumeColorLightSvg from './hume/hume-color-light.svg';
import _HumeColorDarkSvg from './hume/hume-color-dark.svg';
import _HumeLightSvg from './hume/hume-light.svg';
import _HumeDarkSvg from './hume/hume-dark.svg';
import _HydrogenLogoColorLightSvg from './hydrogen-logo/hydrogen-logo-color-light.svg';
import _HydrogenLogoColorDarkSvg from './hydrogen-logo/hydrogen-logo-color-dark.svg';
import _HydrogenLogoLightSvg from './hydrogen-logo/hydrogen-logo-light.svg';
import _HydrogenLogoDarkSvg from './hydrogen-logo/hydrogen-logo-dark.svg';
import _HydrogenLogotypeColorLightSvg from './hydrogen-logotype/hydrogen-logotype-color-light.svg';
import _HydrogenLogotypeColorDarkSvg from './hydrogen-logotype/hydrogen-logotype-color-dark.svg';
import _HydrogenLogotypeLightSvg from './hydrogen-logotype/hydrogen-logotype-light.svg';
import _HydrogenLogotypeDarkSvg from './hydrogen-logotype/hydrogen-logotype-dark.svg';
import _HydrowColorLightSvg from './hydrow/hydrow-color-light.svg';
import _HydrowLightSvg from './hydrow/hydrow-light.svg';
import _HydrowDarkSvg from './hydrow/hydrow-dark.svg';
import _HygraphLightSvg from './hygraph/hygraph-light.svg';
import _HygraphDarkSvg from './hygraph/hygraph-dark.svg';
import _IcbcColorLightSvg from './icbc/icbc-color-light.svg';
import _IcbcColorDarkSvg from './icbc/icbc-color-dark.svg';
import _IcbcLightSvg from './icbc/icbc-light.svg';
import _IcbcDarkSvg from './icbc/icbc-dark.svg';
import _IgaColorLightSvg from './iga/iga-color-light.svg';
import _IgaColorDarkSvg from './iga/iga-color-dark.svg';
import _IgaLightSvg from './iga/iga-light.svg';
import _IgaDarkSvg from './iga/iga-dark.svg';
import _IliaLightSvg from './ilia/ilia-light.svg';
import _IliaDarkSvg from './ilia/ilia-dark.svg';
import _ImdbLightSvg from './imdb/imdb-light.svg';
import _ImdbDarkSvg from './imdb/imdb-dark.svg';
import _IncidentIoColorLightSvg from './incident-io/incident-io-color-light.svg';
import _IncidentIoLightSvg from './incident-io/incident-io-light.svg';
import _IncidentIoColorDarkSvg from './incident-io/incident-io-color-dark.svg';
import _IncidentIoDarkSvg from './incident-io/incident-io-dark.svg';
import _IndentColorLightSvg from './indent/indent-color-light.svg';
import _IndentLightSvg from './indent/indent-light.svg';
import _IndentColorDarkSvg from './indent/indent-color-dark.svg';
import _IndentDarkSvg from './indent/indent-dark.svg';
import _InstatusLightSvg from './instatus/instatus-light.svg';
import _InstatusDarkSvg from './instatus/instatus-dark.svg';
import _IntegralStudioColorLightSvg from './integral-studio/integral-studio-color-light.svg';
import _IntegralStudioColorDarkSvg from './integral-studio/integral-studio-color-dark.svg';
import _IntegralStudioLightSvg from './integral-studio/integral-studio-light.svg';
import _IntegralStudioDarkSvg from './integral-studio/integral-studio-dark.svg';
import _IqviaColorLightSvg from './iqvia/iqvia-color-light.svg';
import _IqviaColorDarkSvg from './iqvia/iqvia-color-dark.svg';
import _IqviaLightSvg from './iqvia/iqvia-light.svg';
import _IqviaDarkSvg from './iqvia/iqvia-dark.svg';
import _IronMountainColorLightSvg from './iron-mountain/iron-mountain-color-light.svg';
import _IronMountainColorDarkSvg from './iron-mountain/iron-mountain-color-dark.svg';
import _IronMountainLightSvg from './iron-mountain/iron-mountain-light.svg';
import _IronMountainDarkSvg from './iron-mountain/iron-mountain-dark.svg';
import _IssLightSvg from './iss/iss-light.svg';
import _IssDarkSvg from './iss/iss-dark.svg';
import _JagexLightSvg from './jagex/jagex-light.svg';
import _JagexDarkSvg from './jagex/jagex-dark.svg';
import _JasperColorLightSvg from './jasper/jasper-color-light.svg';
import _JasperLightSvg from './jasper/jasper-light.svg';
import _JasperColorDarkSvg from './jasper/jasper-color-dark.svg';
import _JasperDarkSvg from './jasper/jasper-dark.svg';
import _JeanPaulGaultierLightSvg from './jean-paul-gaultier/jean-paul-gaultier-light.svg';
import _JeanPaulGaultierDarkSvg from './jean-paul-gaultier/jean-paul-gaultier-dark.svg';
import _JekyllColorLightSvg from './jekyll/jekyll-color-light.svg';
import _JekyllColorDarkSvg from './jekyll/jekyll-color-dark.svg';
import _JekyllLightSvg from './jekyll/jekyll-light.svg';
import _JekyllDarkSvg from './jekyll/jekyll-dark.svg';
import _JenniferFisherLightSvg from './jennifer-fisher/jennifer-fisher-light.svg';
import _JenniferFisherDarkSvg from './jennifer-fisher/jennifer-fisher-dark.svg';
import _JiscColorLightSvg from './jisc/jisc-color-light.svg';
import _JiscColorDarkSvg from './jisc/jisc-color-dark.svg';
import _JiscLightSvg from './jisc/jisc-light.svg';
import _JiscDarkSvg from './jisc/jisc-dark.svg';
import _JohnsonJohnsonColorLightSvg from './johnson-johnson/johnson-johnson-color-light.svg';
import _JohnsonJohnsonColorDarkSvg from './johnson-johnson/johnson-johnson-color-dark.svg';
import _JohnsonJohnsonLightSvg from './johnson-johnson/johnson-johnson-light.svg';
import _JohnsonJohnsonDarkSvg from './johnson-johnson/johnson-johnson-dark.svg';
import _JoynLightSvg from './joyn/joyn-light.svg';
import _JoynDarkSvg from './joyn/joyn-dark.svg';
import _JustincaseLightSvg from './justincase/justincase-light.svg';
import _JustincaseDarkSvg from './justincase/justincase-dark.svg';
import _KeystoneColorLightSvg from './keystone/keystone-color-light.svg';
import _KeystoneColorDarkSvg from './keystone/keystone-color-dark.svg';
import _KeystoneLightSvg from './keystone/keystone-light.svg';
import _KeystoneDarkSvg from './keystone/keystone-dark.svg';
import _KidsuperStudiosLightSvg from './kidsuper-studios/kidsuper-studios-light.svg';
import _KidsuperStudiosDarkSvg from './kidsuper-studios/kidsuper-studios-dark.svg';
import _KonabosColorLightSvg from './konabos/konabos-color-light.svg';
import _KonabosColorDarkSvg from './konabos/konabos-color-dark.svg';
import _KonabosLightSvg from './konabos/konabos-light.svg';
import _KonabosDarkSvg from './konabos/konabos-dark.svg';
import _KnockColorLightSvg from './knock/knock-color-light.svg';
import _KnockLightSvg from './knock/knock-light.svg';
import _KnockDarkSvg from './knock/knock-dark.svg';
import _KrakenColorLightSvg from './kraken/kraken-color-light.svg';
import _KrakenColorDarkSvg from './kraken/kraken-color-dark.svg';
import _KrakenLightSvg from './kraken/kraken-light.svg';
import _KrakenDarkSvg from './kraken/kraken-dark.svg';
import _KvikaLightSvg from './kvika/kvika-light.svg';
import _KvikaDarkSvg from './kvika/kvika-dark.svg';
import _LangchainLightSvg from './langchain/langchain-light.svg';
import _LangchainDarkSvg from './langchain/langchain-dark.svg';
import _LaurentianBankLightSvg from './laurentian-bank/laurentian-bank-light.svg';
import _LaurentianBankDarkSvg from './laurentian-bank/laurentian-bank-dark.svg';
import _LeonardoAiColorLightSvg from './leonardo-ai/leonardo-ai-color-light.svg';
import _LeonardoAiColorDarkSvg from './leonardo-ai/leonardo-ai-color-dark.svg';
import _LeonardoAiLightSvg from './leonardo-ai/leonardo-ai-light.svg';
import _LeonardoAiDarkSvg from './leonardo-ai/leonardo-ai-dark.svg';
import _LexicaLightSvg from './lexica/lexica-light.svg';
import _LexicaDarkSvg from './lexica/lexica-dark.svg';
import _LinktreeColorLightSvg from './linktree/linktree-color-light.svg';
import _LinktreeLightSvg from './linktree/linktree-light.svg';
import _LinktreeColorDarkSvg from './linktree/linktree-color-dark.svg';
import _LinktreeDarkSvg from './linktree/linktree-dark.svg';
import _LiveNationColorLightSvg from './live-nation/live-nation-color-light.svg';
import _LiveNationColorDarkSvg from './live-nation/live-nation-color-dark.svg';
import _LiveNationLightSvg from './live-nation/live-nation-light.svg';
import _LiveNationDarkSvg from './live-nation/live-nation-dark.svg';
import _LiveblocksColorLightSvg from './liveblocks/liveblocks-color-light.svg';
import _LiveblocksLightSvg from './liveblocks/liveblocks-light.svg';
import _LiveblocksColorDarkSvg from './liveblocks/liveblocks-color-dark.svg';
import _LiveblocksDarkSvg from './liveblocks/liveblocks-dark.svg';
import _LOfficielLightSvg from './l-officiel/l-officiel-light.svg';
import _LOfficielDarkSvg from './l-officiel/l-officiel-dark.svg';
import _LoomColorLightSvg from './loom/loom-color-light.svg';
import _LoomLightSvg from './loom/loom-light.svg';
import _LoomColorDarkSvg from './loom/loom-color-dark.svg';
import _LoomDarkSvg from './loom/loom-dark.svg';
import _LoveveryColorLightSvg from './lovevery/lovevery-color-light.svg';
import _LoveveryColorDarkSvg from './lovevery/lovevery-color-dark.svg';
import _LoveveryLightSvg from './lovevery/lovevery-light.svg';
import _LoveveryDarkSvg from './lovevery/lovevery-dark.svg';
import _MagdaButrymLightSvg from './magda-butrym/magda-butrym-light.svg';
import _MagdaButrymDarkSvg from './magda-butrym/magda-butrym-dark.svg';
import _MagicSchoolColorLightSvg from './magic-school/magic-school-color-light.svg';
import _MagicSchoolColorDarkSvg from './magic-school/magic-school-color-dark.svg';
import _MagicSchoolLightSvg from './magic-school/magic-school-light.svg';
import _MagicSchoolDarkSvg from './magic-school/magic-school-dark.svg';
import _MakeSpaceLightSvg from './make-space/make-space-light.svg';
import _MakeSpaceDarkSvg from './make-space/make-space-dark.svg';
import _MammutLightSvg from './mammut/mammut-light.svg';
import _MammutDarkSvg from './mammut/mammut-dark.svg';
import _MasterClassColorLightSvg from './master-class/master-class-color-light.svg';
import _MasterClassColorDarkSvg from './master-class/master-class-color-dark.svg';
import _MasterClassLightSvg from './master-class/master-class-light.svg';
import _MasterClassDarkSvg from './master-class/master-class-dark.svg';
import _MatterportColorLightSvg from './matterport/matterport-color-light.svg';
import _MatterportLightSvg from './matterport/matterport-light.svg';
import _MatterportColorDarkSvg from './matterport/matterport-color-dark.svg';
import _MatterportDarkSvg from './matterport/matterport-dark.svg';
import _MavenAgiColorLightSvg from './maven-agi/maven-agi-color-light.svg';
import _MavenAgiColorDarkSvg from './maven-agi/maven-agi-color-dark.svg';
import _MavenAgiLightSvg from './maven-agi/maven-agi-light.svg';
import _MavenAgiDarkSvg from './maven-agi/maven-agi-dark.svg';
import _McDonaldSColorLightSvg from './mc-donald-s/mc-donald-s-color-light.svg';
import _McDonaldSColorDarkSvg from './mc-donald-s/mc-donald-s-color-dark.svg';
import _McDonaldSLightSvg from './mc-donald-s/mc-donald-s-light.svg';
import _McDonaldSDarkSvg from './mc-donald-s/mc-donald-s-dark.svg';
import _MedusaColorLightSvg from './medusa/medusa-color-light.svg';
import _MedusaColorDarkSvg from './medusa/medusa-color-dark.svg';
import _MedusaLightSvg from './medusa/medusa-light.svg';
import _MedusaDarkSvg from './medusa/medusa-dark.svg';
import _MetaColorLightSvg from './meta/meta-color-light.svg';
import _MetaLightSvg from './meta/meta-light.svg';
import _MetaColorDarkSvg from './meta/meta-color-dark.svg';
import _MetaDarkSvg from './meta/meta-dark.svg';
import _MetagenicsColorLightSvg from './metagenics/metagenics-color-light.svg';
import _MetagenicsColorDarkSvg from './metagenics/metagenics-color-dark.svg';
import _MetagenicsLightSvg from './metagenics/metagenics-light.svg';
import _MetagenicsDarkSvg from './metagenics/metagenics-dark.svg';
import _MetcashColorLightSvg from './metcash/metcash-color-light.svg';
import _MetcashColorDarkSvg from './metcash/metcash-color-dark.svg';
import _MetcashLightSvg from './metcash/metcash-light.svg';
import _MetcashDarkSvg from './metcash/metcash-dark.svg';
import _MetrolinxColorLightSvg from './metrolinx/metrolinx-color-light.svg';
import _MetrolinxColorDarkSvg from './metrolinx/metrolinx-color-dark.svg';
import _MetrolinxLightSvg from './metrolinx/metrolinx-light.svg';
import _MetrolinxDarkSvg from './metrolinx/metrolinx-dark.svg';
import _MiddlemanLightSvg from './middleman/middleman-light.svg';
import _MiddlemanDarkSvg from './middleman/middleman-dark.svg';
import _MintlifyColorLightSvg from './mintlify/mintlify-color-light.svg';
import _MintlifyLightSvg from './mintlify/mintlify-light.svg';
import _MintlifyColorDarkSvg from './mintlify/mintlify-color-dark.svg';
import _MintlifyDarkSvg from './mintlify/mintlify-dark.svg';
import _MirrorColorLightSvg from './mirror/mirror-color-light.svg';
import _MirrorLightSvg from './mirror/mirror-light.svg';
import _MirrorColorDarkSvg from './mirror/mirror-color-dark.svg';
import _MirrorDarkSvg from './mirror/mirror-dark.svg';
import _MitsueLinksColorLightSvg from './mitsue-links/mitsue-links-color-light.svg';
import _MitsueLinksColorDarkSvg from './mitsue-links/mitsue-links-color-dark.svg';
import _MitsueLinksLightSvg from './mitsue-links/mitsue-links-light.svg';
import _MitsueLinksDarkSvg from './mitsue-links/mitsue-links-dark.svg';
import _ModalColorLightSvg from './modal/modal-color-light.svg';
import _ModalColorDarkSvg from './modal/modal-color-dark.svg';
import _ModalLightSvg from './modal/modal-light.svg';
import _ModalDarkSvg from './modal/modal-dark.svg';
import _ModernTreasuryColorLightSvg from './modern-treasury/modern-treasury-color-light.svg';
import _ModernTreasuryColorDarkSvg from './modern-treasury/modern-treasury-color-dark.svg';
import _ModernTreasuryLightSvg from './modern-treasury/modern-treasury-light.svg';
import _ModernTreasuryDarkSvg from './modern-treasury/modern-treasury-dark.svg';
import _MongoDbColorLightSvg from './mongo-db/mongo-db-color-light.svg';
import _MongoDbLightSvg from './mongo-db/mongo-db-light.svg';
import _MongoDbColorDarkSvg from './mongo-db/mongo-db-color-dark.svg';
import _MongoDbDarkSvg from './mongo-db/mongo-db-dark.svg';
import _MonogramLightSvg from './monogram/monogram-light.svg';
import _MonogramDarkSvg from './monogram/monogram-dark.svg';
import _MooLightSvg from './moo/moo-light.svg';
import _MooDarkSvg from './moo/moo-dark.svg';
import _MorningBrewColorLightSvg from './morning-brew/morning-brew-color-light.svg';
import _MorningBrewColorDarkSvg from './morning-brew/morning-brew-color-dark.svg';
import _MorningBrewLightSvg from './morning-brew/morning-brew-light.svg';
import _MorningBrewDarkSvg from './morning-brew/morning-brew-dark.svg';
import _MorningConsultLightSvg from './morning-consult/morning-consult-light.svg';
import _MorningConsultDarkSvg from './morning-consult/morning-consult-dark.svg';
import _MotifLightSvg from './motif/motif-light.svg';
import _MotifDarkSvg from './motif/motif-dark.svg';
import _MotortrendLightSvg from './motortrend/motortrend-light.svg';
import _MotortrendDarkSvg from './motortrend/motortrend-dark.svg';
import _MrBeastLightSvg from './mr-beast/mr-beast-light.svg';
import _MrBeastDarkSvg from './mr-beast/mr-beast-dark.svg';
import _MuxLightSvg from './mux/mux-light.svg';
import _MuxDarkSvg from './mux/mux-dark.svg';
import _MyMuscleChefLightSvg from './my-muscle-chef/my-muscle-chef-light.svg';
import _MyMuscleChefDarkSvg from './my-muscle-chef/my-muscle-chef-dark.svg';
import _NacelleLightSvg from './nacelle/nacelle-light.svg';
import _NacelleDarkSvg from './nacelle/nacelle-dark.svg';
import _NationalMarrowDonorProgramColorLightSvg from './national-marrow-donor-program/national-marrow-donor-program-color-light.svg';
import _NationalMarrowDonorProgramColorDarkSvg from './national-marrow-donor-program/national-marrow-donor-program-color-dark.svg';
import _NationalMarrowDonorProgramLightSvg from './national-marrow-donor-program/national-marrow-donor-program-light.svg';
import _NationalMarrowDonorProgramDarkSvg from './national-marrow-donor-program/national-marrow-donor-program-dark.svg';
import _NeoLightSvg from './neo/neo-light.svg';
import _NeoDarkSvg from './neo/neo-dark.svg';
import _NeonColorLightSvg from './neon/neon-color-light.svg';
import _NeonColorDarkSvg from './neon/neon-color-dark.svg';
import _NeonLightSvg from './neon/neon-light.svg';
import _NeonDarkSvg from './neon/neon-dark.svg';
import _NerdwalletColorLightSvg from './nerdwallet/nerdwallet-color-light.svg';
import _NerdwalletColorDarkSvg from './nerdwallet/nerdwallet-color-dark.svg';
import _NerdwalletLightSvg from './nerdwallet/nerdwallet-light.svg';
import _NerdwalletDarkSvg from './nerdwallet/nerdwallet-dark.svg';
import _NetflixColorLightSvg from './netflix/netflix-color-light.svg';
import _NetflixColorDarkSvg from './netflix/netflix-color-dark.svg';
import _NetflixLightSvg from './netflix/netflix-light.svg';
import _NetflixDarkSvg from './netflix/netflix-dark.svg';
import _NetlightColorLightSvg from './netlight/netlight-color-light.svg';
import _NetlightColorDarkSvg from './netlight/netlight-color-dark.svg';
import _NetlightLightSvg from './netlight/netlight-light.svg';
import _NetlightDarkSvg from './netlight/netlight-dark.svg';
import _NewRelicColorLightSvg from './new-relic/new-relic-color-light.svg';
import _NewRelicColorDarkSvg from './new-relic/new-relic-color-dark.svg';
import _NewRelicLightSvg from './new-relic/new-relic-light.svg';
import _NewRelicDarkSvg from './new-relic/new-relic-dark.svg';
import _NintendoColorLightSvg from './nintendo/nintendo-color-light.svg';
import _NintendoColorDarkSvg from './nintendo/nintendo-color-dark.svg';
import _NintendoLightSvg from './nintendo/nintendo-light.svg';
import _NintendoDarkSvg from './nintendo/nintendo-dark.svg';
import _NixonPeabodyColorLightSvg from './nixon-peabody/nixon-peabody-color-light.svg';
import _NixonPeabodyColorDarkSvg from './nixon-peabody/nixon-peabody-color-dark.svg';
import _NixonPeabodyLightSvg from './nixon-peabody/nixon-peabody-light.svg';
import _NixonPeabodyDarkSvg from './nixon-peabody/nixon-peabody-dark.svg';
import _NomadLightSvg from './nomad/nomad-light.svg';
import _NomadDarkSvg from './nomad/nomad-dark.svg';
import _NotableCapitalColorLightSvg from './notable-capital/notable-capital-color-light.svg';
import _NotableCapitalColorDarkSvg from './notable-capital/notable-capital-color-dark.svg';
import _NotableCapitalLightSvg from './notable-capital/notable-capital-light.svg';
import _NotableCapitalDarkSvg from './notable-capital/notable-capital-dark.svg';
import _NotionLightSvg from './notion/notion-light.svg';
import _NotionDarkSvg from './notion/notion-dark.svg';
import _NuxtLogoColorLightSvg from './nuxt-logo/nuxt-logo-color-light.svg';
import _NuxtLogoColorDarkSvg from './nuxt-logo/nuxt-logo-color-dark.svg';
import _NuxtLogoLightSvg from './nuxt-logo/nuxt-logo-light.svg';
import _NuxtLogoDarkSvg from './nuxt-logo/nuxt-logo-dark.svg';
import _NuxtLogotypeColorLightSvg from './nuxt-logotype/nuxt-logotype-color-light.svg';
import _NuxtLogotypeColorDarkSvg from './nuxt-logotype/nuxt-logotype-color-dark.svg';
import _NuxtLogotypeLightSvg from './nuxt-logotype/nuxt-logotype-light.svg';
import _NuxtLogotypeDarkSvg from './nuxt-logotype/nuxt-logotype-dark.svg';
import _NzxtLightSvg from './nzxt/nzxt-light.svg';
import _NzxtDarkSvg from './nzxt/nzxt-dark.svg';
import _OctopusEnergyColorLightSvg from './octopus-energy/octopus-energy-color-light.svg';
import _OctopusEnergyColorDarkSvg from './octopus-energy/octopus-energy-color-dark.svg';
import _OctopusEnergyLightSvg from './octopus-energy/octopus-energy-light.svg';
import _OctopusEnergyDarkSvg from './octopus-energy/octopus-energy-dark.svg';
import _OddleColorLightSvg from './oddle/oddle-color-light.svg';
import _OddleLightSvg from './oddle/oddle-light.svg';
import _OddleDarkSvg from './oddle/oddle-dark.svg';
import _OfficeworksColorLightSvg from './officeworks/officeworks-color-light.svg';
import _OfficeworksColorDarkSvg from './officeworks/officeworks-color-dark.svg';
import _OfficeworksLightSvg from './officeworks/officeworks-light.svg';
import _OfficeworksDarkSvg from './officeworks/officeworks-dark.svg';
import _OktaLightSvg from './okta/okta-light.svg';
import _OktaDarkSvg from './okta/okta-dark.svg';
import _OpenAiLightSvg from './open-ai/open-ai-light.svg';
import _OpenAiDarkSvg from './open-ai/open-ai-dark.svg';
import _OptimizelyColorLightSvg from './optimizely/optimizely-color-light.svg';
import _OptimizelyColorDarkSvg from './optimizely/optimizely-color-dark.svg';
import _OptimizelyLightSvg from './optimizely/optimizely-light.svg';
import _OptimizelyDarkSvg from './optimizely/optimizely-dark.svg';
import _OptimizelyLogoColorLightSvg from './optimizely-logo/optimizely-logo-color-light.svg';
import _OptimizelyLogoColorDarkSvg from './optimizely-logo/optimizely-logo-color-dark.svg';
import _OptimizelyLogoLightSvg from './optimizely-logo/optimizely-logo-light.svg';
import _OptimizelyLogoDarkSvg from './optimizely-logo/optimizely-logo-dark.svg';
import _OriumLightSvg from './orium/orium-light.svg';
import _OriumDarkSvg from './orium/orium-dark.svg';
import _OutfrontColorLightSvg from './outfront/outfront-color-light.svg';
import _OutfrontColorDarkSvg from './outfront/outfront-color-dark.svg';
import _OutfrontLightSvg from './outfront/outfront-light.svg';
import _OutfrontDarkSvg from './outfront/outfront-dark.svg';
import _OutsideColorLightSvg from './outside/outside-color-light.svg';
import _OutsideColorDarkSvg from './outside/outside-color-dark.svg';
import _OutsideLightSvg from './outside/outside-light.svg';
import _OutsideDarkSvg from './outside/outside-dark.svg';
import _OvoEnergyColorLightSvg from './ovo-energy/ovo-energy-color-light.svg';
import _OvoEnergyColorDarkSvg from './ovo-energy/ovo-energy-color-dark.svg';
import _OvoEnergyLightSvg from './ovo-energy/ovo-energy-light.svg';
import _OvoEnergyDarkSvg from './ovo-energy/ovo-energy-dark.svg';
import _ParachuteLightSvg from './parachute/parachute-light.svg';
import _ParachuteDarkSvg from './parachute/parachute-dark.svg';
import _ParcelColorLightSvg from './parcel/parcel-color-light.svg';
import _ParcelColorDarkSvg from './parcel/parcel-color-dark.svg';
import _ParcelLightSvg from './parcel/parcel-light.svg';
import _ParcelDarkSvg from './parcel/parcel-dark.svg';
import _PatreonLightSvg from './patreon/patreon-light.svg';
import _PatreonDarkSvg from './patreon/patreon-dark.svg';
import _PerficientLightSvg from './perficient/perficient-light.svg';
import _PerficientDarkSvg from './perficient/perficient-dark.svg';
import _PerplexityColorLightSvg from './perplexity/perplexity-color-light.svg';
import _PerplexityColorDarkSvg from './perplexity/perplexity-color-dark.svg';
import _PerplexityLightSvg from './perplexity/perplexity-light.svg';
import _PerplexityDarkSvg from './perplexity/perplexity-dark.svg';
import _PersonioLightSvg from './personio/personio-light.svg';
import _PersonioDarkSvg from './personio/personio-dark.svg';
import _PsychiatricTimesColorLightSvg from './psychiatric-times/psychiatric-times-color-light.svg';
import _PsychiatricTimesColorDarkSvg from './psychiatric-times/psychiatric-times-color-dark.svg';
import _PsychiatricTimesLightSvg from './psychiatric-times/psychiatric-times-light.svg';
import _PsychiatricTimesDarkSvg from './psychiatric-times/psychiatric-times-dark.svg';
import _PikaLightSvg from './pika/pika-light.svg';
import _PikaDarkSvg from './pika/pika-dark.svg';
import _PineconeLightSvg from './pinecone/pinecone-light.svg';
import _PineconeDarkSvg from './pinecone/pinecone-dark.svg';
import _PipeLightSvg from './pipe/pipe-light.svg';
import _PipeDarkSvg from './pipe/pipe-dark.svg';
import _PistonHeadsLightSvg from './piston-heads/piston-heads-light.svg';
import _PistonHeadsDarkSvg from './piston-heads/piston-heads-dark.svg';
import _PlanetScaleLightSvg from './planet-scale/planet-scale-light.svg';
import _PlanetScaleDarkSvg from './planet-scale/planet-scale-dark.svg';
import _PlexColorLightSvg from './plex/plex-color-light.svg';
import _PlexLightSvg from './plex/plex-light.svg';
import _PlexColorDarkSvg from './plex/plex-color-dark.svg';
import _PlexDarkSvg from './plex/plex-dark.svg';
import _PopSqlColorLightSvg from './pop-sql/pop-sql-color-light.svg';
import _PopSqlColorDarkSvg from './pop-sql/pop-sql-color-dark.svg';
import _PopSqlLightSvg from './pop-sql/pop-sql-light.svg';
import _PopSqlDarkSvg from './pop-sql/pop-sql-dark.svg';
import _PolymarketLightSvg from './polymarket/polymarket-light.svg';
import _PolymarketDarkSvg from './polymarket/polymarket-dark.svg';
import _PolymerColorLightSvg from './polymer/polymer-color-light.svg';
import _PolymerColorDarkSvg from './polymer/polymer-color-dark.svg';
import _PolymerLightSvg from './polymer/polymer-light.svg';
import _PolymerDarkSvg from './polymer/polymer-dark.svg';
import _PreactLogoColorLightSvg from './preact-logo/preact-logo-color-light.svg';
import _PreactLogoColorDarkSvg from './preact-logo/preact-logo-color-dark.svg';
import _PreactLogoLightSvg from './preact-logo/preact-logo-light.svg';
import _PreactLogoDarkSvg from './preact-logo/preact-logo-dark.svg';
import _PreactLogotypeColorLightSvg from './preact-logotype/preact-logotype-color-light.svg';
import _PreactLogotypeColorDarkSvg from './preact-logotype/preact-logotype-color-dark.svg';
import _PreactLogotypeLightSvg from './preact-logotype/preact-logotype-light.svg';
import _PreactLogotypeDarkSvg from './preact-logotype/preact-logotype-dark.svg';
import _PresbyterianColorLightSvg from './presbyterian/presbyterian-color-light.svg';
import _PresbyterianColorDarkSvg from './presbyterian/presbyterian-color-dark.svg';
import _PresbyterianLightSvg from './presbyterian/presbyterian-light.svg';
import _PresbyterianDarkSvg from './presbyterian/presbyterian-dark.svg';
import _PrismaColorLightSvg from './prisma/prisma-color-light.svg';
import _PrismaLightSvg from './prisma/prisma-light.svg';
import _PrismaDarkSvg from './prisma/prisma-dark.svg';
import _PrismicLightSvg from './prismic/prismic-light.svg';
import _PrismicDarkSvg from './prismic/prismic-dark.svg';
import _RaycastColorLightSvg from './raycast/raycast-color-light.svg';
import _RaycastColorDarkSvg from './raycast/raycast-color-dark.svg';
import _RaycastLightSvg from './raycast/raycast-light.svg';
import _RaycastDarkSvg from './raycast/raycast-dark.svg';
import _ReactColorLightSvg from './react/react-color-light.svg';
import _ReactColorDarkSvg from './react/react-color-dark.svg';
import _ReactLightSvg from './react/react-light.svg';
import _ReactDarkSvg from './react/react-dark.svg';
import _ReadCvLightSvg from './read-cv/read-cv-light.svg';
import _ReadCvDarkSvg from './read-cv/read-cv-dark.svg';
import _RedwoodjsLogoColorLightSvg from './redwoodjs-logo/redwoodjs-logo-color-light.svg';
import _RedwoodjsLogoColorDarkSvg from './redwoodjs-logo/redwoodjs-logo-color-dark.svg';
import _RedwoodjsLogoLightSvg from './redwoodjs-logo/redwoodjs-logo-light.svg';
import _RedwoodjsLogoDarkSvg from './redwoodjs-logo/redwoodjs-logo-dark.svg';
import _RedwoodjsLogotypeColorLightSvg from './redwoodjs-logotype/redwoodjs-logotype-color-light.svg';
import _RedwoodjsLogotypeColorDarkSvg from './redwoodjs-logotype/redwoodjs-logotype-color-dark.svg';
import _RedwoodjsLogotypeLightSvg from './redwoodjs-logotype/redwoodjs-logotype-light.svg';
import _RedwoodjsLogotypeDarkSvg from './redwoodjs-logotype/redwoodjs-logotype-dark.svg';
import _RenownHealthColorLightSvg from './renown-health/renown-health-color-light.svg';
import _RenownHealthColorDarkSvg from './renown-health/renown-health-color-dark.svg';
import _RenownHealthLightSvg from './renown-health/renown-health-light.svg';
import _RenownHealthDarkSvg from './renown-health/renown-health-dark.svg';
import _ReMarkableLightSvg from './re-markable/re-markable-light.svg';
import _ReMarkableDarkSvg from './re-markable/re-markable-dark.svg';
import _RemixLightSvg from './remix/remix-light.svg';
import _RemixDarkSvg from './remix/remix-dark.svg';
import _RemoteLightSvg from './remote/remote-light.svg';
import _RemoteDarkSvg from './remote/remote-dark.svg';
import _ReplicateLightSvg from './replicate/replicate-light.svg';
import _ReplicateDarkSvg from './replicate/replicate-dark.svg';
import _ResoluteCapitalPartnersColorLightSvg from './resolute-capital-partners/resolute-capital-partners-color-light.svg';
import _ResoluteCapitalPartnersLightSvg from './resolute-capital-partners/resolute-capital-partners-light.svg';
import _ResoluteCapitalPartnersDarkSvg from './resolute-capital-partners/resolute-capital-partners-dark.svg';
import _RetoolLightSvg from './retool/retool-light.svg';
import _RetoolDarkSvg from './retool/retool-dark.svg';
import _RightpointColorLightSvg from './rightpoint/rightpoint-color-light.svg';
import _RightpointColorDarkSvg from './rightpoint/rightpoint-color-dark.svg';
import _RightpointLightSvg from './rightpoint/rightpoint-light.svg';
import _RightpointDarkSvg from './rightpoint/rightpoint-dark.svg';
import _RipplingLightSvg from './rippling/rippling-light.svg';
import _RipplingDarkSvg from './rippling/rippling-dark.svg';
import _RollinColorLightSvg from './rollin/rollin-color-light.svg';
import _RollinColorDarkSvg from './rollin/rollin-color-dark.svg';
import _RollinLightSvg from './rollin/rollin-light.svg';
import _RollinDarkSvg from './rollin/rollin-dark.svg';
import _RuggableLightSvg from './ruggable/ruggable-light.svg';
import _RuggableDarkSvg from './ruggable/ruggable-dark.svg';
import _RunwayLightSvg from './runway/runway-light.svg';
import _RunwayDarkSvg from './runway/runway-dark.svg';
import _RunwayLogomarkLightSvg from './runway-logomark/runway-logomark-light.svg';
import _RunwayLogomarkDarkSvg from './runway-logomark/runway-logomark-dark.svg';
import _RustLightSvg from './rust/rust-light.svg';
import _RustDarkSvg from './rust/rust-dark.svg';
import _RangleLogotypeColorLightSvg from './rangle-logotype/rangle-logotype-color-light.svg';
import _RangleLogotypeColorDarkSvg from './rangle-logotype/rangle-logotype-color-dark.svg';
import _RangleLogotypeLightSvg from './rangle-logotype/rangle-logotype-light.svg';
import _RangleLogotypeDarkSvg from './rangle-logotype/rangle-logotype-dark.svg';
import _RangleLogoColorLightSvg from './rangle-logo/rangle-logo-color-light.svg';
import _RangleLogoColorDarkSvg from './rangle-logo/rangle-logo-color-dark.svg';
import _RangleLogoLightSvg from './rangle-logo/rangle-logo-light.svg';
import _RangleLogoDarkSvg from './rangle-logo/rangle-logo-dark.svg';
import _STwoEightLightSvg from './s-two-eight/s-two-eight-light.svg';
import _STwoEightDarkSvg from './s-two-eight/s-two-eight-dark.svg';
import _SaasSeattleAcademyLightSvg from './saas-seattle-academy/saas-seattle-academy-light.svg';
import _SaasSeattleAcademyDarkSvg from './saas-seattle-academy/saas-seattle-academy-dark.svg';
import _SaberColorLightSvg from './saber/saber-color-light.svg';
import _SaberColorDarkSvg from './saber/saber-color-dark.svg';
import _SaberLightSvg from './saber/saber-light.svg';
import _SaberDarkSvg from './saber/saber-dark.svg';
import _SaleorColorLightSvg from './saleor/saleor-color-light.svg';
import _SaleorColorDarkSvg from './saleor/saleor-color-dark.svg';
import _SaleorLightSvg from './saleor/saleor-light.svg';
import _SaleorDarkSvg from './saleor/saleor-dark.svg';
import _SalesforceColorLightSvg from './salesforce/salesforce-color-light.svg';
import _SalesforceColorDarkSvg from './salesforce/salesforce-color-dark.svg';
import _SalesforceLightSvg from './salesforce/salesforce-light.svg';
import _SalesforceDarkSvg from './salesforce/salesforce-dark.svg';
import _SamaraLightSvg from './samara/samara-light.svg';
import _SamaraDarkSvg from './samara/samara-dark.svg';
import _SanityColorLightSvg from './sanity/sanity-color-light.svg';
import _SanityColorDarkSvg from './sanity/sanity-color-dark.svg';
import _SanityLightSvg from './sanity/sanity-light.svg';
import _SanityDarkSvg from './sanity/sanity-dark.svg';
import _SantaCruzLightSvg from './santa-cruz/santa-cruz-light.svg';
import _SantaCruzDarkSvg from './santa-cruz/santa-cruz-dark.svg';
import _ScaleLightSvg from './scale/scale-light.svg';
import _ScaleDarkSvg from './scale/scale-dark.svg';
import _ScullyLogoColorLightSvg from './scully-logo/scully-logo-color-light.svg';
import _ScullyLogoColorDarkSvg from './scully-logo/scully-logo-color-dark.svg';
import _ScullyLogoLightSvg from './scully-logo/scully-logo-light.svg';
import _ScullyLogoDarkSvg from './scully-logo/scully-logo-dark.svg';
import _ScullyLogotypeColorLightSvg from './scully-logotype/scully-logotype-color-light.svg';
import _ScullyLogotypeColorDarkSvg from './scully-logotype/scully-logotype-color-dark.svg';
import _ScullyLogotypeLightSvg from './scully-logotype/scully-logotype-light.svg';
import _ScullyLogotypeDarkSvg from './scully-logotype/scully-logotype-dark.svg';
import _SeismicColorLightSvg from './seismic/seismic-color-light.svg';
import _SeismicColorDarkSvg from './seismic/seismic-color-dark.svg';
import _SeismicLightSvg from './seismic/seismic-light.svg';
import _SeismicDarkSvg from './seismic/seismic-dark.svg';
import _SentryLightSvg from './sentry/sentry-light.svg';
import _SentryDarkSvg from './sentry/sentry-dark.svg';
import _ServusTvLightSvg from './servus-tv/servus-tv-light.svg';
import _ServusTvDarkSvg from './servus-tv/servus-tv-dark.svg';
import _ShopifyColorLightSvg from './shopify/shopify-color-light.svg';
import _ShopifyLightSvg from './shopify/shopify-light.svg';
import _ShopifyColorDarkSvg from './shopify/shopify-color-dark.svg';
import _ShopifyDarkSvg from './shopify/shopify-dark.svg';
import _ShopwareColorLightSvg from './shopware/shopware-color-light.svg';
import _ShopwareColorDarkSvg from './shopware/shopware-color-dark.svg';
import _ShopwareLightSvg from './shopware/shopware-light.svg';
import _ShopwareDarkSvg from './shopware/shopware-dark.svg';
import _ShotgunLightSvg from './shotgun/shotgun-light.svg';
import _ShotgunDarkSvg from './shotgun/shotgun-dark.svg';
import _SimplePracticeColorLightSvg from './simple-practice/simple-practice-color-light.svg';
import _SimplePracticeColorDarkSvg from './simple-practice/simple-practice-color-dark.svg';
import _SimplePracticeLightSvg from './simple-practice/simple-practice-light.svg';
import _SimplePracticeDarkSvg from './simple-practice/simple-practice-dark.svg';
import _SitecoreLogoColorLightSvg from './sitecore-logo/sitecore-logo-color-light.svg';
import _SitecoreLogoLightSvg from './sitecore-logo/sitecore-logo-light.svg';
import _SitecoreLogoColorDarkSvg from './sitecore-logo/sitecore-logo-color-dark.svg';
import _SitecoreLogoDarkSvg from './sitecore-logo/sitecore-logo-dark.svg';
import _SnykColorLightSvg from './snyk/snyk-color-light.svg';
import _SnykColorDarkSvg from './snyk/snyk-color-dark.svg';
import _SnykLightSvg from './snyk/snyk-light.svg';
import _SnykDarkSvg from './snyk/snyk-dark.svg';
import _SoftwareOneLightSvg from './software-one/software-one-light.svg';
import _SoftwareOneDarkSvg from './software-one/software-one-dark.svg';
import _SolanaColorLightSvg from './solana/solana-color-light.svg';
import _SolanaColorDarkSvg from './solana/solana-color-dark.svg';
import _SolanaLightSvg from './solana/solana-light.svg';
import _SolanaDarkSvg from './solana/solana-dark.svg';
import _SolidjsLogoColorLightSvg from './solidjs-logo/solidjs-logo-color-light.svg';
import _SolidjsLogoColorDarkSvg from './solidjs-logo/solidjs-logo-color-dark.svg';
import _SolidjsLogoLightSvg from './solidjs-logo/solidjs-logo-light.svg';
import _SolidjsLogoDarkSvg from './solidjs-logo/solidjs-logo-dark.svg';
import _SolidjsLogotypeColorLightSvg from './solidjs-logotype/solidjs-logotype-color-light.svg';
import _SolidjsLogotypeColorDarkSvg from './solidjs-logotype/solidjs-logotype-color-dark.svg';
import _SolidjsLogotypeLightSvg from './solidjs-logotype/solidjs-logotype-light.svg';
import _SolidjsLogotypeDarkSvg from './solidjs-logotype/solidjs-logotype-dark.svg';
import _SongwhipLightSvg from './songwhip/songwhip-light.svg';
import _SongwhipDarkSvg from './songwhip/songwhip-dark.svg';
import _SonosLightSvg from './sonos/sonos-light.svg';
import _SonosDarkSvg from './sonos/sonos-dark.svg';
import _SplitLightSvg from './split/split-light.svg';
import _SplitDarkSvg from './split/split-dark.svg';
import _SplunkLightSvg from './splunk/splunk-light.svg';
import _SplunkDarkSvg from './splunk/splunk-dark.svg';
import _StaarSurgicalColorLightSvg from './staar-surgical/staar-surgical-color-light.svg';
import _StaarSurgicalLightSvg from './staar-surgical/staar-surgical-light.svg';
import _StaarSurgicalColorDarkSvg from './staar-surgical/staar-surgical-color-dark.svg';
import _StaarSurgicalDarkSvg from './staar-surgical/staar-surgical-dark.svg';
import _StabilityLightSvg from './stability/stability-light.svg';
import _StabilityDarkSvg from './stability/stability-dark.svg';
import _StakeLightSvg from './stake/stake-light.svg';
import _StakeDarkSvg from './stake/stake-dark.svg';
import _StarTribuneColorLightSvg from './star-tribune/star-tribune-color-light.svg';
import _StarTribuneColorDarkSvg from './star-tribune/star-tribune-color-dark.svg';
import _StarTribuneLightSvg from './star-tribune/star-tribune-light.svg';
import _StarTribuneDarkSvg from './star-tribune/star-tribune-dark.svg';
import _StencilLogoLightSvg from './stencil-logo/stencil-logo-light.svg';
import _StencilLogoDarkSvg from './stencil-logo/stencil-logo-dark.svg';
import _StencilLogotypeLightSvg from './stencil-logotype/stencil-logotype-light.svg';
import _StencilLogotypeDarkSvg from './stencil-logotype/stencil-logotype-dark.svg';
import _StockAiColorLightSvg from './stock-ai/stock-ai-color-light.svg';
import _StockAiLightSvg from './stock-ai/stock-ai-light.svg';
import _StockAiColorDarkSvg from './stock-ai/stock-ai-color-dark.svg';
import _StockAiDarkSvg from './stock-ai/stock-ai-dark.svg';
import _StrapiColorLightSvg from './strapi/strapi-color-light.svg';
import _StrapiColorDarkSvg from './strapi/strapi-color-dark.svg';
import _StrapiLightSvg from './strapi/strapi-light.svg';
import _StrapiDarkSvg from './strapi/strapi-dark.svg';
import _StripeColorLightSvg from './stripe/stripe-color-light.svg';
import _StripeColorDarkSvg from './stripe/stripe-color-dark.svg';
import _StripeLightSvg from './stripe/stripe-light.svg';
import _StripeDarkSvg from './stripe/stripe-dark.svg';
import _SumUpLightSvg from './sum-up/sum-up-light.svg';
import _SumUpDarkSvg from './sum-up/sum-up-dark.svg';
import _SunoLightSvg from './suno/suno-light.svg';
import _SunoDarkSvg from './suno/suno-dark.svg';
import _SupabaseColorLightSvg from './supabase/supabase-color-light.svg';
import _SupabaseLightSvg from './supabase/supabase-light.svg';
import _SupabaseColorDarkSvg from './supabase/supabase-color-dark.svg';
import _SupabaseDarkSvg from './supabase/supabase-dark.svg';
import _SuperColorLightSvg from './super/super-color-light.svg';
import _SuperLightSvg from './super/super-light.svg';
import _SuperColorDarkSvg from './super/super-color-dark.svg';
import _SuperDarkSvg from './super/super-dark.svg';
import _SupremeColorLightSvg from './supreme/supreme-color-light.svg';
import _SupremeColorDarkSvg from './supreme/supreme-color-dark.svg';
import _SupremeLightSvg from './supreme/supreme-light.svg';
import _SupremeDarkSvg from './supreme/supreme-dark.svg';
import _SvAngelColorLightSvg from './sv-angel/sv-angel-color-light.svg';
import _SvAngelLightSvg from './sv-angel/sv-angel-light.svg';
import _SvAngelDarkSvg from './sv-angel/sv-angel-dark.svg';
import _SvelteLogoColorLightSvg from './svelte-logo/svelte-logo-color-light.svg';
import _SvelteLogoColorDarkSvg from './svelte-logo/svelte-logo-color-dark.svg';
import _SvelteLogoLightSvg from './svelte-logo/svelte-logo-light.svg';
import _SvelteLogoDarkSvg from './svelte-logo/svelte-logo-dark.svg';
import _SvelteLogotypeColorLightSvg from './svelte-logotype/svelte-logotype-color-light.svg';
import _SvelteLogotypeColorDarkSvg from './svelte-logotype/svelte-logotype-color-dark.svg';
import _SvelteLogotypeLightSvg from './svelte-logotype/svelte-logotype-light.svg';
import _SvelteLogotypeDarkSvg from './svelte-logotype/svelte-logotype-dark.svg';
import _SwcColorLightSvg from './swc/swc-color-light.svg';
import _SwcColorDarkSvg from './swc/swc-color-dark.svg';
import _SwcLightSvg from './swc/swc-light.svg';
import _SwcDarkSvg from './swc/swc-dark.svg';
import _SwellColorLightSvg from './swell/swell-color-light.svg';
import _SwellColorDarkSvg from './swell/swell-color-dark.svg';
import _SwellLightSvg from './swell/swell-light.svg';
import _SwellDarkSvg from './swell/swell-dark.svg';
import _SwrLightSvg from './swr/swr-light.svg';
import _SwrDarkSvg from './swr/swr-dark.svg';
import _TabletLightSvg from './tablet/tablet-light.svg';
import _TabletDarkSvg from './tablet/tablet-dark.svg';
import _TailoredBrandsLightSvg from './tailored-brands/tailored-brands-light.svg';
import _TailoredBrandsDarkSvg from './tailored-brands/tailored-brands-dark.svg';
import _TailwindColorLightSvg from './tailwind/tailwind-color-light.svg';
import _TailwindLightSvg from './tailwind/tailwind-light.svg';
import _TailwindColorDarkSvg from './tailwind/tailwind-color-dark.svg';
import _TailwindDarkSvg from './tailwind/tailwind-dark.svg';
import _TakedaColorLightSvg from './takeda/takeda-color-light.svg';
import _TakedaColorDarkSvg from './takeda/takeda-color-dark.svg';
import _TakedaLightSvg from './takeda/takeda-light.svg';
import _TakedaDarkSvg from './takeda/takeda-dark.svg';
import _TargetColorLightSvg from './target/target-color-light.svg';
import _TargetColorDarkSvg from './target/target-color-dark.svg';
import _TargetLightSvg from './target/target-light.svg';
import _TargetDarkSvg from './target/target-dark.svg';
import _TeleportLightSvg from './teleport/teleport-light.svg';
import _TeleportDarkSvg from './teleport/teleport-dark.svg';
import _TeleportHqColorLightSvg from './teleport-hq/teleport-hq-color-light.svg';
import _TeleportHqLightSvg from './teleport-hq/teleport-hq-light.svg';
import _TeleportHqColorDarkSvg from './teleport-hq/teleport-hq-color-dark.svg';
import _TeleportHqDarkSvg from './teleport-hq/teleport-hq-dark.svg';
import _TheRealRealLightSvg from './the-real-real/the-real-real-light.svg';
import _TheRealRealDarkSvg from './the-real-real/the-real-real-dark.svg';
import _ThinxLightSvg from './thinx/thinx-light.svg';
import _ThinxDarkSvg from './thinx/thinx-dark.svg';
import _ThoughtSpotLightSvg from './thought-spot/thought-spot-light.svg';
import _ThoughtSpotDarkSvg from './thought-spot/thought-spot-dark.svg';
import _TigerGlobalLightSvg from './tiger-global/tiger-global-light.svg';
import _TigerGlobalDarkSvg from './tiger-global/tiger-global-dark.svg';
import _TitanInvestLightSvg from './titan-invest/titan-invest-light.svg';
import _TitanInvestDarkSvg from './titan-invest/titan-invest-dark.svg';
import _TombrasColorLightSvg from './tombras/tombras-color-light.svg';
import _TombrasColorDarkSvg from './tombras/tombras-color-dark.svg';
import _TombrasLightSvg from './tombras/tombras-light.svg';
import _TombrasDarkSvg from './tombras/tombras-dark.svg';
import _TripAdvisorColorLightSvg from './trip-advisor/trip-advisor-color-light.svg';
import _TripAdvisorLightSvg from './trip-advisor/trip-advisor-light.svg';
import _TripAdvisorColorDarkSvg from './trip-advisor/trip-advisor-color-dark.svg';
import _TripAdvisorDarkSvg from './trip-advisor/trip-advisor-dark.svg';
import _TrueBridgeLightSvg from './true-bridge/true-bridge-light.svg';
import _TrueBridgeDarkSvg from './true-bridge/true-bridge-dark.svg';
import _TruesecLightSvg from './truesec/truesec-light.svg';
import _TruesecDarkSvg from './truesec/truesec-dark.svg';
import _TuftNeedleLightSvg from './tuft-needle/tuft-needle-light.svg';
import _TuftNeedleDarkSvg from './tuft-needle/tuft-needle-dark.svg';
import _TursoLightSvg from './turso/turso-light.svg';
import _TursoDarkSvg from './turso/turso-dark.svg';
import _TwilioColorLightSvg from './twilio/twilio-color-light.svg';
import _TwilioColorDarkSvg from './twilio/twilio-color-dark.svg';
import _TwilioLightSvg from './twilio/twilio-light.svg';
import _TwilioDarkSvg from './twilio/twilio-dark.svg';
import _TypedreamColorLightSvg from './typedream/typedream-color-light.svg';
import _TypedreamLightSvg from './typedream/typedream-light.svg';
import _TypedreamColorDarkSvg from './typedream/typedream-color-dark.svg';
import _TypedreamDarkSvg from './typedream/typedream-dark.svg';
import _TypefullyColorLightSvg from './typefully/typefully-color-light.svg';
import _TypefullyColorDarkSvg from './typefully/typefully-color-dark.svg';
import _TypefullyLightSvg from './typefully/typefully-light.svg';
import _TypefullyDarkSvg from './typefully/typefully-dark.svg';
import _UNextLightSvg from './u-next/u-next-light.svg';
import _UNextDarkSvg from './u-next/u-next-dark.svg';
import _UberLightSvg from './uber/uber-light.svg';
import _UberDarkSvg from './uber/uber-dark.svg';
import _UmbracoColorLightSvg from './umbraco/umbraco-color-light.svg';
import _UmbracoColorDarkSvg from './umbraco/umbraco-color-dark.svg';
import _UmbracoLightSvg from './umbraco/umbraco-light.svg';
import _UmbracoDarkSvg from './umbraco/umbraco-dark.svg';
import _UmiJsColorLightSvg from './umi-js/umi-js-color-light.svg';
import _UmiJsColorDarkSvg from './umi-js/umi-js-color-dark.svg';
import _UmiJsLightSvg from './umi-js/umi-js-light.svg';
import _UmiJsDarkSvg from './umi-js/umi-js-dark.svg';
import _UnderArmourLightSvg from './under-armour/under-armour-light.svg';
import _UnderArmourDarkSvg from './under-armour/under-armour-dark.svg';
import _UniformColorLightSvg from './uniform/uniform-color-light.svg';
import _UniformColorDarkSvg from './uniform/uniform-color-dark.svg';
import _UniformLightSvg from './uniform/uniform-light.svg';
import _UniformDarkSvg from './uniform/uniform-dark.svg';
import _UniSuiperColorLightSvg from './uni-suiper/uni-suiper-color-light.svg';
import _UniSuiperColorDarkSvg from './uni-suiper/uni-suiper-color-dark.svg';
import _UniSuiperLightSvg from './uni-suiper/uni-suiper-light.svg';
import _UniSuiperDarkSvg from './uni-suiper/uni-suiper-dark.svg';
import _UnitedSurgicalPartnersColorLightSvg from './united-surgical-partners/united-surgical-partners-color-light.svg';
import _UnitedSurgicalPartnersColorDarkSvg from './united-surgical-partners/united-surgical-partners-color-dark.svg';
import _UnitedSurgicalPartnersLightSvg from './united-surgical-partners/united-surgical-partners-light.svg';
import _UnitedSurgicalPartnersDarkSvg from './united-surgical-partners/united-surgical-partners-dark.svg';
import _UnityLightSvg from './unity/unity-light.svg';
import _UnityDarkSvg from './unity/unity-dark.svg';
import _UniverseLightSvg from './universe/universe-light.svg';
import _UniverseDarkSvg from './universe/universe-dark.svg';
import _UnlikelyTechnologyLightSvg from './unlikely-technology/unlikely-technology-light.svg';
import _UnlikelyTechnologyDarkSvg from './unlikely-technology/unlikely-technology-dark.svg';
import _UpliftDeskLightSvg from './uplift-desk/uplift-desk-light.svg';
import _UpliftDeskDarkSvg from './uplift-desk/uplift-desk-dark.svg';
import _UpstartColorLightSvg from './upstart/upstart-color-light.svg';
import _UpstartLightSvg from './upstart/upstart-light.svg';
import _UpstartColorDarkSvg from './upstart/upstart-color-dark.svg';
import _UpstartDarkSvg from './upstart/upstart-dark.svg';
import _UpstashColorLightSvg from './upstash/upstash-color-light.svg';
import _UpstashColorDarkSvg from './upstash/upstash-color-dark.svg';
import _UpstashLightSvg from './upstash/upstash-light.svg';
import _UpstashDarkSvg from './upstash/upstash-dark.svg';
import _ValtechLightSvg from './valtech/valtech-light.svg';
import _ValtechDarkSvg from './valtech/valtech-dark.svg';
import _VaroLightSvg from './varo/varo-light.svg';
import _VaroDarkSvg from './varo/varo-dark.svg';
import _VelirLightSvg from './velir/velir-light.svg';
import _VelirDarkSvg from './velir/velir-dark.svg';
import _VerkadaLightSvg from './verkada/verkada-light.svg';
import _VerkadaDarkSvg from './verkada/verkada-dark.svg';
import _ViableColorLightSvg from './viable/viable-color-light.svg';
import _ViableColorDarkSvg from './viable/viable-color-dark.svg';
import _ViableLightSvg from './viable/viable-light.svg';
import _ViableDarkSvg from './viable/viable-dark.svg';
import _VillageRoadshowPicturesLightSvg from './village-roadshow-pictures/village-roadshow-pictures-light.svg';
import _VillageRoadshowPicturesDarkSvg from './village-roadshow-pictures/village-roadshow-pictures-dark.svg';
import _VimeoLightSvg from './vimeo/vimeo-light.svg';
import _VimeoDarkSvg from './vimeo/vimeo-dark.svg';
import _VirginColorLightSvg from './virgin/virgin-color-light.svg';
import _VirginColorDarkSvg from './virgin/virgin-color-dark.svg';
import _VirginLightSvg from './virgin/virgin-light.svg';
import _VirginDarkSvg from './virgin/virgin-dark.svg';
import _ViteColorLightSvg from './vite/vite-color-light.svg';
import _ViteColorDarkSvg from './vite/vite-color-dark.svg';
import _ViteLightSvg from './vite/vite-light.svg';
import _ViteDarkSvg from './vite/vite-dark.svg';
import _VizientColorLightSvg from './vizient/vizient-color-light.svg';
import _VizientColorDarkSvg from './vizient/vizient-color-dark.svg';
import _VizientLightSvg from './vizient/vizient-light.svg';
import _VizientDarkSvg from './vizient/vizient-dark.svg';
import _VolvoLightSvg from './volvo/volvo-light.svg';
import _VolvoDarkSvg from './volvo/volvo-dark.svg';
import _VoxmediaLightSvg from './voxmedia/voxmedia-light.svg';
import _VoxmediaDarkSvg from './voxmedia/voxmedia-dark.svg';
import _VscoLightSvg from './vsco/vsco-light.svg';
import _VscoDarkSvg from './vsco/vsco-dark.svg';
import _VueColorLightSvg from './vue/vue-color-light.svg';
import _VueColorDarkSvg from './vue/vue-color-dark.svg';
import _VueLightSvg from './vue/vue-light.svg';
import _VueDarkSvg from './vue/vue-dark.svg';
import _WalmartColorLightSvg from './walmart/walmart-color-light.svg';
import _WalmartColorDarkSvg from './walmart/walmart-color-dark.svg';
import _WalmartLightSvg from './walmart/walmart-light.svg';
import _WalmartDarkSvg from './walmart/walmart-dark.svg';
import _WanderLightSvg from './wander/wander-light.svg';
import _WanderDarkSvg from './wander/wander-dark.svg';
import _WashingtonPostLightSvg from './washington-post/washington-post-light.svg';
import _WashingtonPostDarkSvg from './washington-post/washington-post-dark.svg';
import _WayfairColorLightSvg from './wayfair/wayfair-color-light.svg';
import _WayfairColorDarkSvg from './wayfair/wayfair-color-dark.svg';
import _WayfairLightSvg from './wayfair/wayfair-light.svg';
import _WayfairDarkSvg from './wayfair/wayfair-dark.svg';
import _WellfoundColorLightSvg from './wellfound/wellfound-color-light.svg';
import _WellfoundLightSvg from './wellfound/wellfound-light.svg';
import _WellfoundDarkSvg from './wellfound/wellfound-dark.svg';
import _WhoopLightSvg from './whoop/whoop-light.svg';
import _WhoopDarkSvg from './whoop/whoop-dark.svg';
import _WhitehatJrLightSvg from './whitehat-jr/whitehat-jr-light.svg';
import _WhitehatJrDarkSvg from './whitehat-jr/whitehat-jr-dark.svg';
import _WhopColorLightSvg from './whop/whop-color-light.svg';
import _WhopLightSvg from './whop/whop-light.svg';
import _WhopColorDarkSvg from './whop/whop-color-dark.svg';
import _WhopDarkSvg from './whop/whop-dark.svg';
import _WixLightSvg from './wix/wix-light.svg';
import _WixDarkSvg from './wix/wix-dark.svg';
import _WizColorLightSvg from './wiz/wiz-color-light.svg';
import _WizColorDarkSvg from './wiz/wiz-color-dark.svg';
import _WizLightSvg from './wiz/wiz-light.svg';
import _WizDarkSvg from './wiz/wiz-dark.svg';
import _WppColorLightSvg from './wpp/wpp-color-light.svg';
import _WppLightSvg from './wpp/wpp-light.svg';
import _WppDarkSvg from './wpp/wpp-dark.svg';
import _WundermanThompsonLightSvg from './wunderman-thompson/wunderman-thompson-light.svg';
import _WundermanThompsonDarkSvg from './wunderman-thompson/wunderman-thompson-dark.svg';
import _WebpackColorLightSvg from './webpack/webpack-color-light.svg';
import _WebpackColorDarkSvg from './webpack/webpack-color-dark.svg';
import _WebpackLightSvg from './webpack/webpack-light.svg';
import _WebpackDarkSvg from './webpack/webpack-dark.svg';
import _YseLightSvg from './yse/yse-light.svg';
import _YseDarkSvg from './yse/yse-dark.svg';
import _ZapierColorLightSvg from './zapier/zapier-color-light.svg';
import _ZapierColorDarkSvg from './zapier/zapier-color-dark.svg';
import _ZapierLightSvg from './zapier/zapier-light.svg';
import _ZapierDarkSvg from './zapier/zapier-dark.svg';
import _ZolaLightSvg from './zola/zola-light.svg';
import _ZolaDarkSvg from './zola/zola-dark.svg';
import _ZoraLightSvg from './zora/zora-light.svg';
import _ZoraDarkSvg from './zora/zora-dark.svg';

// Do not edit this file manually. Auto-generated by geist 'pnpm generate-logos'.
export const logos = {
  'vercel-logo': {
    height: 100,
    width: 115,
    balancedScale: 1.3,
    variants: {
      light: _VercelLogoLightSvg,
      dark: _VercelLogoDarkSvg,
    } as LogoVariant,
  },
  'vercel-logotype': {
    height: 64,
    width: 283,
    balancedScale: 0.8,
    variants: {
      light: _VercelLogotypeLightSvg,
      dark: _VercelLogotypeDarkSvg,
    } as LogoVariant,
  },
  'vercel-logotype-thin': {
    height: 64,
    width: 283,
    balancedScale: 0.8,
    variants: {
      light: _VercelLogotypeThinLightSvg,
      dark: _VercelLogotypeThinDarkSvg,
    } as LogoVariant,
  },
  'geist-logo': {
    height: 101,
    width: 114,
    balancedScale: 1.3,
    variants: {
      light: _GeistLogoLightSvg,
      dark: _GeistLogoDarkSvg,
    } as LogoVariant,
  },
  'nextjs-logotype': {
    height: 56,
    width: 278,
    balancedScale: 0.7,
    variants: {
      light: _NextjsLogotypeLightSvg,
      dark: _NextjsLogotypeDarkSvg,
    } as LogoVariant,
  },
  'nextjs-logo': {
    height: 100,
    width: 100,
    balancedScale: 1.3,
    variants: {
      light: _NextjsLogoLightSvg,
      dark: _NextjsLogoDarkSvg,
    } as LogoVariant,
  },
  'turbo-logotype': {
    height: 63,
    width: 297,
    balancedScale: 0.8,
    variants: {
      'color-light': _TurboLogotypeColorLightSvg,
      light: _TurboLogotypeLightSvg,
      'color-dark': _TurboLogotypeColorDarkSvg,
      dark: _TurboLogotypeDarkSvg,
    } as LogoVariant,
  },
  'turborepo-logotype': {
    height: 63,
    width: 473,
    balancedScale: 0.8,
    variants: {
      'color-light': _TurborepoLogotypeColorLightSvg,
      light: _TurborepoLogotypeLightSvg,
      'color-dark': _TurborepoLogotypeColorDarkSvg,
      dark: _TurborepoLogotypeDarkSvg,
    } as LogoVariant,
  },
  'turbopack-logotype': {
    height: 63,
    width: 473,
    balancedScale: 0.8,
    variants: {
      'color-light': _TurbopackLogotypeColorLightSvg,
      light: _TurbopackLogotypeLightSvg,
      'color-dark': _TurbopackLogotypeColorDarkSvg,
      dark: _TurbopackLogotypeDarkSvg,
    } as LogoVariant,
  },
  'turbo-logo': {
    height: 100,
    width: 100,
    balancedScale: 1.3,
    variants: {
      'color-light': _TurboLogoColorLightSvg,
      light: _TurboLogoLightSvg,
      'color-dark': _TurboLogoColorDarkSvg,
      dark: _TurboLogoDarkSvg,
    } as LogoVariant,
  },
  'turbopack-logo': {
    height: 100,
    width: 100,
    balancedScale: 1.3,
    variants: {
      'color-light': _TurbopackLogoColorLightSvg,
      light: _TurbopackLogoLightSvg,
      'color-dark': _TurbopackLogoColorDarkSvg,
      dark: _TurbopackLogoDarkSvg,
    } as LogoVariant,
  },
  'hyper-logo': {
    height: 100,
    width: 108,
    balancedScale: 1.3,
    variants: {
      light: _HyperLogoLightSvg,
      dark: _HyperLogoDarkSvg,
    } as LogoVariant,
  },
  'vzero-logo': {
    height: 72,
    width: 145,
    balancedScale: 0.9,
    variants: {
      light: _VzeroLogoLightSvg,
      dark: _VzeroLogoDarkSvg,
    } as LogoVariant,
  },
  zeroxide: {
    height: 59,
    width: 210,
    balancedScale: 0.7,
    variants: {
      light: _ZeroxideLightSvg,
      dark: _ZeroxideDarkSvg,
    } as LogoVariant,
  },
  'eight-vc': {
    height: 72,
    width: 180,
    balancedScale: 0.9,
    variants: {
      light: _EightVcLightSvg,
      dark: _EightVcDarkSvg,
    } as LogoVariant,
  },
  'one-zeroup': {
    height: 132,
    width: 116,
    balancedScale: 1.7,
    variants: {
      'color-light': _OneZeroupColorLightSvg,
      'color-dark': _OneZeroupColorDarkSvg,
      light: _OneZeroupLightSvg,
      dark: _OneZeroupDarkSvg,
    } as LogoVariant,
  },
  'onezerox-genomics': {
    height: 74,
    width: 320,
    balancedScale: 0.9,
    variants: {
      'color-light': _OnezeroxGenomicsColorLightSvg,
      'color-dark': _OnezeroxGenomicsColorDarkSvg,
      light: _OnezeroxGenomicsLightSvg,
      dark: _OnezeroxGenomicsDarkSvg,
    } as LogoVariant,
  },
  'one-onety': {
    height: 118,
    width: 140,
    balancedScale: 1.5,
    variants: {
      light: _OneOnetyLightSvg,
      dark: _OneOnetyDarkSvg,
    } as LogoVariant,
  },
  'a-cloud-guru': {
    height: 61,
    width: 390,
    balancedScale: 0.8,
    variants: {
      light: _ACloudGuruLightSvg,
      dark: _ACloudGuruDarkSvg,
    } as LogoVariant,
  },
  'ae-networks': {
    height: 120,
    width: 220,
    balancedScale: 1.5,
    variants: {
      light: _AeNetworksLightSvg,
      dark: _AeNetworksDarkSvg,
    } as LogoVariant,
  },
  aaa: {
    height: 115,
    width: 190,
    balancedScale: 1.4,
    variants: {
      'color-light': _AaaColorLightSvg,
      'color-dark': _AaaColorDarkSvg,
      light: _AaaLightSvg,
      dark: _AaaDarkSvg,
    } as LogoVariant,
  },
  abnormal: {
    height: 56,
    width: 320,
    balancedScale: 0.7,
    variants: {
      light: _AbnormalLightSvg,
      dark: _AbnormalDarkSvg,
    } as LogoVariant,
  },
  accel: {
    height: 70,
    width: 220,
    balancedScale: 0.9,
    variants: {
      'color-light': _AccelColorLightSvg,
      light: _AccelLightSvg,
      dark: _AccelDarkSvg,
    } as LogoVariant,
  },
  achmea: {
    height: 52,
    width: 280,
    balancedScale: 0.7,
    variants: {
      'color-light': _AchmeaColorLightSvg,
      'color-dark': _AchmeaColorDarkSvg,
      light: _AchmeaLightSvg,
      dark: _AchmeaDarkSvg,
    } as LogoVariant,
  },
  adept: {
    height: 79,
    width: 240,
    balancedScale: 1,
    variants: {
      'color-light': _AdeptColorLightSvg,
      light: _AdeptLightSvg,
      dark: _AdeptDarkSvg,
    } as LogoVariant,
  },
  adobe: {
    height: 74,
    width: 280,
    balancedScale: 0.9,
    variants: {
      'color-light': _AdobeColorLightSvg,
      'color-dark': _AdobeColorDarkSvg,
      light: _AdobeLightSvg,
      dark: _AdobeDarkSvg,
    } as LogoVariant,
  },
  'ae-studio': {
    height: 80,
    width: 300,
    balancedScale: 1,
    variants: {
      'color-light': _AeStudioColorLightSvg,
      light: _AeStudioLightSvg,
      'color-dark': _AeStudioColorDarkSvg,
      dark: _AeStudioDarkSvg,
    } as LogoVariant,
  },
  'agency-q': {
    height: 86,
    width: 280,
    balancedScale: 1.1,
    variants: {
      light: _AgencyQLightSvg,
      dark: _AgencyQDarkSvg,
    } as LogoVariant,
  },
  airbnb: {
    height: 85,
    width: 270,
    balancedScale: 1.1,
    variants: {
      'color-light': _AirbnbColorLightSvg,
      light: _AirbnbLightSvg,
      dark: _AirbnbDarkSvg,
    } as LogoVariant,
  },
  akqa: {
    height: 101,
    width: 210,
    balancedScale: 1.3,
    variants: {
      light: _AkqaLightSvg,
      dark: _AkqaDarkSvg,
    } as LogoVariant,
  },
  algolia: {
    height: 64,
    width: 280,
    balancedScale: 0.8,
    variants: {
      'color-light': _AlgoliaColorLightSvg,
      'color-dark': _AlgoliaColorDarkSvg,
      light: _AlgoliaLightSvg,
      dark: _AlgoliaDarkSvg,
    } as LogoVariant,
  },
  'american-eagle-com': {
    height: 158,
    width: 260,
    balancedScale: 2,
    variants: {
      light: _AmericanEagleComLightSvg,
      dark: _AmericanEagleComDarkSvg,
    } as LogoVariant,
  },
  amherst: {
    height: 104,
    width: 300,
    balancedScale: 1.3,
    variants: {
      light: _AmherstLightSvg,
      dark: _AmherstDarkSvg,
    } as LogoVariant,
  },
  amplitude: {
    height: 65,
    width: 310,
    balancedScale: 0.8,
    variants: {
      'color-light': _AmplitudeColorLightSvg,
      light: _AmplitudeLightSvg,
      dark: _AmplitudeDarkSvg,
    } as LogoVariant,
  },
  andersen: {
    height: 41,
    width: 280,
    balancedScale: 0.5,
    variants: {
      'color-light': _AndersenColorLightSvg,
      'color-dark': _AndersenColorDarkSvg,
      light: _AndersenLightSvg,
      dark: _AndersenDarkSvg,
    } as LogoVariant,
  },
  'angular-logo': {
    height: 120,
    width: 112,
    balancedScale: 1.5,
    variants: {
      'color-light': _AngularLogoColorLightSvg,
      'color-dark': _AngularLogoColorDarkSvg,
      light: _AngularLogoLightSvg,
      dark: _AngularLogoDarkSvg,
    } as LogoVariant,
  },
  'angular-logotype': {
    height: 76,
    width: 280,
    balancedScale: 1,
    variants: {
      'color-light': _AngularLogotypeColorLightSvg,
      'color-dark': _AngularLogotypeColorDarkSvg,
      light: _AngularLogotypeLightSvg,
      dark: _AngularLogotypeDarkSvg,
    } as LogoVariant,
  },
  anthropic: {
    height: 38,
    width: 330,
    balancedScale: 0.5,
    variants: {
      light: _AnthropicLightSvg,
      dark: _AnthropicDarkSvg,
    } as LogoVariant,
  },
  'apply-digital': {
    height: 67,
    width: 257,
    balancedScale: 0.8,
    variants: {
      light: _ApplyDigitalLightSvg,
      dark: _ApplyDigitalDarkSvg,
    } as LogoVariant,
  },
  appnovation: {
    height: 57,
    width: 360,
    balancedScale: 0.7,
    variants: {
      light: _AppnovationLightSvg,
      dark: _AppnovationDarkSvg,
    } as LogoVariant,
  },
  april: {
    height: 61,
    width: 250,
    balancedScale: 0.8,
    variants: {
      light: _AprilLightSvg,
      dark: _AprilDarkSvg,
    } as LogoVariant,
  },
  arc: {
    height: 64,
    width: 160,
    balancedScale: 0.8,
    variants: {
      light: _ArcLightSvg,
      dark: _ArcDarkSvg,
    } as LogoVariant,
  },
  archer: {
    height: 40,
    width: 320,
    balancedScale: 0.5,
    variants: {
      light: _ArcherLightSvg,
      dark: _ArcherDarkSvg,
    } as LogoVariant,
  },
  asics: {
    height: 80,
    width: 240,
    balancedScale: 1,
    variants: {
      'color-light': _AsicsColorLightSvg,
      light: _AsicsLightSvg,
      dark: _AsicsDarkSvg,
    } as LogoVariant,
  },
  asml: {
    height: 62,
    width: 220,
    balancedScale: 0.8,
    variants: {
      'color-light': _AsmlColorLightSvg,
      light: _AsmlLightSvg,
      dark: _AsmlDarkSvg,
    } as LogoVariant,
  },
  'astro-logo': {
    height: 120,
    width: 96,
    balancedScale: 1.5,
    variants: {
      'color-light': _AstroLogoColorLightSvg,
      'color-dark': _AstroLogoColorDarkSvg,
      light: _AstroLogoLightSvg,
      dark: _AstroLogoDarkSvg,
    } as LogoVariant,
  },
  'astro-logotype': {
    height: 74,
    width: 280,
    balancedScale: 0.9,
    variants: {
      'color-light': _AstroLogotypeColorLightSvg,
      'color-dark': _AstroLogotypeColorDarkSvg,
      light: _AstroLogotypeLightSvg,
      dark: _AstroLogotypeDarkSvg,
    } as LogoVariant,
  },
  asurion: {
    height: 61,
    width: 260,
    balancedScale: 0.8,
    variants: {
      light: _AsurionLightSvg,
      dark: _AsurionDarkSvg,
    } as LogoVariant,
  },
  async: {
    height: 77,
    width: 210,
    balancedScale: 1,
    variants: {
      light: _AsyncLightSvg,
      dark: _AsyncDarkSvg,
    } as LogoVariant,
  },
  'athletic-greens': {
    height: 36,
    width: 420,
    balancedScale: 0.5,
    variants: {
      'color-light': _AthleticGreensColorLightSvg,
      light: _AthleticGreensLightSvg,
      dark: _AthleticGreensDarkSvg,
    } as LogoVariant,
  },
  'athletic-greens-agone': {
    height: 73,
    width: 180,
    balancedScale: 0.9,
    variants: {
      'color-light': _AthleticGreensAgoneColorLightSvg,
      'color-dark': _AthleticGreensAgoneColorDarkSvg,
      light: _AthleticGreensAgoneLightSvg,
      dark: _AthleticGreensAgoneDarkSvg,
    } as LogoVariant,
  },
  aumni: {
    height: 68,
    width: 230,
    balancedScale: 0.9,
    variants: {
      light: _AumniLightSvg,
      dark: _AumniDarkSvg,
    } as LogoVariant,
  },
  'auth-zero': {
    height: 73,
    width: 340,
    balancedScale: 0.9,
    variants: {
      light: _AuthZeroLightSvg,
      dark: _AuthZeroDarkSvg,
    } as LogoVariant,
  },
  automattic: {
    height: 33,
    width: 420,
    balancedScale: 0.4,
    variants: {
      light: _AutomatticLightSvg,
      dark: _AutomatticDarkSvg,
    } as LogoVariant,
  },
  avanade: {
    height: 81,
    width: 320,
    balancedScale: 1,
    variants: {
      'color-light': _AvanadeColorLightSvg,
      'color-dark': _AvanadeColorDarkSvg,
      light: _AvanadeLightSvg,
      dark: _AvanadeDarkSvg,
    } as LogoVariant,
  },
  aws: {
    height: 108,
    width: 180,
    balancedScale: 1.4,
    variants: {
      'color-light': _AwsColorLightSvg,
      'color-dark': _AwsColorDarkSvg,
      light: _AwsLightSvg,
      dark: _AwsDarkSvg,
    } as LogoVariant,
  },
  'axel-arigato': {
    height: 46,
    width: 320,
    balancedScale: 0.6,
    variants: {
      light: _AxelArigatoLightSvg,
      dark: _AxelArigatoDarkSvg,
    } as LogoVariant,
  },
  axiom: {
    height: 52,
    width: 290,
    balancedScale: 0.7,
    variants: {
      light: _AxiomLightSvg,
      dark: _AxiomDarkSvg,
    } as LogoVariant,
  },
  azure: {
    height: 70,
    width: 240,
    balancedScale: 0.9,
    variants: {
      'color-light': _AzureColorLightSvg,
      'color-dark': _AzureColorDarkSvg,
      light: _AzureLightSvg,
      dark: _AzureDarkSvg,
    } as LogoVariant,
  },
  banana: {
    height: 54,
    width: 260,
    balancedScale: 0.7,
    variants: {
      light: _BananaLightSvg,
      dark: _BananaDarkSvg,
    } as LogoVariant,
  },
  barnebys: {
    height: 68,
    width: 340,
    balancedScale: 0.9,
    variants: {
      light: _BarnebysLightSvg,
      dark: _BarnebysDarkSvg,
    } as LogoVariant,
  },
  'barstool-sports': {
    height: 96,
    width: 300,
    balancedScale: 1.2,
    variants: {
      light: _BarstoolSportsLightSvg,
      dark: _BarstoolSportsDarkSvg,
    } as LogoVariant,
  },
  basement: {
    height: 37,
    width: 260,
    balancedScale: 0.5,
    variants: {
      'color-light': _BasementColorLightSvg,
      'color-dark': _BasementColorDarkSvg,
      light: _BasementLightSvg,
      dark: _BasementDarkSvg,
    } as LogoVariant,
  },
  bayer: {
    height: 181,
    width: 180,
    balancedScale: 2.3,
    variants: {
      'color-light': _BayerColorLightSvg,
      'color-dark': _BayerColorDarkSvg,
      light: _BayerLightSvg,
      dark: _BayerDarkSvg,
    } as LogoVariant,
  },
  bcg: {
    height: 74,
    width: 180,
    balancedScale: 0.9,
    variants: {
      light: _BcgLightSvg,
      dark: _BcgDarkSvg,
    } as LogoVariant,
  },
  bedrock: {
    height: 45,
    width: 340,
    balancedScale: 0.6,
    variants: {
      light: _BedrockLightSvg,
      dark: _BedrockDarkSvg,
    } as LogoVariant,
  },
  'big-commerce': {
    height: 73,
    width: 320,
    balancedScale: 0.9,
    variants: {
      'color-light': _BigCommerceColorLightSvg,
      light: _BigCommerceLightSvg,
      dark: _BigCommerceDarkSvg,
    } as LogoVariant,
  },
  blackmores: {
    height: 41,
    width: 380,
    balancedScale: 0.5,
    variants: {
      light: _BlackmoresLightSvg,
      dark: _BlackmoresDarkSvg,
    } as LogoVariant,
  },
  'blitz-logo': {
    height: 140,
    width: 92,
    balancedScale: 1.8,
    variants: {
      'color-light': _BlitzLogoColorLightSvg,
      'color-dark': _BlitzLogoColorDarkSvg,
      light: _BlitzLogoLightSvg,
      dark: _BlitzLogoDarkSvg,
    } as LogoVariant,
  },
  'blitz-logotype': {
    height: 95,
    width: 241,
    balancedScale: 1.2,
    variants: {
      'color-light': _BlitzLogotypeColorLightSvg,
      'color-dark': _BlitzLogotypeColorDarkSvg,
      light: _BlitzLogotypeLightSvg,
      dark: _BlitzLogotypeDarkSvg,
    } as LogoVariant,
  },
  'bluecross-blueshield-arizona': {
    height: 82,
    width: 320,
    balancedScale: 1,
    variants: {
      'color-light': _BluecrossBlueshieldArizonaColorLightSvg,
      'color-dark': _BluecrossBlueshieldArizonaColorDarkSvg,
      light: _BluecrossBlueshieldArizonaLightSvg,
      dark: _BluecrossBlueshieldArizonaDarkSvg,
    } as LogoVariant,
  },
  blush: {
    height: 88,
    width: 260,
    balancedScale: 1.1,
    variants: {
      'color-light': _BlushColorLightSvg,
      light: _BlushLightSvg,
      'color-dark': _BlushColorDarkSvg,
      dark: _BlushDarkSvg,
    } as LogoVariant,
  },
  bolt: {
    height: 64,
    width: 220,
    balancedScale: 0.8,
    variants: {
      light: _BoltLightSvg,
      dark: _BoltDarkSvg,
    } as LogoVariant,
  },
  bombas: {
    height: 123,
    width: 260,
    balancedScale: 1.5,
    variants: {
      'color-light': _BombasColorLightSvg,
      'color-dark': _BombasColorDarkSvg,
      light: _BombasLightSvg,
      dark: _BombasDarkSvg,
    } as LogoVariant,
  },
  boom: {
    height: 77,
    width: 300,
    balancedScale: 1,
    variants: {
      light: _BoomLightSvg,
      dark: _BoomDarkSvg,
    } as LogoVariant,
  },
  bose: {
    height: 40,
    width: 340,
    balancedScale: 0.5,
    variants: {
      light: _BoseLightSvg,
      dark: _BoseDarkSvg,
    } as LogoVariant,
  },
  box: {
    height: 87,
    width: 160,
    balancedScale: 1.1,
    variants: {
      'color-light': _BoxColorLightSvg,
      light: _BoxLightSvg,
      dark: _BoxDarkSvg,
    } as LogoVariant,
  },
  brandung: {
    height: 86,
    width: 290,
    balancedScale: 1.1,
    variants: {
      light: _BrandungLightSvg,
      dark: _BrandungDarkSvg,
    } as LogoVariant,
  },
  brex: {
    height: 74,
    width: 280,
    balancedScale: 0.9,
    variants: {
      light: _BrexLightSvg,
      dark: _BrexDarkSvg,
    } as LogoVariant,
  },
  brioni: {
    height: 105,
    width: 260,
    balancedScale: 1.3,
    variants: {
      light: _BrioniLightSvg,
      dark: _BrioniDarkSvg,
    } as LogoVariant,
  },
  'browser-company': {
    height: 131,
    width: 260,
    balancedScale: 1.6,
    variants: {
      'color-light': _BrowserCompanyColorLightSvg,
      light: _BrowserCompanyLightSvg,
      dark: _BrowserCompanyDarkSvg,
    } as LogoVariant,
  },
  brunch: {
    height: 138,
    width: 140,
    balancedScale: 1.7,
    variants: {
      'color-light': _BrunchColorLightSvg,
      'color-dark': _BrunchColorDarkSvg,
      light: _BrunchLightSvg,
      dark: _BrunchDarkSvg,
    } as LogoVariant,
  },
  'brunch-logotype': {
    height: 96,
    width: 280,
    balancedScale: 1.2,
    variants: {
      'color-light': _BrunchLogotypeColorLightSvg,
      'color-dark': _BrunchLogotypeColorDarkSvg,
      light: _BrunchLogotypeLightSvg,
      dark: _BrunchLogotypeDarkSvg,
    } as LogoVariant,
  },
  'byju-s': {
    height: 55,
    width: 256,
    balancedScale: 0.7,
    variants: {
      light: _ByjuSLightSvg,
      dark: _ByjuSDarkSvg,
    } as LogoVariant,
  },
  'caffeinated-capital': {
    height: 80,
    width: 340,
    balancedScale: 1,
    variants: {
      light: _CaffeinatedCapitalLightSvg,
      dark: _CaffeinatedCapitalDarkSvg,
    } as LogoVariant,
  },
  'caloptima-health': {
    height: 78,
    width: 400,
    balancedScale: 1,
    variants: {
      'color-light': _CaloptimaHealthColorLightSvg,
      'color-dark': _CaloptimaHealthColorDarkSvg,
      light: _CaloptimaHealthLightSvg,
      dark: _CaloptimaHealthDarkSvg,
    } as LogoVariant,
  },
  'campaign-monitor': {
    height: 43,
    width: 410,
    balancedScale: 0.5,
    variants: {
      light: _CampaignMonitorLightSvg,
      dark: _CampaignMonitorDarkSvg,
    } as LogoVariant,
  },
  candor: {
    height: 62,
    width: 260,
    balancedScale: 0.8,
    variants: {
      light: _CandorLightSvg,
      dark: _CandorDarkSvg,
    } as LogoVariant,
  },
  capsule: {
    height: 72,
    width: 360,
    balancedScale: 0.9,
    variants: {
      'color-light': _CapsuleColorLightSvg,
      'color-dark': _CapsuleColorDarkSvg,
      light: _CapsuleLightSvg,
      dark: _CapsuleDarkSvg,
    } as LogoVariant,
  },
  carhartt: {
    height: 42,
    width: 320,
    balancedScale: 0.5,
    variants: {
      light: _CarharttLightSvg,
      dark: _CarharttDarkSvg,
    } as LogoVariant,
  },
  chainguard: {
    height: 69,
    width: 360,
    balancedScale: 0.9,
    variants: {
      'color-light': _ChainguardColorLightSvg,
      'color-dark': _ChainguardColorDarkSvg,
      light: _ChainguardLightSvg,
      dark: _ChainguardDarkSvg,
    } as LogoVariant,
  },
  'change-lingerie': {
    height: 67,
    width: 240,
    balancedScale: 0.8,
    variants: {
      light: _ChangeLingerieLightSvg,
      dark: _ChangeLingerieDarkSvg,
    } as LogoVariant,
  },
  'charles-river-ventures': {
    height: 140,
    width: 140,
    balancedScale: 1.8,
    variants: {
      'color-light': _CharlesRiverVenturesColorLightSvg,
      'color-dark': _CharlesRiverVenturesColorDarkSvg,
      light: _CharlesRiverVenturesLightSvg,
      dark: _CharlesRiverVenturesDarkSvg,
    } as LogoVariant,
  },
  chatbase: {
    height: 71,
    width: 260,
    balancedScale: 0.9,
    variants: {
      light: _ChatbaseLightSvg,
      dark: _ChatbaseDarkSvg,
    } as LogoVariant,
  },
  'checkout-com': {
    height: 50,
    width: 380,
    balancedScale: 0.6,
    variants: {
      light: _CheckoutComLightSvg,
      dark: _CheckoutComDarkSvg,
    } as LogoVariant,
  },
  chegg: {
    height: 77,
    width: 230,
    balancedScale: 1,
    variants: {
      'color-light': _CheggColorLightSvg,
      'color-dark': _CheggColorDarkSvg,
      light: _CheggLightSvg,
      dark: _CheggDarkSvg,
    } as LogoVariant,
  },
  'chick-fil-a': {
    height: 127,
    width: 260,
    balancedScale: 1.6,
    variants: {
      'color-light': _ChickFilAColorLightSvg,
      'color-dark': _ChickFilAColorDarkSvg,
      light: _ChickFilALightSvg,
      dark: _ChickFilADarkSvg,
    } as LogoVariant,
  },
  chicos: {
    height: 58,
    width: 280,
    balancedScale: 0.7,
    variants: {
      light: _ChicosLightSvg,
      dark: _ChicosDarkSvg,
    } as LogoVariant,
  },
  'childrens-national': {
    height: 54,
    width: 440,
    balancedScale: 0.7,
    variants: {
      'color-light': _ChildrensNationalColorLightSvg,
      'color-dark': _ChildrensNationalColorDarkSvg,
      light: _ChildrensNationalLightSvg,
      dark: _ChildrensNationalDarkSvg,
    } as LogoVariant,
  },
  chipotle: {
    height: 140,
    width: 140,
    balancedScale: 1.8,
    variants: {
      'color-light': _ChipotleColorLightSvg,
      'color-dark': _ChipotleColorDarkSvg,
      light: _ChipotleLightSvg,
      dark: _ChipotleDarkSvg,
    } as LogoVariant,
  },
  chiselstrike: {
    height: 36,
    width: 360,
    balancedScale: 0.5,
    variants: {
      light: _ChiselstrikeLightSvg,
      dark: _ChiselstrikeDarkSvg,
    } as LogoVariant,
  },
  chroma: {
    height: 46,
    width: 280,
    balancedScale: 0.6,
    variants: {
      light: _ChromaLightSvg,
      dark: _ChromaDarkSvg,
    } as LogoVariant,
  },
  clerk: {
    height: 76,
    width: 241,
    balancedScale: 1,
    variants: {
      'color-light': _ClerkColorLightSvg,
      'color-dark': _ClerkColorDarkSvg,
      light: _ClerkLightSvg,
      dark: _ClerkDarkSvg,
    } as LogoVariant,
  },
  cloudflare: {
    height: 93,
    width: 280,
    balancedScale: 1.2,
    variants: {
      'color-light': _CloudflareColorLightSvg,
      light: _CloudflareLightSvg,
      'color-dark': _CloudflareColorDarkSvg,
      dark: _CloudflareDarkSvg,
    } as LogoVariant,
  },
  'co-op': {
    height: 148,
    width: 140,
    balancedScale: 1.9,
    variants: {
      'color-light': _CoOpColorLightSvg,
      'color-dark': _CoOpColorDarkSvg,
      light: _CoOpLightSvg,
      dark: _CoOpDarkSvg,
    } as LogoVariant,
  },
  'code-and-theory': {
    height: 32,
    width: 420,
    balancedScale: 0.4,
    variants: {
      light: _CodeAndTheoryLightSvg,
      dark: _CodeAndTheoryDarkSvg,
    } as LogoVariant,
  },
  cohere: {
    height: 47,
    width: 281,
    balancedScale: 0.6,
    variants: {
      'color-light': _CohereColorLightSvg,
      'color-dark': _CohereColorDarkSvg,
      light: _CohereLightSvg,
      dark: _CohereDarkSvg,
    } as LogoVariant,
  },
  'coin-desk': {
    height: 71,
    width: 360,
    balancedScale: 0.9,
    variants: {
      'color-light': _CoinDeskColorLightSvg,
      'color-dark': _CoinDeskColorDarkSvg,
      light: _CoinDeskLightSvg,
      dark: _CoinDeskDarkSvg,
    } as LogoVariant,
  },
  'commerce-ui': {
    height: 67,
    width: 300,
    balancedScale: 0.8,
    variants: {
      light: _CommerceUiLightSvg,
      dark: _CommerceUiDarkSvg,
    } as LogoVariant,
  },
  'commerce-js': {
    height: 55,
    width: 340,
    balancedScale: 0.7,
    variants: {
      light: _CommerceJsLightSvg,
      dark: _CommerceJsDarkSvg,
    } as LogoVariant,
  },
  contentful: {
    height: 73,
    width: 350,
    balancedScale: 0.9,
    variants: {
      'color-light': _ContentfulColorLightSvg,
      'color-dark': _ContentfulColorDarkSvg,
      light: _ContentfulLightSvg,
      dark: _ContentfulDarkSvg,
    } as LogoVariant,
  },
  convex: {
    height: 50,
    width: 280,
    balancedScale: 0.6,
    variants: {
      light: _ConvexLightSvg,
      dark: _ConvexDarkSvg,
    } as LogoVariant,
  },
  coravin: {
    height: 51,
    width: 380,
    balancedScale: 0.6,
    variants: {
      light: _CoravinLightSvg,
      dark: _CoravinDarkSvg,
    } as LogoVariant,
  },
  corpay: {
    height: 58,
    width: 240,
    balancedScale: 0.7,
    variants: {
      'color-light': _CorpayColorLightSvg,
      'color-dark': _CorpayColorDarkSvg,
      light: _CorpayLightSvg,
      dark: _CorpayDarkSvg,
    } as LogoVariant,
  },
  corra: {
    height: 38,
    width: 341,
    balancedScale: 0.5,
    variants: {
      light: _CorraLightSvg,
      dark: _CorraDarkSvg,
    } as LogoVariant,
  },
  'country-road-grouip': {
    height: 27,
    width: 480,
    balancedScale: 0.3,
    variants: {
      light: _CountryRoadGrouipLightSvg,
      dark: _CountryRoadGrouipDarkSvg,
    } as LogoVariant,
  },
  covermymeds: {
    height: 61,
    width: 360,
    balancedScale: 0.8,
    variants: {
      light: _CovermymedsLightSvg,
      dark: _CovermymedsDarkSvg,
    } as LogoVariant,
  },
  'cruise-critic': {
    height: 54,
    width: 360,
    balancedScale: 0.7,
    variants: {
      'color-light': _CruiseCriticColorLightSvg,
      'color-dark': _CruiseCriticColorDarkSvg,
      light: _CruiseCriticLightSvg,
      dark: _CruiseCriticDarkSvg,
    } as LogoVariant,
  },
  crystallize: {
    height: 107,
    width: 320,
    balancedScale: 1.3,
    variants: {
      light: _CrystallizeLightSvg,
      dark: _CrystallizeDarkSvg,
    } as LogoVariant,
  },
  dagster: {
    height: 60,
    width: 281,
    balancedScale: 0.8,
    variants: {
      'color-light': _DagsterColorLightSvg,
      'color-dark': _DagsterColorDarkSvg,
      light: _DagsterLightSvg,
      dark: _DagsterDarkSvg,
    } as LogoVariant,
  },
  dashlane: {
    height: 86,
    width: 320,
    balancedScale: 1.1,
    variants: {
      'color-light': _DashlaneColorLightSvg,
      'color-dark': _DashlaneColorDarkSvg,
      light: _DashlaneLightSvg,
      dark: _DashlaneDarkSvg,
    } as LogoVariant,
  },
  databricks: {
    height: 45,
    width: 280,
    balancedScale: 0.6,
    variants: {
      'color-light': _DatabricksColorLightSvg,
      'color-dark': _DatabricksColorDarkSvg,
      light: _DatabricksLightSvg,
      dark: _DatabricksDarkSvg,
    } as LogoVariant,
  },
  datadog: {
    height: 77,
    width: 300,
    balancedScale: 1,
    variants: {
      'color-light': _DatadogColorLightSvg,
      'color-dark': _DatadogColorDarkSvg,
      light: _DatadogLightSvg,
      dark: _DatadogDarkSvg,
    } as LogoVariant,
  },
  datastax: {
    height: 34,
    width: 360,
    balancedScale: 0.4,
    variants: {
      light: _DatastaxLightSvg,
      dark: _DatastaxDarkSvg,
    } as LogoVariant,
  },
  dato: {
    height: 74,
    width: 240,
    balancedScale: 0.9,
    variants: {
      'color-light': _DatoColorLightSvg,
      'color-dark': _DatoColorDarkSvg,
      light: _DatoLightSvg,
      dark: _DatoDarkSvg,
    } as LogoVariant,
  },
  dena: {
    height: 99,
    width: 239,
    balancedScale: 1.2,
    variants: {
      light: _DenaLightSvg,
      dark: _DenaDarkSvg,
    } as LogoVariant,
  },
  descope: {
    height: 64,
    width: 281,
    balancedScale: 0.8,
    variants: {
      'color-light': _DescopeColorLightSvg,
      'color-dark': _DescopeColorDarkSvg,
      light: _DescopeLightSvg,
      dark: _DescopeDarkSvg,
    } as LogoVariant,
  },
  desenio: {
    height: 56,
    width: 300,
    balancedScale: 0.7,
    variants: {
      light: _DesenioLightSvg,
      dark: _DesenioDarkSvg,
    } as LogoVariant,
  },
  'devolver-digital': {
    height: 104,
    width: 231,
    balancedScale: 1.3,
    variants: {
      'color-light': _DevolverDigitalColorLightSvg,
      'color-dark': _DevolverDigitalColorDarkSvg,
      light: _DevolverDigitalLightSvg,
      dark: _DevolverDigitalDarkSvg,
    } as LogoVariant,
  },
  dexerto: {
    height: 87,
    width: 320,
    balancedScale: 1.1,
    variants: {
      light: _DexertoLightSvg,
      dark: _DexertoDarkSvg,
    } as LogoVariant,
  },
  dialpad: {
    height: 71,
    width: 300,
    balancedScale: 0.9,
    variants: {
      'color-light': _DialpadColorLightSvg,
      'color-dark': _DialpadColorDarkSvg,
      light: _DialpadLightSvg,
      dark: _DialpadDarkSvg,
    } as LogoVariant,
  },
  dice: {
    height: 90,
    width: 250,
    balancedScale: 1.1,
    variants: {
      light: _DiceLightSvg,
      dark: _DiceDarkSvg,
    } as LogoVariant,
  },
  disney: {
    height: 130,
    width: 260,
    balancedScale: 1.6,
    variants: {
      light: _DisneyLightSvg,
      dark: _DisneyDarkSvg,
    } as LogoVariant,
  },
  docusaurus: {
    height: 120,
    width: 140,
    balancedScale: 1.5,
    variants: {
      'color-light': _DocusaurusColorLightSvg,
      'color-dark': _DocusaurusColorDarkSvg,
      light: _DocusaurusLightSvg,
      dark: _DocusaurusDarkSvg,
    } as LogoVariant,
  },
  'dogo-logo': {
    height: 120,
    width: 168,
    balancedScale: 1.5,
    variants: {
      'color-light': _DogoLogoColorLightSvg,
      'color-dark': _DogoLogoColorDarkSvg,
      light: _DogoLogoLightSvg,
      dark: _DogoLogoDarkSvg,
    } as LogoVariant,
  },
  'dojo-logotype': {
    height: 81,
    width: 280,
    balancedScale: 1,
    variants: {
      'color-light': _DojoLogotypeColorLightSvg,
      'color-dark': _DojoLogotypeColorDarkSvg,
      light: _DojoLogotypeLightSvg,
      dark: _DojoLogotypeDarkSvg,
    } as LogoVariant,
  },
  dub: {
    height: 75,
    width: 180,
    balancedScale: 0.9,
    variants: {
      light: _DubLightSvg,
      dark: _DubDarkSvg,
    } as LogoVariant,
  },
  ebay: {
    height: 96,
    width: 240,
    balancedScale: 1.2,
    variants: {
      'color-light': _EbayColorLightSvg,
      'color-dark': _EbayColorDarkSvg,
      light: _EbayLightSvg,
      dark: _EbayDarkSvg,
    } as LogoVariant,
  },
  economist: {
    height: 121,
    width: 240,
    balancedScale: 1.5,
    variants: {
      'color-light': _EconomistColorLightSvg,
      'color-dark': _EconomistColorDarkSvg,
      light: _EconomistLightSvg,
      dark: _EconomistDarkSvg,
    } as LogoVariant,
  },
  elastic: {
    height: 93,
    width: 270,
    balancedScale: 1.2,
    variants: {
      'color-light': _ElasticColorLightSvg,
      light: _ElasticLightSvg,
      'color-dark': _ElasticColorDarkSvg,
      dark: _ElasticDarkSvg,
    } as LogoVariant,
  },
  elevenlabs: {
    height: 45,
    width: 321,
    balancedScale: 0.6,
    variants: {
      light: _ElevenlabsLightSvg,
      dark: _ElevenlabsDarkSvg,
    } as LogoVariant,
  },
  elkjop: {
    height: 109,
    width: 300,
    balancedScale: 1.4,
    variants: {
      'color-light': _ElkjopColorLightSvg,
      'color-dark': _ElkjopColorDarkSvg,
      light: _ElkjopLightSvg,
      dark: _ElkjopDarkSvg,
    } as LogoVariant,
  },
  ember: {
    height: 93,
    width: 240,
    balancedScale: 1.2,
    variants: {
      'color-light': _EmberColorLightSvg,
      'color-dark': _EmberColorDarkSvg,
      light: _EmberLightSvg,
      dark: _EmberDarkSvg,
    } as LogoVariant,
  },
  'encompass-health': {
    height: 86,
    width: 320,
    balancedScale: 1.1,
    variants: {
      'color-light': _EncompassHealthColorLightSvg,
      'color-dark': _EncompassHealthColorDarkSvg,
      light: _EncompassHealthLightSvg,
      dark: _EncompassHealthDarkSvg,
    } as LogoVariant,
  },
  endear: {
    height: 47,
    width: 240,
    balancedScale: 0.6,
    variants: {
      'color-light': _EndearColorLightSvg,
      'color-dark': _EndearColorDarkSvg,
      light: _EndearLightSvg,
      dark: _EndearDarkSvg,
    } as LogoVariant,
  },
  enso: {
    height: 109,
    width: 220,
    balancedScale: 1.4,
    variants: {
      'color-light': _EnsoColorLightSvg,
      light: _EnsoLightSvg,
      dark: _EnsoDarkSvg,
    } as LogoVariant,
  },
  'erie-insurance': {
    height: 126,
    width: 320,
    balancedScale: 1.6,
    variants: {
      'color-light': _ErieInsuranceColorLightSvg,
      'color-dark': _ErieInsuranceColorDarkSvg,
      light: _ErieInsuranceLightSvg,
      dark: _ErieInsuranceDarkSvg,
    } as LogoVariant,
  },
  eventbrite: {
    height: 58,
    width: 320,
    balancedScale: 0.7,
    variants: {
      'color-light': _EventbriteColorLightSvg,
      'color-dark': _EventbriteColorDarkSvg,
      light: _EventbriteLightSvg,
      dark: _EventbriteDarkSvg,
    } as LogoVariant,
  },
  'extra-space-storage': {
    height: 109,
    width: 240,
    balancedScale: 1.4,
    variants: {
      'color-light': _ExtraSpaceStorageColorLightSvg,
      'color-dark': _ExtraSpaceStorageColorDarkSvg,
      light: _ExtraSpaceStorageLightSvg,
      dark: _ExtraSpaceStorageDarkSvg,
    } as LogoVariant,
  },
  fauna: {
    height: 98,
    width: 290,
    balancedScale: 1.2,
    variants: {
      light: _FaunaLightSvg,
      dark: _FaunaDarkSvg,
    } as LogoVariant,
  },
  fey: {
    height: 89,
    width: 220,
    balancedScale: 1.1,
    variants: {
      light: _FeyLightSvg,
      dark: _FeyDarkSvg,
    } as LogoVariant,
  },
  ffw: {
    height: 121,
    width: 240,
    balancedScale: 1.5,
    variants: {
      'color-light': _FfwColorLightSvg,
      light: _FfwLightSvg,
      'color-dark': _FfwColorDarkSvg,
      dark: _FfwDarkSvg,
    } as LogoVariant,
  },
  finary: {
    height: 71,
    width: 260,
    balancedScale: 0.9,
    variants: {
      'color-light': _FinaryColorLightSvg,
      'color-dark': _FinaryColorDarkSvg,
      light: _FinaryLightSvg,
      dark: _FinaryDarkSvg,
    } as LogoVariant,
  },
  'finsbury-glover-hering': {
    height: 73,
    width: 300,
    balancedScale: 0.9,
    variants: {
      light: _FinsburyGloverHeringLightSvg,
      dark: _FinsburyGloverHeringDarkSvg,
    } as LogoVariant,
  },
  foundation: {
    height: 68,
    width: 200,
    balancedScale: 0.9,
    variants: {
      light: _FoundationLightSvg,
      dark: _FoundationDarkSvg,
    } as LogoVariant,
  },
  framer: {
    height: 92,
    width: 280,
    balancedScale: 1.2,
    variants: {
      light: _FramerLightSvg,
      dark: _FramerDarkSvg,
    } as LogoVariant,
  },
  ftd: {
    height: 135,
    width: 160,
    balancedScale: 1.7,
    variants: {
      light: _FtdLightSvg,
      dark: _FtdDarkSvg,
    } as LogoVariant,
  },
  fueled: {
    height: 160,
    width: 160,
    balancedScale: 2,
    variants: {
      'color-light': _FueledColorLightSvg,
      'color-dark': _FueledColorDarkSvg,
      light: _FueledLightSvg,
      dark: _FueledDarkSvg,
    } as LogoVariant,
  },
  'gatsby-logo': {
    height: 140,
    width: 140,
    balancedScale: 1.8,
    variants: {
      'color-light': _GatsbyLogoColorLightSvg,
      'color-dark': _GatsbyLogoColorDarkSvg,
      light: _GatsbyLogoLightSvg,
      dark: _GatsbyLogoDarkSvg,
    } as LogoVariant,
  },
  'gatsby-logotype': {
    height: 77,
    width: 291,
    balancedScale: 1,
    variants: {
      'color-light': _GatsbyLogotypeColorLightSvg,
      'color-dark': _GatsbyLogotypeColorDarkSvg,
      light: _GatsbyLogotypeLightSvg,
      dark: _GatsbyLogotypeDarkSvg,
    } as LogoVariant,
  },
  gearbox: {
    height: 93,
    width: 280,
    balancedScale: 1.2,
    variants: {
      light: _GearboxLightSvg,
      dark: _GearboxDarkSvg,
    } as LogoVariant,
  },
  'geodesic-capital': {
    height: 140,
    width: 140,
    balancedScale: 1.8,
    variants: {
      light: _GeodesicCapitalLightSvg,
      dark: _GeodesicCapitalDarkSvg,
    } as LogoVariant,
  },
  'ggv-capital': {
    height: 45,
    width: 400,
    balancedScale: 0.6,
    variants: {
      light: _GgvCapitalLightSvg,
      dark: _GgvCapitalDarkSvg,
    } as LogoVariant,
  },
  ghanda: {
    height: 98,
    width: 260,
    balancedScale: 1.2,
    variants: {
      light: _GhandaLightSvg,
      dark: _GhandaDarkSvg,
    } as LogoVariant,
  },
  'github-logo': {
    height: 140,
    width: 140,
    balancedScale: 1.8,
    variants: {
      'color-light': _GithubLogoColorLightSvg,
      light: _GithubLogoLightSvg,
      dark: _GithubLogoDarkSvg,
    } as LogoVariant,
  },
  'github-logotype': {
    height: 68,
    width: 240,
    balancedScale: 0.9,
    variants: {
      'color-light': _GithubLogotypeColorLightSvg,
      light: _GithubLogotypeLightSvg,
      dark: _GithubLogotypeDarkSvg,
      'color-dark': _GithubLogotypeColorDarkSvg,
    } as LogoVariant,
  },
  'glide-publishing-platform': {
    height: 107,
    width: 320,
    balancedScale: 1.3,
    variants: {
      'color-light': _GlidePublishingPlatformColorLightSvg,
      light: _GlidePublishingPlatformLightSvg,
      'color-dark': _GlidePublishingPlatformColorDarkSvg,
      dark: _GlidePublishingPlatformDarkSvg,
    } as LogoVariant,
  },
  globacore: {
    height: 74,
    width: 340,
    balancedScale: 0.9,
    variants: {
      light: _GlobacoreLightSvg,
      dark: _GlobacoreDarkSvg,
    } as LogoVariant,
  },
  'global-retail-brands-australia': {
    height: 109,
    width: 260,
    balancedScale: 1.4,
    variants: {
      'color-light': _GlobalRetailBrandsAustraliaColorLightSvg,
      'color-dark': _GlobalRetailBrandsAustraliaColorDarkSvg,
      light: _GlobalRetailBrandsAustraliaLightSvg,
      dark: _GlobalRetailBrandsAustraliaDarkSvg,
    } as LogoVariant,
  },
  gnc: {
    height: 52,
    width: 280,
    balancedScale: 0.7,
    variants: {
      'color-light': _GncColorLightSvg,
      'color-dark': _GncColorDarkSvg,
      light: _GncLightSvg,
      dark: _GncDarkSvg,
    } as LogoVariant,
  },
  'gohealth-urgent-care': {
    height: 94,
    width: 320,
    balancedScale: 1.2,
    variants: {
      'color-light': _GohealthUrgentCareColorLightSvg,
      'color-dark': _GohealthUrgentCareColorDarkSvg,
      light: _GohealthUrgentCareLightSvg,
      dark: _GohealthUrgentCareDarkSvg,
    } as LogoVariant,
  },
  'google-cloud-platform': {
    height: 57,
    width: 360,
    balancedScale: 0.7,
    variants: {
      'color-light': _GoogleCloudPlatformColorLightSvg,
      'color-dark': _GoogleCloudPlatformColorDarkSvg,
      light: _GoogleCloudPlatformLightSvg,
      dark: _GoogleCloudPlatformDarkSvg,
    } as LogoVariant,
  },
  'google-ventures': {
    height: 95,
    width: 150,
    balancedScale: 1.2,
    variants: {
      light: _GoogleVenturesLightSvg,
      dark: _GoogleVenturesDarkSvg,
    } as LogoVariant,
  },
  'graph-cms': {
    height: 74,
    width: 320,
    balancedScale: 0.9,
    variants: {
      light: _GraphCmsLightSvg,
      dark: _GraphCmsDarkSvg,
    } as LogoVariant,
  },
  greenoaks: {
    height: 42,
    width: 360,
    balancedScale: 0.5,
    variants: {
      light: _GreenoaksLightSvg,
      dark: _GreenoaksDarkSvg,
    } as LogoVariant,
  },
  'gridsome-logo': {
    height: 140,
    width: 140,
    balancedScale: 1.8,
    variants: {
      'color-light': _GridsomeLogoColorLightSvg,
      'color-dark': _GridsomeLogoColorDarkSvg,
      light: _GridsomeLogoLightSvg,
      dark: _GridsomeLogoDarkSvg,
    } as LogoVariant,
  },
  'gridsome-logotype': {
    height: 57,
    width: 300,
    balancedScale: 0.7,
    variants: {
      'color-light': _GridsomeLogotypeColorLightSvg,
      'color-dark': _GridsomeLogotypeColorDarkSvg,
      light: _GridsomeLogotypeLightSvg,
      dark: _GridsomeLogotypeDarkSvg,
    } as LogoVariant,
  },
  groq: {
    height: 58,
    width: 159,
    balancedScale: 0.7,
    variants: {
      light: _GroqLightSvg,
      dark: _GroqDarkSvg,
    } as LogoVariant,
  },
  guinness: {
    height: 62,
    width: 280,
    balancedScale: 0.8,
    variants: {
      'color-light': _GuinnessColorLightSvg,
      'color-dark': _GuinnessColorDarkSvg,
      light: _GuinnessLightSvg,
      dark: _GuinnessDarkSvg,
    } as LogoVariant,
  },
  'harry-rosen': {
    height: 46,
    width: 400,
    balancedScale: 0.6,
    variants: {
      light: _HarryRosenLightSvg,
      dark: _HarryRosenDarkSvg,
    } as LogoVariant,
  },
  'hashi-corp': {
    height: 73,
    width: 310,
    balancedScale: 0.9,
    variants: {
      light: _HashiCorpLightSvg,
      dark: _HashiCorpDarkSvg,
    } as LogoVariant,
  },
  hashnode: {
    height: 59,
    width: 350,
    balancedScale: 0.7,
    variants: {
      'color-light': _HashnodeColorLightSvg,
      light: _HashnodeLightSvg,
      'color-dark': _HashnodeColorDarkSvg,
      dark: _HashnodeDarkSvg,
    } as LogoVariant,
  },
  hatch: {
    height: 48,
    width: 300,
    balancedScale: 0.6,
    variants: {
      'color-light': _HatchColorLightSvg,
      'color-dark': _HatchColorDarkSvg,
      light: _HatchLightSvg,
      dark: _HatchDarkSvg,
    } as LogoVariant,
  },
  'hello-alice': {
    height: 72,
    width: 330,
    balancedScale: 0.9,
    variants: {
      'color-light': _HelloAliceColorLightSvg,
      'color-dark': _HelloAliceColorDarkSvg,
      light: _HelloAliceLightSvg,
      dark: _HelloAliceDarkSvg,
    } as LogoVariant,
  },
  'helly-hansen-logomark': {
    height: 96,
    width: 180,
    balancedScale: 1.2,
    variants: {
      light: _HellyHansenLogomarkLightSvg,
      dark: _HellyHansenLogomarkDarkSvg,
    } as LogoVariant,
  },
  'helly-hansen-logotype': {
    height: 140,
    width: 240,
    balancedScale: 1.8,
    variants: {
      light: _HellyHansenLogotypeLightSvg,
      dark: _HellyHansenLogotypeDarkSvg,
    } as LogoVariant,
  },
  'herman-miller': {
    height: 93,
    width: 360,
    balancedScale: 1.2,
    variants: {
      'color-light': _HermanMillerColorLightSvg,
      'color-dark': _HermanMillerColorDarkSvg,
      light: _HermanMillerLightSvg,
      dark: _HermanMillerDarkSvg,
    } as LogoVariant,
  },
  'hewlett-packard-enterprise': {
    height: 117,
    width: 300,
    balancedScale: 1.5,
    variants: {
      'color-light': _HewlettPackardEnterpriseColorLightSvg,
      'color-dark': _HewlettPackardEnterpriseColorDarkSvg,
      light: _HewlettPackardEnterpriseLightSvg,
      dark: _HewlettPackardEnterpriseDarkSvg,
    } as LogoVariant,
  },
  hexo: {
    height: 130,
    width: 113,
    balancedScale: 1.6,
    variants: {
      'color-light': _HexoColorLightSvg,
      'color-dark': _HexoColorDarkSvg,
      light: _HexoLightSvg,
      dark: _HexoDarkSvg,
    } as LogoVariant,
  },
  'hola-com': {
    height: 98,
    width: 240,
    balancedScale: 1.2,
    variants: {
      'color-light': _HolaComColorLightSvg,
      'color-dark': _HolaComColorDarkSvg,
      light: _HolaComLightSvg,
      dark: _HolaComDarkSvg,
    } as LogoVariant,
  },
  horizontal: {
    height: 102,
    width: 200,
    balancedScale: 1.3,
    variants: {
      light: _HorizontalLightSvg,
      dark: _HorizontalDarkSvg,
    } as LogoVariant,
  },
  hub: {
    height: 75,
    width: 240,
    balancedScale: 0.9,
    variants: {
      'color-light': _HubColorLightSvg,
      'color-dark': _HubColorDarkSvg,
      light: _HubLightSvg,
      dark: _HubDarkSvg,
    } as LogoVariant,
  },
  huge: {
    height: 144,
    width: 148,
    balancedScale: 1.8,
    variants: {
      light: _HugeLightSvg,
      dark: _HugeDarkSvg,
    } as LogoVariant,
  },
  'hugging-face': {
    height: 71,
    width: 321,
    balancedScale: 0.9,
    variants: {
      'color-light': _HuggingFaceColorLightSvg,
      'color-dark': _HuggingFaceColorDarkSvg,
      light: _HuggingFaceLightSvg,
      dark: _HuggingFaceDarkSvg,
    } as LogoVariant,
  },
  'hugo-logo': {
    height: 140,
    width: 125,
    balancedScale: 1.8,
    variants: {
      'color-light': _HugoLogoColorLightSvg,
      'color-dark': _HugoLogoColorDarkSvg,
      light: _HugoLogoLightSvg,
      dark: _HugoLogoDarkSvg,
    } as LogoVariant,
  },
  'hugo-logotype': {
    height: 79,
    width: 300,
    balancedScale: 1,
    variants: {
      'color-light': _HugoLogotypeColorLightSvg,
      'color-dark': _HugoLogotypeColorDarkSvg,
      light: _HugoLogotypeLightSvg,
      dark: _HugoLogotypeDarkSvg,
    } as LogoVariant,
  },
  hume: {
    height: 64,
    width: 280,
    balancedScale: 0.8,
    variants: {
      'color-light': _HumeColorLightSvg,
      'color-dark': _HumeColorDarkSvg,
      light: _HumeLightSvg,
      dark: _HumeDarkSvg,
    } as LogoVariant,
  },
  'hydrogen-logo': {
    height: 120,
    width: 114,
    balancedScale: 1.5,
    variants: {
      'color-light': _HydrogenLogoColorLightSvg,
      'color-dark': _HydrogenLogoColorDarkSvg,
      light: _HydrogenLogoLightSvg,
      dark: _HydrogenLogoDarkSvg,
    } as LogoVariant,
  },
  'hydrogen-logotype': {
    height: 122,
    width: 260,
    balancedScale: 1.5,
    variants: {
      'color-light': _HydrogenLogotypeColorLightSvg,
      'color-dark': _HydrogenLogotypeColorDarkSvg,
      light: _HydrogenLogotypeLightSvg,
      dark: _HydrogenLogotypeDarkSvg,
    } as LogoVariant,
  },
  hydrow: {
    height: 69,
    width: 279,
    balancedScale: 0.9,
    variants: {
      'color-light': _HydrowColorLightSvg,
      light: _HydrowLightSvg,
      dark: _HydrowDarkSvg,
    } as LogoVariant,
  },
  hygraph: {
    height: 88,
    width: 320,
    balancedScale: 1.1,
    variants: {
      light: _HygraphLightSvg,
      dark: _HygraphDarkSvg,
    } as LogoVariant,
  },
  icbc: {
    height: 148,
    width: 148,
    balancedScale: 1.9,
    variants: {
      'color-light': _IcbcColorLightSvg,
      'color-dark': _IcbcColorDarkSvg,
      light: _IcbcLightSvg,
      dark: _IcbcDarkSvg,
    } as LogoVariant,
  },
  iga: {
    height: 116,
    width: 180,
    balancedScale: 1.5,
    variants: {
      'color-light': _IgaColorLightSvg,
      'color-dark': _IgaColorDarkSvg,
      light: _IgaLightSvg,
      dark: _IgaDarkSvg,
    } as LogoVariant,
  },
  ilia: {
    height: 71,
    width: 197,
    balancedScale: 0.9,
    variants: {
      light: _IliaLightSvg,
      dark: _IliaDarkSvg,
    } as LogoVariant,
  },
  imdb: {
    height: 72,
    width: 176,
    balancedScale: 0.9,
    variants: {
      light: _ImdbLightSvg,
      dark: _ImdbDarkSvg,
    } as LogoVariant,
  },
  'incident-io': {
    height: 70,
    width: 280,
    balancedScale: 0.9,
    variants: {
      'color-light': _IncidentIoColorLightSvg,
      light: _IncidentIoLightSvg,
      'color-dark': _IncidentIoColorDarkSvg,
      dark: _IncidentIoDarkSvg,
    } as LogoVariant,
  },
  indent: {
    height: 55,
    width: 280,
    balancedScale: 0.7,
    variants: {
      'color-light': _IndentColorLightSvg,
      light: _IndentLightSvg,
      'color-dark': _IndentColorDarkSvg,
      dark: _IndentDarkSvg,
    } as LogoVariant,
  },
  instatus: {
    height: 68,
    width: 296,
    balancedScale: 0.9,
    variants: {
      light: _InstatusLightSvg,
      dark: _InstatusDarkSvg,
    } as LogoVariant,
  },
  'integral-studio': {
    height: 98,
    width: 260,
    balancedScale: 1.2,
    variants: {
      'color-light': _IntegralStudioColorLightSvg,
      'color-dark': _IntegralStudioColorDarkSvg,
      light: _IntegralStudioLightSvg,
      dark: _IntegralStudioDarkSvg,
    } as LogoVariant,
  },
  iqvia: {
    height: 52,
    width: 289,
    balancedScale: 0.7,
    variants: {
      'color-light': _IqviaColorLightSvg,
      'color-dark': _IqviaColorDarkSvg,
      light: _IqviaLightSvg,
      dark: _IqviaDarkSvg,
    } as LogoVariant,
  },
  'iron-mountain': {
    height: 78,
    width: 300,
    balancedScale: 1,
    variants: {
      'color-light': _IronMountainColorLightSvg,
      'color-dark': _IronMountainColorDarkSvg,
      light: _IronMountainLightSvg,
      dark: _IronMountainDarkSvg,
    } as LogoVariant,
  },
  iss: {
    height: 140,
    width: 160,
    balancedScale: 1.8,
    variants: {
      light: _IssLightSvg,
      dark: _IssDarkSvg,
    } as LogoVariant,
  },
  jagex: {
    height: 78,
    width: 256,
    balancedScale: 1,
    variants: {
      light: _JagexLightSvg,
      dark: _JagexDarkSvg,
    } as LogoVariant,
  },
  jasper: {
    height: 79,
    width: 340,
    balancedScale: 1,
    variants: {
      'color-light': _JasperColorLightSvg,
      light: _JasperLightSvg,
      'color-dark': _JasperColorDarkSvg,
      dark: _JasperDarkSvg,
    } as LogoVariant,
  },
  'jean-paul-gaultier': {
    height: 94,
    width: 256,
    balancedScale: 1.2,
    variants: {
      light: _JeanPaulGaultierLightSvg,
      dark: _JeanPaulGaultierDarkSvg,
    } as LogoVariant,
  },
  jekyll: {
    height: 200,
    width: 112,
    balancedScale: 2.5,
    variants: {
      'color-light': _JekyllColorLightSvg,
      'color-dark': _JekyllColorDarkSvg,
      light: _JekyllLightSvg,
      dark: _JekyllDarkSvg,
    } as LogoVariant,
  },
  'jennifer-fisher': {
    height: 29,
    width: 420,
    balancedScale: 0.4,
    variants: {
      light: _JenniferFisherLightSvg,
      dark: _JenniferFisherDarkSvg,
    } as LogoVariant,
  },
  jisc: {
    height: 180,
    width: 180,
    balancedScale: 2.3,
    variants: {
      'color-light': _JiscColorLightSvg,
      'color-dark': _JiscColorDarkSvg,
      light: _JiscLightSvg,
      dark: _JiscDarkSvg,
    } as LogoVariant,
  },
  'johnson-johnson': {
    height: 69,
    width: 380,
    balancedScale: 0.9,
    variants: {
      'color-light': _JohnsonJohnsonColorLightSvg,
      'color-dark': _JohnsonJohnsonColorDarkSvg,
      light: _JohnsonJohnsonLightSvg,
      dark: _JohnsonJohnsonDarkSvg,
    } as LogoVariant,
  },
  joyn: {
    height: 75,
    width: 180,
    balancedScale: 0.9,
    variants: {
      light: _JoynLightSvg,
      dark: _JoynDarkSvg,
    } as LogoVariant,
  },
  justincase: {
    height: 73,
    width: 301,
    balancedScale: 0.9,
    variants: {
      light: _JustincaseLightSvg,
      dark: _JustincaseDarkSvg,
    } as LogoVariant,
  },
  keystone: {
    height: 56,
    width: 280,
    balancedScale: 0.7,
    variants: {
      'color-light': _KeystoneColorLightSvg,
      'color-dark': _KeystoneColorDarkSvg,
      light: _KeystoneLightSvg,
      dark: _KeystoneDarkSvg,
    } as LogoVariant,
  },
  'kidsuper-studios': {
    height: 128,
    width: 321,
    balancedScale: 1.6,
    variants: {
      light: _KidsuperStudiosLightSvg,
      dark: _KidsuperStudiosDarkSvg,
    } as LogoVariant,
  },
  konabos: {
    height: 64,
    width: 380,
    balancedScale: 0.8,
    variants: {
      'color-light': _KonabosColorLightSvg,
      'color-dark': _KonabosColorDarkSvg,
      light: _KonabosLightSvg,
      dark: _KonabosDarkSvg,
    } as LogoVariant,
  },
  knock: {
    height: 62,
    width: 230,
    balancedScale: 0.8,
    variants: {
      'color-light': _KnockColorLightSvg,
      light: _KnockLightSvg,
      dark: _KnockDarkSvg,
    } as LogoVariant,
  },
  kraken: {
    height: 78,
    width: 340,
    balancedScale: 1,
    variants: {
      'color-light': _KrakenColorLightSvg,
      'color-dark': _KrakenColorDarkSvg,
      light: _KrakenLightSvg,
      dark: _KrakenDarkSvg,
    } as LogoVariant,
  },
  kvika: {
    height: 94,
    width: 240,
    balancedScale: 1.2,
    variants: {
      light: _KvikaLightSvg,
      dark: _KvikaDarkSvg,
    } as LogoVariant,
  },
  langchain: {
    height: 63,
    width: 301,
    balancedScale: 0.8,
    variants: {
      light: _LangchainLightSvg,
      dark: _LangchainDarkSvg,
    } as LogoVariant,
  },
  'laurentian-bank': {
    height: 98,
    width: 300,
    balancedScale: 1.2,
    variants: {
      light: _LaurentianBankLightSvg,
      dark: _LaurentianBankDarkSvg,
    } as LogoVariant,
  },
  'leonardo-ai': {
    height: 60,
    width: 360,
    balancedScale: 0.8,
    variants: {
      'color-light': _LeonardoAiColorLightSvg,
      'color-dark': _LeonardoAiColorDarkSvg,
      light: _LeonardoAiLightSvg,
      dark: _LeonardoAiDarkSvg,
    } as LogoVariant,
  },
  lexica: {
    height: 69,
    width: 240,
    balancedScale: 0.9,
    variants: {
      light: _LexicaLightSvg,
      dark: _LexicaDarkSvg,
    } as LogoVariant,
  },
  linktree: {
    height: 57,
    width: 300,
    balancedScale: 0.7,
    variants: {
      'color-light': _LinktreeColorLightSvg,
      light: _LinktreeLightSvg,
      'color-dark': _LinktreeColorDarkSvg,
      dark: _LinktreeDarkSvg,
    } as LogoVariant,
  },
  'live-nation': {
    height: 65,
    width: 300,
    balancedScale: 0.8,
    variants: {
      'color-light': _LiveNationColorLightSvg,
      'color-dark': _LiveNationColorDarkSvg,
      light: _LiveNationLightSvg,
      dark: _LiveNationDarkSvg,
    } as LogoVariant,
  },
  liveblocks: {
    height: 47,
    width: 360,
    balancedScale: 0.6,
    variants: {
      'color-light': _LiveblocksColorLightSvg,
      light: _LiveblocksLightSvg,
      'color-dark': _LiveblocksColorDarkSvg,
      dark: _LiveblocksDarkSvg,
    } as LogoVariant,
  },
  'l-officiel': {
    height: 68,
    width: 296,
    balancedScale: 0.9,
    variants: {
      light: _LOfficielLightSvg,
      dark: _LOfficielDarkSvg,
    } as LogoVariant,
  },
  loom: {
    height: 80,
    width: 264,
    balancedScale: 1,
    variants: {
      'color-light': _LoomColorLightSvg,
      light: _LoomLightSvg,
      'color-dark': _LoomColorDarkSvg,
      dark: _LoomDarkSvg,
    } as LogoVariant,
  },
  lovevery: {
    height: 76,
    width: 310,
    balancedScale: 1,
    variants: {
      'color-light': _LoveveryColorLightSvg,
      'color-dark': _LoveveryColorDarkSvg,
      light: _LoveveryLightSvg,
      dark: _LoveveryDarkSvg,
    } as LogoVariant,
  },
  'magda-butrym': {
    height: 33,
    width: 400,
    balancedScale: 0.4,
    variants: {
      light: _MagdaButrymLightSvg,
      dark: _MagdaButrymDarkSvg,
    } as LogoVariant,
  },
  'magic-school': {
    height: 98,
    width: 220,
    balancedScale: 1.2,
    variants: {
      'color-light': _MagicSchoolColorLightSvg,
      'color-dark': _MagicSchoolColorDarkSvg,
      light: _MagicSchoolLightSvg,
      dark: _MagicSchoolDarkSvg,
    } as LogoVariant,
  },
  'make-space': {
    height: 62,
    width: 321,
    balancedScale: 0.8,
    variants: {
      light: _MakeSpaceLightSvg,
      dark: _MakeSpaceDarkSvg,
    } as LogoVariant,
  },
  mammut: {
    height: 37,
    width: 340,
    balancedScale: 0.5,
    variants: {
      light: _MammutLightSvg,
      dark: _MammutDarkSvg,
    } as LogoVariant,
  },
  'master-class': {
    height: 48,
    width: 360,
    balancedScale: 0.6,
    variants: {
      'color-light': _MasterClassColorLightSvg,
      'color-dark': _MasterClassColorDarkSvg,
      light: _MasterClassLightSvg,
      dark: _MasterClassDarkSvg,
    } as LogoVariant,
  },
  matterport: {
    height: 62,
    width: 361,
    balancedScale: 0.8,
    variants: {
      'color-light': _MatterportColorLightSvg,
      light: _MatterportLightSvg,
      'color-dark': _MatterportColorDarkSvg,
      dark: _MatterportDarkSvg,
    } as LogoVariant,
  },
  'maven-agi': {
    height: 68,
    width: 300,
    balancedScale: 0.9,
    variants: {
      'color-light': _MavenAgiColorLightSvg,
      'color-dark': _MavenAgiColorDarkSvg,
      light: _MavenAgiLightSvg,
      dark: _MavenAgiDarkSvg,
    } as LogoVariant,
  },
  'mc-donald-s': {
    height: 109,
    width: 129,
    balancedScale: 1.4,
    variants: {
      'color-light': _McDonaldSColorLightSvg,
      'color-dark': _McDonaldSColorDarkSvg,
      light: _McDonaldSLightSvg,
      dark: _McDonaldSDarkSvg,
    } as LogoVariant,
  },
  medusa: {
    height: 58,
    width: 260,
    balancedScale: 0.7,
    variants: {
      'color-light': _MedusaColorLightSvg,
      'color-dark': _MedusaColorDarkSvg,
      light: _MedusaLightSvg,
      dark: _MedusaDarkSvg,
    } as LogoVariant,
  },
  meta: {
    height: 57,
    width: 280,
    balancedScale: 0.7,
    variants: {
      'color-light': _MetaColorLightSvg,
      light: _MetaLightSvg,
      'color-dark': _MetaColorDarkSvg,
      dark: _MetaDarkSvg,
    } as LogoVariant,
  },
  metagenics: {
    height: 47,
    width: 320,
    balancedScale: 0.6,
    variants: {
      'color-light': _MetagenicsColorLightSvg,
      'color-dark': _MetagenicsColorDarkSvg,
      light: _MetagenicsLightSvg,
      dark: _MetagenicsDarkSvg,
    } as LogoVariant,
  },
  metcash: {
    height: 63,
    width: 300,
    balancedScale: 0.8,
    variants: {
      'color-light': _MetcashColorLightSvg,
      'color-dark': _MetcashColorDarkSvg,
      light: _MetcashLightSvg,
      dark: _MetcashDarkSvg,
    } as LogoVariant,
  },
  metrolinx: {
    height: 100,
    width: 229,
    balancedScale: 1.3,
    variants: {
      'color-light': _MetrolinxColorLightSvg,
      'color-dark': _MetrolinxColorDarkSvg,
      light: _MetrolinxLightSvg,
      dark: _MetrolinxDarkSvg,
    } as LogoVariant,
  },
  middleman: {
    height: 119,
    width: 140,
    balancedScale: 1.5,
    variants: {
      light: _MiddlemanLightSvg,
      dark: _MiddlemanDarkSvg,
    } as LogoVariant,
  },
  mintlify: {
    height: 56,
    width: 258,
    balancedScale: 0.7,
    variants: {
      'color-light': _MintlifyColorLightSvg,
      light: _MintlifyLightSvg,
      'color-dark': _MintlifyColorDarkSvg,
      dark: _MintlifyDarkSvg,
    } as LogoVariant,
  },
  mirror: {
    height: 88,
    width: 280,
    balancedScale: 1.1,
    variants: {
      'color-light': _MirrorColorLightSvg,
      light: _MirrorLightSvg,
      'color-dark': _MirrorColorDarkSvg,
      dark: _MirrorDarkSvg,
    } as LogoVariant,
  },
  'mitsue-links': {
    height: 77,
    width: 466,
    balancedScale: 1,
    variants: {
      'color-light': _MitsueLinksColorLightSvg,
      'color-dark': _MitsueLinksColorDarkSvg,
      light: _MitsueLinksLightSvg,
      dark: _MitsueLinksDarkSvg,
    } as LogoVariant,
  },
  modal: {
    height: 61,
    width: 280,
    balancedScale: 0.8,
    variants: {
      'color-light': _ModalColorLightSvg,
      'color-dark': _ModalColorDarkSvg,
      light: _ModalLightSvg,
      dark: _ModalDarkSvg,
    } as LogoVariant,
  },
  'modern-treasury': {
    height: 157,
    width: 210,
    balancedScale: 2,
    variants: {
      'color-light': _ModernTreasuryColorLightSvg,
      'color-dark': _ModernTreasuryColorDarkSvg,
      light: _ModernTreasuryLightSvg,
      dark: _ModernTreasuryDarkSvg,
    } as LogoVariant,
  },
  'mongo-db': {
    height: 81,
    width: 320,
    balancedScale: 1,
    variants: {
      'color-light': _MongoDbColorLightSvg,
      light: _MongoDbLightSvg,
      'color-dark': _MongoDbColorDarkSvg,
      dark: _MongoDbDarkSvg,
    } as LogoVariant,
  },
  monogram: {
    height: 39,
    width: 381,
    balancedScale: 0.5,
    variants: {
      light: _MonogramLightSvg,
      dark: _MonogramDarkSvg,
    } as LogoVariant,
  },
  moo: {
    height: 66,
    width: 200,
    balancedScale: 0.8,
    variants: {
      light: _MooLightSvg,
      dark: _MooDarkSvg,
    } as LogoVariant,
  },
  'morning-brew': {
    height: 63,
    width: 380,
    balancedScale: 0.8,
    variants: {
      'color-light': _MorningBrewColorLightSvg,
      'color-dark': _MorningBrewColorDarkSvg,
      light: _MorningBrewLightSvg,
      dark: _MorningBrewDarkSvg,
    } as LogoVariant,
  },
  'morning-consult': {
    height: 36,
    width: 440,
    balancedScale: 0.5,
    variants: {
      light: _MorningConsultLightSvg,
      dark: _MorningConsultDarkSvg,
    } as LogoVariant,
  },
  motif: {
    height: 87,
    width: 260,
    balancedScale: 1.1,
    variants: {
      light: _MotifLightSvg,
      dark: _MotifDarkSvg,
    } as LogoVariant,
  },
  motortrend: {
    height: 49,
    width: 260,
    balancedScale: 0.6,
    variants: {
      light: _MotortrendLightSvg,
      dark: _MotortrendDarkSvg,
    } as LogoVariant,
  },
  'mr-beast': {
    height: 49,
    width: 241,
    balancedScale: 0.6,
    variants: {
      light: _MrBeastLightSvg,
      dark: _MrBeastDarkSvg,
    } as LogoVariant,
  },
  mux: {
    height: 69,
    width: 221,
    balancedScale: 0.9,
    variants: {
      light: _MuxLightSvg,
      dark: _MuxDarkSvg,
    } as LogoVariant,
  },
  'my-muscle-chef': {
    height: 103,
    width: 200,
    balancedScale: 1.3,
    variants: {
      light: _MyMuscleChefLightSvg,
      dark: _MyMuscleChefDarkSvg,
    } as LogoVariant,
  },
  nacelle: {
    height: 57,
    width: 340,
    balancedScale: 0.7,
    variants: {
      light: _NacelleLightSvg,
      dark: _NacelleDarkSvg,
    } as LogoVariant,
  },
  'national-marrow-donor-program': {
    height: 84,
    width: 260,
    balancedScale: 1.1,
    variants: {
      'color-light': _NationalMarrowDonorProgramColorLightSvg,
      'color-dark': _NationalMarrowDonorProgramColorDarkSvg,
      light: _NationalMarrowDonorProgramLightSvg,
      dark: _NationalMarrowDonorProgramDarkSvg,
    } as LogoVariant,
  },
  neo: {
    height: 61,
    width: 200,
    balancedScale: 0.8,
    variants: {
      light: _NeoLightSvg,
      dark: _NeoDarkSvg,
    } as LogoVariant,
  },
  neon: {
    height: 80,
    width: 280,
    balancedScale: 1,
    variants: {
      'color-light': _NeonColorLightSvg,
      'color-dark': _NeonColorDarkSvg,
      light: _NeonLightSvg,
      dark: _NeonDarkSvg,
    } as LogoVariant,
  },
  nerdwallet: {
    height: 49,
    width: 300,
    balancedScale: 0.6,
    variants: {
      'color-light': _NerdwalletColorLightSvg,
      'color-dark': _NerdwalletColorDarkSvg,
      light: _NerdwalletLightSvg,
      dark: _NerdwalletDarkSvg,
    } as LogoVariant,
  },
  netflix: {
    height: 70,
    width: 260,
    balancedScale: 0.9,
    variants: {
      'color-light': _NetflixColorLightSvg,
      'color-dark': _NetflixColorDarkSvg,
      light: _NetflixLightSvg,
      dark: _NetflixDarkSvg,
    } as LogoVariant,
  },
  netlight: {
    height: 71,
    width: 280,
    balancedScale: 0.9,
    variants: {
      'color-light': _NetlightColorLightSvg,
      'color-dark': _NetlightColorDarkSvg,
      light: _NetlightLightSvg,
      dark: _NetlightDarkSvg,
    } as LogoVariant,
  },
  'new-relic': {
    height: 66,
    width: 340,
    balancedScale: 0.8,
    variants: {
      'color-light': _NewRelicColorLightSvg,
      'color-dark': _NewRelicColorDarkSvg,
      light: _NewRelicLightSvg,
      dark: _NewRelicDarkSvg,
    } as LogoVariant,
  },
  nintendo: {
    height: 74,
    width: 300,
    balancedScale: 0.9,
    variants: {
      'color-light': _NintendoColorLightSvg,
      'color-dark': _NintendoColorDarkSvg,
      light: _NintendoLightSvg,
      dark: _NintendoDarkSvg,
    } as LogoVariant,
  },
  'nixon-peabody': {
    height: 133,
    width: 290,
    balancedScale: 1.7,
    variants: {
      'color-light': _NixonPeabodyColorLightSvg,
      'color-dark': _NixonPeabodyColorDarkSvg,
      light: _NixonPeabodyLightSvg,
      dark: _NixonPeabodyDarkSvg,
    } as LogoVariant,
  },
  nomad: {
    height: 60,
    width: 250,
    balancedScale: 0.8,
    variants: {
      light: _NomadLightSvg,
      dark: _NomadDarkSvg,
    } as LogoVariant,
  },
  'notable-capital': {
    height: 127,
    width: 260,
    balancedScale: 1.6,
    variants: {
      'color-light': _NotableCapitalColorLightSvg,
      'color-dark': _NotableCapitalColorDarkSvg,
      light: _NotableCapitalLightSvg,
      dark: _NotableCapitalDarkSvg,
    } as LogoVariant,
  },
  notion: {
    height: 102,
    width: 280,
    balancedScale: 1.3,
    variants: {
      light: _NotionLightSvg,
      dark: _NotionDarkSvg,
    } as LogoVariant,
  },
  'nuxt-logo': {
    height: 80,
    width: 120,
    balancedScale: 1,
    variants: {
      'color-light': _NuxtLogoColorLightSvg,
      'color-dark': _NuxtLogoColorDarkSvg,
      light: _NuxtLogoLightSvg,
      dark: _NuxtLogoDarkSvg,
    } as LogoVariant,
  },
  'nuxt-logotype': {
    height: 65,
    width: 260,
    balancedScale: 0.8,
    variants: {
      'color-light': _NuxtLogotypeColorLightSvg,
      'color-dark': _NuxtLogotypeColorDarkSvg,
      light: _NuxtLogotypeLightSvg,
      dark: _NuxtLogotypeDarkSvg,
    } as LogoVariant,
  },
  nzxt: {
    height: 60,
    width: 230,
    balancedScale: 0.8,
    variants: {
      light: _NzxtLightSvg,
      dark: _NzxtDarkSvg,
    } as LogoVariant,
  },
  'octopus-energy': {
    height: 55,
    width: 400,
    balancedScale: 0.7,
    variants: {
      'color-light': _OctopusEnergyColorLightSvg,
      'color-dark': _OctopusEnergyColorDarkSvg,
      light: _OctopusEnergyLightSvg,
      dark: _OctopusEnergyDarkSvg,
    } as LogoVariant,
  },
  oddle: {
    height: 76,
    width: 280,
    balancedScale: 1,
    variants: {
      'color-light': _OddleColorLightSvg,
      light: _OddleLightSvg,
      dark: _OddleDarkSvg,
    } as LogoVariant,
  },
  officeworks: {
    height: 70,
    width: 340,
    balancedScale: 0.9,
    variants: {
      'color-light': _OfficeworksColorLightSvg,
      'color-dark': _OfficeworksColorDarkSvg,
      light: _OfficeworksLightSvg,
      dark: _OfficeworksDarkSvg,
    } as LogoVariant,
  },
  okta: {
    height: 86,
    width: 260,
    balancedScale: 1.1,
    variants: {
      light: _OktaLightSvg,
      dark: _OktaDarkSvg,
    } as LogoVariant,
  },
  'open-ai': {
    height: 83,
    width: 300,
    balancedScale: 1,
    variants: {
      light: _OpenAiLightSvg,
      dark: _OpenAiDarkSvg,
    } as LogoVariant,
  },
  optimizely: {
    height: 84,
    width: 361,
    balancedScale: 1.1,
    variants: {
      'color-light': _OptimizelyColorLightSvg,
      'color-dark': _OptimizelyColorDarkSvg,
      light: _OptimizelyLightSvg,
      dark: _OptimizelyDarkSvg,
    } as LogoVariant,
  },
  'optimizely-logo': {
    height: 118,
    width: 122,
    balancedScale: 1.5,
    variants: {
      'color-light': _OptimizelyLogoColorLightSvg,
      'color-dark': _OptimizelyLogoColorDarkSvg,
      light: _OptimizelyLogoLightSvg,
      dark: _OptimizelyLogoDarkSvg,
    } as LogoVariant,
  },
  orium: {
    height: 64,
    width: 261,
    balancedScale: 0.8,
    variants: {
      light: _OriumLightSvg,
      dark: _OriumDarkSvg,
    } as LogoVariant,
  },
  outfront: {
    height: 37,
    width: 280,
    balancedScale: 0.5,
    variants: {
      'color-light': _OutfrontColorLightSvg,
      'color-dark': _OutfrontColorDarkSvg,
      light: _OutfrontLightSvg,
      dark: _OutfrontDarkSvg,
    } as LogoVariant,
  },
  outside: {
    height: 51,
    width: 200,
    balancedScale: 0.6,
    variants: {
      'color-light': _OutsideColorLightSvg,
      'color-dark': _OutsideColorDarkSvg,
      light: _OutsideLightSvg,
      dark: _OutsideDarkSvg,
    } as LogoVariant,
  },
  'ovo-energy': {
    height: 114,
    width: 280,
    balancedScale: 1.4,
    variants: {
      'color-light': _OvoEnergyColorLightSvg,
      'color-dark': _OvoEnergyColorDarkSvg,
      light: _OvoEnergyLightSvg,
      dark: _OvoEnergyDarkSvg,
    } as LogoVariant,
  },
  parachute: {
    height: 38,
    width: 410,
    balancedScale: 0.5,
    variants: {
      light: _ParachuteLightSvg,
      dark: _ParachuteDarkSvg,
    } as LogoVariant,
  },
  parcel: {
    height: 58,
    width: 280,
    balancedScale: 0.7,
    variants: {
      'color-light': _ParcelColorLightSvg,
      'color-dark': _ParcelColorDarkSvg,
      light: _ParcelLightSvg,
      dark: _ParcelDarkSvg,
    } as LogoVariant,
  },
  patreon: {
    height: 54,
    width: 320,
    balancedScale: 0.7,
    variants: {
      light: _PatreonLightSvg,
      dark: _PatreonDarkSvg,
    } as LogoVariant,
  },
  perficient: {
    height: 68,
    width: 300,
    balancedScale: 0.9,
    variants: {
      light: _PerficientLightSvg,
      dark: _PerficientDarkSvg,
    } as LogoVariant,
  },
  perplexity: {
    height: 82,
    width: 340,
    balancedScale: 1,
    variants: {
      'color-light': _PerplexityColorLightSvg,
      'color-dark': _PerplexityColorDarkSvg,
      light: _PerplexityLightSvg,
      dark: _PerplexityDarkSvg,
    } as LogoVariant,
  },
  personio: {
    height: 87,
    width: 280,
    balancedScale: 1.1,
    variants: {
      light: _PersonioLightSvg,
      dark: _PersonioDarkSvg,
    } as LogoVariant,
  },
  'psychiatric-times': {
    height: 52,
    width: 380,
    balancedScale: 0.7,
    variants: {
      'color-light': _PsychiatricTimesColorLightSvg,
      'color-dark': _PsychiatricTimesColorDarkSvg,
      light: _PsychiatricTimesLightSvg,
      dark: _PsychiatricTimesDarkSvg,
    } as LogoVariant,
  },
  pika: {
    height: 54,
    width: 161,
    balancedScale: 0.7,
    variants: {
      light: _PikaLightSvg,
      dark: _PikaDarkSvg,
    } as LogoVariant,
  },
  pinecone: {
    height: 62,
    width: 320,
    balancedScale: 0.8,
    variants: {
      light: _PineconeLightSvg,
      dark: _PineconeDarkSvg,
    } as LogoVariant,
  },
  pipe: {
    height: 68,
    width: 220,
    balancedScale: 0.9,
    variants: {
      light: _PipeLightSvg,
      dark: _PipeDarkSvg,
    } as LogoVariant,
  },
  'piston-heads': {
    height: 70,
    width: 380,
    balancedScale: 0.9,
    variants: {
      light: _PistonHeadsLightSvg,
      dark: _PistonHeadsDarkSvg,
    } as LogoVariant,
  },
  'planet-scale': {
    height: 59,
    width: 360,
    balancedScale: 0.7,
    variants: {
      light: _PlanetScaleLightSvg,
      dark: _PlanetScaleDarkSvg,
    } as LogoVariant,
  },
  plex: {
    height: 93,
    width: 200,
    balancedScale: 1.2,
    variants: {
      'color-light': _PlexColorLightSvg,
      light: _PlexLightSvg,
      'color-dark': _PlexColorDarkSvg,
      dark: _PlexDarkSvg,
    } as LogoVariant,
  },
  'pop-sql': {
    height: 59,
    width: 281,
    balancedScale: 0.7,
    variants: {
      'color-light': _PopSqlColorLightSvg,
      'color-dark': _PopSqlColorDarkSvg,
      light: _PopSqlLightSvg,
      dark: _PopSqlDarkSvg,
    } as LogoVariant,
  },
  polymarket: {
    height: 69,
    width: 300,
    balancedScale: 0.9,
    variants: {
      light: _PolymarketLightSvg,
      dark: _PolymarketDarkSvg,
    } as LogoVariant,
  },
  polymer: {
    height: 98,
    width: 140,
    balancedScale: 1.2,
    variants: {
      'color-light': _PolymerColorLightSvg,
      'color-dark': _PolymerColorDarkSvg,
      light: _PolymerLightSvg,
      dark: _PolymerDarkSvg,
    } as LogoVariant,
  },
  'preact-logo': {
    height: 140,
    width: 122,
    balancedScale: 1.8,
    variants: {
      'color-light': _PreactLogoColorLightSvg,
      'color-dark': _PreactLogoColorDarkSvg,
      light: _PreactLogoLightSvg,
      dark: _PreactLogoDarkSvg,
    } as LogoVariant,
  },
  'preact-logotype': {
    height: 84,
    width: 300,
    balancedScale: 1.1,
    variants: {
      'color-light': _PreactLogotypeColorLightSvg,
      'color-dark': _PreactLogotypeColorDarkSvg,
      light: _PreactLogotypeLightSvg,
      dark: _PreactLogotypeDarkSvg,
    } as LogoVariant,
  },
  presbyterian: {
    height: 44,
    width: 380,
    balancedScale: 0.6,
    variants: {
      'color-light': _PresbyterianColorLightSvg,
      'color-dark': _PresbyterianColorDarkSvg,
      light: _PresbyterianLightSvg,
      dark: _PresbyterianDarkSvg,
    } as LogoVariant,
  },
  prisma: {
    height: 84,
    width: 260,
    balancedScale: 1.1,
    variants: {
      'color-light': _PrismaColorLightSvg,
      light: _PrismaLightSvg,
      dark: _PrismaDarkSvg,
    } as LogoVariant,
  },
  prismic: {
    height: 76,
    width: 280,
    balancedScale: 1,
    variants: {
      light: _PrismicLightSvg,
      dark: _PrismicDarkSvg,
    } as LogoVariant,
  },
  raycast: {
    height: 78,
    width: 300,
    balancedScale: 1,
    variants: {
      'color-light': _RaycastColorLightSvg,
      'color-dark': _RaycastColorDarkSvg,
      light: _RaycastLightSvg,
      dark: _RaycastDarkSvg,
    } as LogoVariant,
  },
  react: {
    height: 125,
    width: 140,
    balancedScale: 1.6,
    variants: {
      'color-light': _ReactColorLightSvg,
      'color-dark': _ReactColorDarkSvg,
      light: _ReactLightSvg,
      dark: _ReactDarkSvg,
    } as LogoVariant,
  },
  'read-cv': {
    height: 138,
    width: 120,
    balancedScale: 1.7,
    variants: {
      light: _ReadCvLightSvg,
      dark: _ReadCvDarkSvg,
    } as LogoVariant,
  },
  'redwoodjs-logo': {
    height: 120,
    width: 111,
    balancedScale: 1.5,
    variants: {
      'color-light': _RedwoodjsLogoColorLightSvg,
      'color-dark': _RedwoodjsLogoColorDarkSvg,
      light: _RedwoodjsLogoLightSvg,
      dark: _RedwoodjsLogoDarkSvg,
    } as LogoVariant,
  },
  'redwoodjs-logotype': {
    height: 66,
    width: 360,
    balancedScale: 0.8,
    variants: {
      'color-light': _RedwoodjsLogotypeColorLightSvg,
      'color-dark': _RedwoodjsLogotypeColorDarkSvg,
      light: _RedwoodjsLogotypeLightSvg,
      dark: _RedwoodjsLogotypeDarkSvg,
    } as LogoVariant,
  },
  'renown-health': {
    height: 75,
    width: 280,
    balancedScale: 0.9,
    variants: {
      'color-light': _RenownHealthColorLightSvg,
      'color-dark': _RenownHealthColorDarkSvg,
      light: _RenownHealthLightSvg,
      dark: _RenownHealthDarkSvg,
    } as LogoVariant,
  },
  're-markable': {
    height: 56,
    width: 340,
    balancedScale: 0.7,
    variants: {
      light: _ReMarkableLightSvg,
      dark: _ReMarkableDarkSvg,
    } as LogoVariant,
  },
  remix: {
    height: 70,
    width: 280,
    balancedScale: 0.9,
    variants: {
      light: _RemixLightSvg,
      dark: _RemixDarkSvg,
    } as LogoVariant,
  },
  remote: {
    height: 62,
    width: 280,
    balancedScale: 0.8,
    variants: {
      light: _RemoteLightSvg,
      dark: _RemoteDarkSvg,
    } as LogoVariant,
  },
  replicate: {
    height: 66,
    width: 280,
    balancedScale: 0.8,
    variants: {
      light: _ReplicateLightSvg,
      dark: _ReplicateDarkSvg,
    } as LogoVariant,
  },
  'resolute-capital-partners': {
    height: 113,
    width: 110,
    balancedScale: 1.4,
    variants: {
      'color-light': _ResoluteCapitalPartnersColorLightSvg,
      light: _ResoluteCapitalPartnersLightSvg,
      dark: _ResoluteCapitalPartnersDarkSvg,
    } as LogoVariant,
  },
  retool: {
    height: 59,
    width: 270,
    balancedScale: 0.7,
    variants: {
      light: _RetoolLightSvg,
      dark: _RetoolDarkSvg,
    } as LogoVariant,
  },
  rightpoint: {
    height: 47,
    width: 280,
    balancedScale: 0.6,
    variants: {
      'color-light': _RightpointColorLightSvg,
      'color-dark': _RightpointColorDarkSvg,
      light: _RightpointLightSvg,
      dark: _RightpointDarkSvg,
    } as LogoVariant,
  },
  rippling: {
    height: 47,
    width: 330,
    balancedScale: 0.6,
    variants: {
      light: _RipplingLightSvg,
      dark: _RipplingDarkSvg,
    } as LogoVariant,
  },
  rollin: {
    height: 65,
    width: 240,
    balancedScale: 0.8,
    variants: {
      'color-light': _RollinColorLightSvg,
      'color-dark': _RollinColorDarkSvg,
      light: _RollinLightSvg,
      dark: _RollinDarkSvg,
    } as LogoVariant,
  },
  ruggable: {
    height: 69,
    width: 300,
    balancedScale: 0.9,
    variants: {
      light: _RuggableLightSvg,
      dark: _RuggableDarkSvg,
    } as LogoVariant,
  },
  runway: {
    height: 63,
    width: 300,
    balancedScale: 0.8,
    variants: {
      light: _RunwayLightSvg,
      dark: _RunwayDarkSvg,
    } as LogoVariant,
  },
  'runway-logomark': {
    height: 120,
    width: 120,
    balancedScale: 1.5,
    variants: {
      light: _RunwayLogomarkLightSvg,
      dark: _RunwayLogomarkDarkSvg,
    } as LogoVariant,
  },
  rust: {
    height: 142,
    width: 140,
    balancedScale: 1.8,
    variants: {
      light: _RustLightSvg,
      dark: _RustDarkSvg,
    } as LogoVariant,
  },
  'rangle-logotype': {
    height: 60,
    width: 280,
    balancedScale: 0.8,
    variants: {
      'color-light': _RangleLogotypeColorLightSvg,
      'color-dark': _RangleLogotypeColorDarkSvg,
      light: _RangleLogotypeLightSvg,
      dark: _RangleLogotypeDarkSvg,
    } as LogoVariant,
  },
  'rangle-logo': {
    height: 82,
    width: 160,
    balancedScale: 1,
    variants: {
      'color-light': _RangleLogoColorLightSvg,
      'color-dark': _RangleLogoColorDarkSvg,
      light: _RangleLogoLightSvg,
      dark: _RangleLogoDarkSvg,
    } as LogoVariant,
  },
  's-two-eight': {
    height: 105,
    width: 150,
    balancedScale: 1.3,
    variants: {
      light: _STwoEightLightSvg,
      dark: _STwoEightDarkSvg,
    } as LogoVariant,
  },
  'saas-seattle-academy': {
    height: 89,
    width: 260,
    balancedScale: 1.1,
    variants: {
      light: _SaasSeattleAcademyLightSvg,
      dark: _SaasSeattleAcademyDarkSvg,
    } as LogoVariant,
  },
  saber: {
    height: 140,
    width: 140,
    balancedScale: 1.8,
    variants: {
      'color-light': _SaberColorLightSvg,
      'color-dark': _SaberColorDarkSvg,
      light: _SaberLightSvg,
      dark: _SaberDarkSvg,
    } as LogoVariant,
  },
  saleor: {
    height: 87,
    width: 240,
    balancedScale: 1.1,
    variants: {
      'color-light': _SaleorColorLightSvg,
      'color-dark': _SaleorColorDarkSvg,
      light: _SaleorLightSvg,
      dark: _SaleorDarkSvg,
    } as LogoVariant,
  },
  salesforce: {
    height: 142,
    width: 200,
    balancedScale: 1.8,
    variants: {
      'color-light': _SalesforceColorLightSvg,
      'color-dark': _SalesforceColorDarkSvg,
      light: _SalesforceLightSvg,
      dark: _SalesforceDarkSvg,
    } as LogoVariant,
  },
  samara: {
    height: 58,
    width: 280,
    balancedScale: 0.7,
    variants: {
      light: _SamaraLightSvg,
      dark: _SamaraDarkSvg,
    } as LogoVariant,
  },
  sanity: {
    height: 57,
    width: 280,
    balancedScale: 0.7,
    variants: {
      'color-light': _SanityColorLightSvg,
      'color-dark': _SanityColorDarkSvg,
      light: _SanityLightSvg,
      dark: _SanityDarkSvg,
    } as LogoVariant,
  },
  'santa-cruz': {
    height: 77,
    width: 240,
    balancedScale: 1,
    variants: {
      light: _SantaCruzLightSvg,
      dark: _SantaCruzDarkSvg,
    } as LogoVariant,
  },
  scale: {
    height: 66,
    width: 200,
    balancedScale: 0.8,
    variants: {
      light: _ScaleLightSvg,
      dark: _ScaleDarkSvg,
    } as LogoVariant,
  },
  'scully-logo': {
    height: 140,
    width: 141,
    balancedScale: 1.8,
    variants: {
      'color-light': _ScullyLogoColorLightSvg,
      'color-dark': _ScullyLogoColorDarkSvg,
      light: _ScullyLogoLightSvg,
      dark: _ScullyLogoDarkSvg,
    } as LogoVariant,
  },
  'scully-logotype': {
    height: 97,
    width: 280,
    balancedScale: 1.2,
    variants: {
      'color-light': _ScullyLogotypeColorLightSvg,
      'color-dark': _ScullyLogotypeColorDarkSvg,
      light: _ScullyLogotypeLightSvg,
      dark: _ScullyLogotypeDarkSvg,
    } as LogoVariant,
  },
  seismic: {
    height: 45,
    width: 240,
    balancedScale: 0.6,
    variants: {
      'color-light': _SeismicColorLightSvg,
      'color-dark': _SeismicColorDarkSvg,
      light: _SeismicLightSvg,
      dark: _SeismicDarkSvg,
    } as LogoVariant,
  },
  sentry: {
    height: 67,
    width: 300,
    balancedScale: 0.8,
    variants: {
      light: _SentryLightSvg,
      dark: _SentryDarkSvg,
    } as LogoVariant,
  },
  'servus-tv': {
    height: 108,
    width: 240,
    balancedScale: 1.4,
    variants: {
      light: _ServusTvLightSvg,
      dark: _ServusTvDarkSvg,
    } as LogoVariant,
  },
  shopify: {
    height: 83,
    width: 281,
    balancedScale: 1,
    variants: {
      'color-light': _ShopifyColorLightSvg,
      light: _ShopifyLightSvg,
      'color-dark': _ShopifyColorDarkSvg,
      dark: _ShopifyDarkSvg,
    } as LogoVariant,
  },
  shopware: {
    height: 65,
    width: 300,
    balancedScale: 0.8,
    variants: {
      'color-light': _ShopwareColorLightSvg,
      'color-dark': _ShopwareColorDarkSvg,
      light: _ShopwareLightSvg,
      dark: _ShopwareDarkSvg,
    } as LogoVariant,
  },
  shotgun: {
    height: 59,
    width: 310,
    balancedScale: 0.7,
    variants: {
      light: _ShotgunLightSvg,
      dark: _ShotgunDarkSvg,
    } as LogoVariant,
  },
  'simple-practice': {
    height: 68,
    width: 360,
    balancedScale: 0.9,
    variants: {
      'color-light': _SimplePracticeColorLightSvg,
      'color-dark': _SimplePracticeColorDarkSvg,
      light: _SimplePracticeLightSvg,
      dark: _SimplePracticeDarkSvg,
    } as LogoVariant,
  },
  'sitecore-logo': {
    height: 144,
    width: 144,
    balancedScale: 1.8,
    variants: {
      'color-light': _SitecoreLogoColorLightSvg,
      light: _SitecoreLogoLightSvg,
      'color-dark': _SitecoreLogoColorDarkSvg,
      dark: _SitecoreLogoDarkSvg,
    } as LogoVariant,
  },
  snyk: {
    height: 145,
    width: 280,
    balancedScale: 1.8,
    variants: {
      'color-light': _SnykColorLightSvg,
      'color-dark': _SnykColorDarkSvg,
      light: _SnykLightSvg,
      dark: _SnykDarkSvg,
    } as LogoVariant,
  },
  'software-one': {
    height: 85,
    width: 240,
    balancedScale: 1.1,
    variants: {
      light: _SoftwareOneLightSvg,
      dark: _SoftwareOneDarkSvg,
    } as LogoVariant,
  },
  solana: {
    height: 48,
    width: 320,
    balancedScale: 0.6,
    variants: {
      'color-light': _SolanaColorLightSvg,
      'color-dark': _SolanaColorDarkSvg,
      light: _SolanaLightSvg,
      dark: _SolanaDarkSvg,
    } as LogoVariant,
  },
  'solidjs-logo': {
    height: 120,
    width: 129,
    balancedScale: 1.5,
    variants: {
      'color-light': _SolidjsLogoColorLightSvg,
      'color-dark': _SolidjsLogoColorDarkSvg,
      light: _SolidjsLogoLightSvg,
      dark: _SolidjsLogoDarkSvg,
    } as LogoVariant,
  },
  'solidjs-logotype': {
    height: 71,
    width: 350,
    balancedScale: 0.9,
    variants: {
      'color-light': _SolidjsLogotypeColorLightSvg,
      'color-dark': _SolidjsLogotypeColorDarkSvg,
      light: _SolidjsLogotypeLightSvg,
      dark: _SolidjsLogotypeDarkSvg,
    } as LogoVariant,
  },
  songwhip: {
    height: 70,
    width: 260,
    balancedScale: 0.9,
    variants: {
      light: _SongwhipLightSvg,
      dark: _SongwhipDarkSvg,
    } as LogoVariant,
  },
  sonos: {
    height: 51,
    width: 260,
    balancedScale: 0.6,
    variants: {
      light: _SonosLightSvg,
      dark: _SonosDarkSvg,
    } as LogoVariant,
  },
  split: {
    height: 90,
    width: 240,
    balancedScale: 1.1,
    variants: {
      light: _SplitLightSvg,
      dark: _SplitDarkSvg,
    } as LogoVariant,
  },
  splunk: {
    height: 83,
    width: 260,
    balancedScale: 1,
    variants: {
      light: _SplunkLightSvg,
      dark: _SplunkDarkSvg,
    } as LogoVariant,
  },
  'staar-surgical': {
    height: 34,
    width: 440,
    balancedScale: 0.4,
    variants: {
      'color-light': _StaarSurgicalColorLightSvg,
      light: _StaarSurgicalLightSvg,
      'color-dark': _StaarSurgicalColorDarkSvg,
      dark: _StaarSurgicalDarkSvg,
    } as LogoVariant,
  },
  stability: {
    height: 59,
    width: 301,
    balancedScale: 0.7,
    variants: {
      light: _StabilityLightSvg,
      dark: _StabilityDarkSvg,
    } as LogoVariant,
  },
  stake: {
    height: 108,
    width: 200,
    balancedScale: 1.4,
    variants: {
      light: _StakeLightSvg,
      dark: _StakeDarkSvg,
    } as LogoVariant,
  },
  'star-tribune': {
    height: 53,
    width: 280,
    balancedScale: 0.7,
    variants: {
      'color-light': _StarTribuneColorLightSvg,
      'color-dark': _StarTribuneColorDarkSvg,
      light: _StarTribuneLightSvg,
      dark: _StarTribuneDarkSvg,
    } as LogoVariant,
  },
  'stencil-logo': {
    height: 107,
    width: 140,
    balancedScale: 1.3,
    variants: {
      light: _StencilLogoLightSvg,
      dark: _StencilLogoDarkSvg,
    } as LogoVariant,
  },
  'stencil-logotype': {
    height: 68,
    width: 300,
    balancedScale: 0.9,
    variants: {
      light: _StencilLogotypeLightSvg,
      dark: _StencilLogotypeDarkSvg,
    } as LogoVariant,
  },
  'stock-ai': {
    height: 69,
    width: 280,
    balancedScale: 0.9,
    variants: {
      'color-light': _StockAiColorLightSvg,
      light: _StockAiLightSvg,
      'color-dark': _StockAiColorDarkSvg,
      dark: _StockAiDarkSvg,
    } as LogoVariant,
  },
  strapi: {
    height: 68,
    width: 280,
    balancedScale: 0.9,
    variants: {
      'color-light': _StrapiColorLightSvg,
      'color-dark': _StrapiColorDarkSvg,
      light: _StrapiLightSvg,
      dark: _StrapiDarkSvg,
    } as LogoVariant,
  },
  stripe: {
    height: 84,
    width: 201,
    balancedScale: 1.1,
    variants: {
      'color-light': _StripeColorLightSvg,
      'color-dark': _StripeColorDarkSvg,
      light: _StripeLightSvg,
      dark: _StripeDarkSvg,
    } as LogoVariant,
  },
  'sum-up': {
    height: 81,
    width: 280,
    balancedScale: 1,
    variants: {
      light: _SumUpLightSvg,
      dark: _SumUpDarkSvg,
    } as LogoVariant,
  },
  suno: {
    height: 66,
    width: 271,
    balancedScale: 0.8,
    variants: {
      light: _SunoLightSvg,
      dark: _SunoDarkSvg,
    } as LogoVariant,
  },
  supabase: {
    height: 71,
    width: 350,
    balancedScale: 0.9,
    variants: {
      'color-light': _SupabaseColorLightSvg,
      light: _SupabaseLightSvg,
      'color-dark': _SupabaseColorDarkSvg,
      dark: _SupabaseDarkSvg,
    } as LogoVariant,
  },
  super: {
    height: 67,
    width: 320,
    balancedScale: 0.8,
    variants: {
      'color-light': _SuperColorLightSvg,
      light: _SuperLightSvg,
      'color-dark': _SuperColorDarkSvg,
      dark: _SuperDarkSvg,
    } as LogoVariant,
  },
  supreme: {
    height: 97,
    width: 280,
    balancedScale: 1.2,
    variants: {
      'color-light': _SupremeColorLightSvg,
      'color-dark': _SupremeColorDarkSvg,
      light: _SupremeLightSvg,
      dark: _SupremeDarkSvg,
    } as LogoVariant,
  },
  'sv-angel': {
    height: 122,
    width: 150,
    balancedScale: 1.5,
    variants: {
      'color-light': _SvAngelColorLightSvg,
      light: _SvAngelLightSvg,
      dark: _SvAngelDarkSvg,
    } as LogoVariant,
  },
  'svelte-logo': {
    height: 120,
    width: 99,
    balancedScale: 1.5,
    variants: {
      'color-light': _SvelteLogoColorLightSvg,
      'color-dark': _SvelteLogoColorDarkSvg,
      light: _SvelteLogoLightSvg,
      dark: _SvelteLogoDarkSvg,
    } as LogoVariant,
  },
  'svelte-logotype': {
    height: 76,
    width: 310,
    balancedScale: 1,
    variants: {
      'color-light': _SvelteLogotypeColorLightSvg,
      'color-dark': _SvelteLogotypeColorDarkSvg,
      light: _SvelteLogotypeLightSvg,
      dark: _SvelteLogotypeDarkSvg,
    } as LogoVariant,
  },
  swc: {
    height: 77,
    width: 220,
    balancedScale: 1,
    variants: {
      'color-light': _SwcColorLightSvg,
      'color-dark': _SwcColorDarkSvg,
      light: _SwcLightSvg,
      dark: _SwcDarkSvg,
    } as LogoVariant,
  },
  swell: {
    height: 82,
    width: 220,
    balancedScale: 1,
    variants: {
      'color-light': _SwellColorLightSvg,
      'color-dark': _SwellColorDarkSvg,
      light: _SwellLightSvg,
      dark: _SwellDarkSvg,
    } as LogoVariant,
  },
  swr: {
    height: 62,
    width: 260,
    balancedScale: 0.8,
    variants: {
      light: _SwrLightSvg,
      dark: _SwrDarkSvg,
    } as LogoVariant,
  },
  tablet: {
    height: 43,
    width: 280,
    balancedScale: 0.5,
    variants: {
      light: _TabletLightSvg,
      dark: _TabletDarkSvg,
    } as LogoVariant,
  },
  'tailored-brands': {
    height: 79,
    width: 420,
    balancedScale: 1,
    variants: {
      light: _TailoredBrandsLightSvg,
      dark: _TailoredBrandsDarkSvg,
    } as LogoVariant,
  },
  tailwind: {
    height: 51,
    width: 401,
    balancedScale: 0.6,
    variants: {
      'color-light': _TailwindColorLightSvg,
      light: _TailwindLightSvg,
      'color-dark': _TailwindColorDarkSvg,
      dark: _TailwindDarkSvg,
    } as LogoVariant,
  },
  takeda: {
    height: 94,
    width: 280,
    balancedScale: 1.2,
    variants: {
      'color-light': _TakedaColorLightSvg,
      'color-dark': _TakedaColorDarkSvg,
      light: _TakedaLightSvg,
      dark: _TakedaDarkSvg,
    } as LogoVariant,
  },
  target: {
    height: 60,
    width: 280,
    balancedScale: 0.8,
    variants: {
      'color-light': _TargetColorLightSvg,
      'color-dark': _TargetColorDarkSvg,
      light: _TargetLightSvg,
      dark: _TargetDarkSvg,
    } as LogoVariant,
  },
  teleport: {
    height: 71,
    width: 310,
    balancedScale: 0.9,
    variants: {
      light: _TeleportLightSvg,
      dark: _TeleportDarkSvg,
    } as LogoVariant,
  },
  'teleport-hq': {
    height: 92,
    width: 357,
    balancedScale: 1.2,
    variants: {
      'color-light': _TeleportHqColorLightSvg,
      light: _TeleportHqLightSvg,
      'color-dark': _TeleportHqColorDarkSvg,
      dark: _TeleportHqDarkSvg,
    } as LogoVariant,
  },
  'the-real-real': {
    height: 47,
    width: 340,
    balancedScale: 0.6,
    variants: {
      light: _TheRealRealLightSvg,
      dark: _TheRealRealDarkSvg,
    } as LogoVariant,
  },
  thinx: {
    height: 74,
    width: 240,
    balancedScale: 0.9,
    variants: {
      light: _ThinxLightSvg,
      dark: _ThinxDarkSvg,
    } as LogoVariant,
  },
  'thought-spot': {
    height: 66,
    width: 320,
    balancedScale: 0.8,
    variants: {
      light: _ThoughtSpotLightSvg,
      dark: _ThoughtSpotDarkSvg,
    } as LogoVariant,
  },
  'tiger-global': {
    height: 38,
    width: 381,
    balancedScale: 0.5,
    variants: {
      light: _TigerGlobalLightSvg,
      dark: _TigerGlobalDarkSvg,
    } as LogoVariant,
  },
  'titan-invest': {
    height: 59,
    width: 200,
    balancedScale: 0.7,
    variants: {
      light: _TitanInvestLightSvg,
      dark: _TitanInvestDarkSvg,
    } as LogoVariant,
  },
  tombras: {
    height: 85,
    width: 300,
    balancedScale: 1.1,
    variants: {
      'color-light': _TombrasColorLightSvg,
      'color-dark': _TombrasColorDarkSvg,
      light: _TombrasLightSvg,
      dark: _TombrasDarkSvg,
    } as LogoVariant,
  },
  'trip-advisor': {
    height: 76,
    width: 360,
    balancedScale: 1,
    variants: {
      'color-light': _TripAdvisorColorLightSvg,
      light: _TripAdvisorLightSvg,
      'color-dark': _TripAdvisorColorDarkSvg,
      dark: _TripAdvisorDarkSvg,
    } as LogoVariant,
  },
  'true-bridge': {
    height: 77,
    width: 361,
    balancedScale: 1,
    variants: {
      light: _TrueBridgeLightSvg,
      dark: _TrueBridgeDarkSvg,
    } as LogoVariant,
  },
  truesec: {
    height: 43,
    width: 280,
    balancedScale: 0.5,
    variants: {
      light: _TruesecLightSvg,
      dark: _TruesecDarkSvg,
    } as LogoVariant,
  },
  'tuft-needle': {
    height: 40,
    width: 340,
    balancedScale: 0.5,
    variants: {
      light: _TuftNeedleLightSvg,
      dark: _TuftNeedleDarkSvg,
    } as LogoVariant,
  },
  turso: {
    height: 71,
    width: 281,
    balancedScale: 0.9,
    variants: {
      light: _TursoLightSvg,
      dark: _TursoDarkSvg,
    } as LogoVariant,
  },
  twilio: {
    height: 82,
    width: 270,
    balancedScale: 1,
    variants: {
      'color-light': _TwilioColorLightSvg,
      'color-dark': _TwilioColorDarkSvg,
      light: _TwilioLightSvg,
      dark: _TwilioDarkSvg,
    } as LogoVariant,
  },
  typedream: {
    height: 58,
    width: 340,
    balancedScale: 0.7,
    variants: {
      'color-light': _TypedreamColorLightSvg,
      light: _TypedreamLightSvg,
      'color-dark': _TypedreamColorDarkSvg,
      dark: _TypedreamDarkSvg,
    } as LogoVariant,
  },
  typefully: {
    height: 58,
    width: 280,
    balancedScale: 0.7,
    variants: {
      'color-light': _TypefullyColorLightSvg,
      'color-dark': _TypefullyColorDarkSvg,
      light: _TypefullyLightSvg,
      dark: _TypefullyDarkSvg,
    } as LogoVariant,
  },
  'u-next': {
    height: 52,
    width: 280,
    balancedScale: 0.7,
    variants: {
      light: _UNextLightSvg,
      dark: _UNextDarkSvg,
    } as LogoVariant,
  },
  uber: {
    height: 72,
    width: 200,
    balancedScale: 0.9,
    variants: {
      light: _UberLightSvg,
      dark: _UberDarkSvg,
    } as LogoVariant,
  },
  umbraco: {
    height: 166,
    width: 140,
    balancedScale: 2.1,
    variants: {
      'color-light': _UmbracoColorLightSvg,
      'color-dark': _UmbracoColorDarkSvg,
      light: _UmbracoLightSvg,
      dark: _UmbracoDarkSvg,
    } as LogoVariant,
  },
  'umi-js': {
    height: 130,
    width: 126,
    balancedScale: 1.6,
    variants: {
      'color-light': _UmiJsColorLightSvg,
      'color-dark': _UmiJsColorDarkSvg,
      light: _UmiJsLightSvg,
      dark: _UmiJsDarkSvg,
    } as LogoVariant,
  },
  'under-armour': {
    height: 83,
    width: 140,
    balancedScale: 1,
    variants: {
      light: _UnderArmourLightSvg,
      dark: _UnderArmourDarkSvg,
    } as LogoVariant,
  },
  uniform: {
    height: 72,
    width: 281,
    balancedScale: 0.9,
    variants: {
      'color-light': _UniformColorLightSvg,
      'color-dark': _UniformColorDarkSvg,
      light: _UniformLightSvg,
      dark: _UniformDarkSvg,
    } as LogoVariant,
  },
  'uni-suiper': {
    height: 77,
    width: 320,
    balancedScale: 1,
    variants: {
      'color-light': _UniSuiperColorLightSvg,
      'color-dark': _UniSuiperColorDarkSvg,
      light: _UniSuiperLightSvg,
      dark: _UniSuiperDarkSvg,
    } as LogoVariant,
  },
  'united-surgical-partners': {
    height: 73,
    width: 360,
    balancedScale: 0.9,
    variants: {
      'color-light': _UnitedSurgicalPartnersColorLightSvg,
      'color-dark': _UnitedSurgicalPartnersColorDarkSvg,
      light: _UnitedSurgicalPartnersLightSvg,
      dark: _UnitedSurgicalPartnersDarkSvg,
    } as LogoVariant,
  },
  unity: {
    height: 95,
    width: 256,
    balancedScale: 1.2,
    variants: {
      light: _UnityLightSvg,
      dark: _UnityDarkSvg,
    } as LogoVariant,
  },
  universe: {
    height: 50,
    width: 363,
    balancedScale: 0.6,
    variants: {
      light: _UniverseLightSvg,
      dark: _UniverseDarkSvg,
    } as LogoVariant,
  },
  'unlikely-technology': {
    height: 72,
    width: 330,
    balancedScale: 0.9,
    variants: {
      light: _UnlikelyTechnologyLightSvg,
      dark: _UnlikelyTechnologyDarkSvg,
    } as LogoVariant,
  },
  'uplift-desk': {
    height: 54,
    width: 341,
    balancedScale: 0.7,
    variants: {
      light: _UpliftDeskLightSvg,
      dark: _UpliftDeskDarkSvg,
    } as LogoVariant,
  },
  upstart: {
    height: 61,
    width: 300,
    balancedScale: 0.8,
    variants: {
      'color-light': _UpstartColorLightSvg,
      light: _UpstartLightSvg,
      'color-dark': _UpstartColorDarkSvg,
      dark: _UpstartDarkSvg,
    } as LogoVariant,
  },
  upstash: {
    height: 83,
    width: 270,
    balancedScale: 1,
    variants: {
      'color-light': _UpstashColorLightSvg,
      'color-dark': _UpstashColorDarkSvg,
      light: _UpstashLightSvg,
      dark: _UpstashDarkSvg,
    } as LogoVariant,
  },
  valtech: {
    height: 55,
    width: 240,
    balancedScale: 0.7,
    variants: {
      light: _ValtechLightSvg,
      dark: _ValtechDarkSvg,
    } as LogoVariant,
  },
  varo: {
    height: 68,
    width: 180,
    balancedScale: 0.9,
    variants: {
      light: _VaroLightSvg,
      dark: _VaroDarkSvg,
    } as LogoVariant,
  },
  velir: {
    height: 67,
    width: 240,
    balancedScale: 0.8,
    variants: {
      light: _VelirLightSvg,
      dark: _VelirDarkSvg,
    } as LogoVariant,
  },
  verkada: {
    height: 55,
    width: 320,
    balancedScale: 0.7,
    variants: {
      light: _VerkadaLightSvg,
      dark: _VerkadaDarkSvg,
    } as LogoVariant,
  },
  viable: {
    height: 87,
    width: 260,
    balancedScale: 1.1,
    variants: {
      'color-light': _ViableColorLightSvg,
      'color-dark': _ViableColorDarkSvg,
      light: _ViableLightSvg,
      dark: _ViableDarkSvg,
    } as LogoVariant,
  },
  'village-roadshow-pictures': {
    height: 90,
    width: 320,
    balancedScale: 1.1,
    variants: {
      light: _VillageRoadshowPicturesLightSvg,
      dark: _VillageRoadshowPicturesDarkSvg,
    } as LogoVariant,
  },
  vimeo: {
    height: 71,
    width: 240,
    balancedScale: 0.9,
    variants: {
      light: _VimeoLightSvg,
      dark: _VimeoDarkSvg,
    } as LogoVariant,
  },
  virgin: {
    height: 143,
    width: 140,
    balancedScale: 1.8,
    variants: {
      'color-light': _VirginColorLightSvg,
      'color-dark': _VirginColorDarkSvg,
      light: _VirginLightSvg,
      dark: _VirginDarkSvg,
    } as LogoVariant,
  },
  vite: {
    height: 141,
    width: 140,
    balancedScale: 1.8,
    variants: {
      'color-light': _ViteColorLightSvg,
      'color-dark': _ViteColorDarkSvg,
      light: _ViteLightSvg,
      dark: _ViteDarkSvg,
    } as LogoVariant,
  },
  vizient: {
    height: 70,
    width: 280,
    balancedScale: 0.9,
    variants: {
      'color-light': _VizientColorLightSvg,
      'color-dark': _VizientColorDarkSvg,
      light: _VizientLightSvg,
      dark: _VizientDarkSvg,
    } as LogoVariant,
  },
  volvo: {
    height: 32,
    width: 400,
    balancedScale: 0.4,
    variants: {
      light: _VolvoLightSvg,
      dark: _VolvoDarkSvg,
    } as LogoVariant,
  },
  voxmedia: {
    height: 43,
    width: 310,
    balancedScale: 0.5,
    variants: {
      light: _VoxmediaLightSvg,
      dark: _VoxmediaDarkSvg,
    } as LogoVariant,
  },
  vsco: {
    height: 119,
    width: 280,
    balancedScale: 1.5,
    variants: {
      light: _VscoLightSvg,
      dark: _VscoDarkSvg,
    } as LogoVariant,
  },
  vue: {
    height: 122,
    width: 140,
    balancedScale: 1.5,
    variants: {
      'color-light': _VueColorLightSvg,
      'color-dark': _VueColorDarkSvg,
      light: _VueLightSvg,
      dark: _VueDarkSvg,
    } as LogoVariant,
  },
  walmart: {
    height: 72,
    width: 290,
    balancedScale: 0.9,
    variants: {
      'color-light': _WalmartColorLightSvg,
      'color-dark': _WalmartColorDarkSvg,
      light: _WalmartLightSvg,
      dark: _WalmartDarkSvg,
    } as LogoVariant,
  },
  wander: {
    height: 66,
    width: 290,
    balancedScale: 0.8,
    variants: {
      light: _WanderLightSvg,
      dark: _WanderDarkSvg,
    } as LogoVariant,
  },
  'washington-post': {
    height: 64,
    width: 420,
    balancedScale: 0.8,
    variants: {
      light: _WashingtonPostLightSvg,
      dark: _WashingtonPostDarkSvg,
    } as LogoVariant,
  },
  wayfair: {
    height: 57,
    width: 260,
    balancedScale: 0.7,
    variants: {
      'color-light': _WayfairColorLightSvg,
      'color-dark': _WayfairColorDarkSvg,
      light: _WayfairLightSvg,
      dark: _WayfairDarkSvg,
    } as LogoVariant,
  },
  wellfound: {
    height: 44,
    width: 340,
    balancedScale: 0.6,
    variants: {
      'color-light': _WellfoundColorLightSvg,
      light: _WellfoundLightSvg,
      dark: _WellfoundDarkSvg,
    } as LogoVariant,
  },
  whoop: {
    height: 38,
    width: 240,
    balancedScale: 0.5,
    variants: {
      light: _WhoopLightSvg,
      dark: _WhoopDarkSvg,
    } as LogoVariant,
  },
  'whitehat-jr': {
    height: 81,
    width: 300,
    balancedScale: 1,
    variants: {
      light: _WhitehatJrLightSvg,
      dark: _WhitehatJrDarkSvg,
    } as LogoVariant,
  },
  whop: {
    height: 61,
    width: 296,
    balancedScale: 0.8,
    variants: {
      'color-light': _WhopColorLightSvg,
      light: _WhopLightSvg,
      'color-dark': _WhopColorDarkSvg,
      dark: _WhopDarkSvg,
    } as LogoVariant,
  },
  wix: {
    height: 70,
    width: 180,
    balancedScale: 0.9,
    variants: {
      light: _WixLightSvg,
      dark: _WixDarkSvg,
    } as LogoVariant,
  },
  wiz: {
    height: 95,
    width: 190,
    balancedScale: 1.2,
    variants: {
      'color-light': _WizColorLightSvg,
      'color-dark': _WizColorDarkSvg,
      light: _WizLightSvg,
      dark: _WizDarkSvg,
    } as LogoVariant,
  },
  wpp: {
    height: 77,
    width: 256,
    balancedScale: 1,
    variants: {
      'color-light': _WppColorLightSvg,
      light: _WppLightSvg,
      dark: _WppDarkSvg,
    } as LogoVariant,
  },
  'wunderman-thompson': {
    height: 100,
    width: 280,
    balancedScale: 1.3,
    variants: {
      light: _WundermanThompsonLightSvg,
      dark: _WundermanThompsonDarkSvg,
    } as LogoVariant,
  },
  webpack: {
    height: 92,
    width: 320,
    balancedScale: 1.2,
    variants: {
      'color-light': _WebpackColorLightSvg,
      'color-dark': _WebpackColorDarkSvg,
      light: _WebpackLightSvg,
      dark: _WebpackDarkSvg,
    } as LogoVariant,
  },
  yse: {
    height: 139,
    width: 190,
    balancedScale: 1.7,
    variants: {
      light: _YseLightSvg,
      dark: _YseDarkSvg,
    } as LogoVariant,
  },
  zapier: {
    height: 71,
    width: 260,
    balancedScale: 0.9,
    variants: {
      'color-light': _ZapierColorLightSvg,
      'color-dark': _ZapierColorDarkSvg,
      light: _ZapierLightSvg,
      dark: _ZapierDarkSvg,
    } as LogoVariant,
  },
  zola: {
    height: 140,
    width: 140,
    balancedScale: 1.8,
    variants: {
      light: _ZolaLightSvg,
      dark: _ZolaDarkSvg,
    } as LogoVariant,
  },
  zora: {
    height: 79,
    width: 227,
    balancedScale: 1,
    variants: {
      light: _ZoraLightSvg,
      dark: _ZoraDarkSvg,
    } as LogoVariant,
  },
} as const;

// Do not edit this file manually. Auto-generated by geist 'pnpm generate-logos'.
interface LogoVariant {
  light: {
    src: string;
  };
  dark: {
    src: string;
  };
  'color-light'?: {
    src: string;
  };
  'color-dark'?: {
    src: string;
  };
}
export type LogoName = keyof typeof logos;
export const logoNames = Object.keys(logos) as LogoName[];
