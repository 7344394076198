import type { ImageProps } from 'next/image';
import Image from 'next/image';
import { clsx } from 'clsx';
import type { JSX } from 'react';
import type { LogoName } from './logos';
import { logos } from './logos';

type ExtraImageProps = Omit<ImageProps, 'src' | 'alt' | 'width' | 'height'>;

export interface LogoProps extends ExtraImageProps {
  name: LogoName;
  balanced?: boolean;
  colored?: boolean;
  monochromeDark?: boolean;
  height?: number;
  lazy?: boolean;
}

export function Logo({
  colored,
  balanced,
  monochromeDark,
  ...props
}: LogoProps): JSX.Element {
  const logo = logos[props.name];
  const { width, height, balancedScale, variants } = logo;

  if (balanced && typeof props.height !== 'number') {
    throw new Error(
      'You must specify a height when using the balanced prop on a Logo.',
    );
  }

  const variantHeight = props.height ?? height;
  const ratio = width / height;
  const scale = !balanced ? 1 : balancedScale;

  const variantProps = {
    ...props,
    alt: `${props.name} Logo`,
    width: variantHeight * ratio * scale,
    height: variantHeight * scale,
  };

  const lightClassName = clsx('geist-hide-on-dark', props.className);
  const darkClassName = clsx('geist-hide-on-light', props.className);

  return (
    <>
      {colored && variants['color-light'] ? (
        <LogoVariation
          {...variantProps}
          className={lightClassName}
          src={variants['color-light'].src}
        />
      ) : (
        <LogoVariation
          {...variantProps}
          className={lightClassName}
          src={variants.light.src}
        />
      )}
      {colored && variants['color-dark'] && !monochromeDark ? (
        <LogoVariation
          {...variantProps}
          className={darkClassName}
          src={variants['color-dark'].src}
        />
      ) : (
        <LogoVariation
          {...variantProps}
          className={darkClassName}
          src={variants.dark.src}
        />
      )}
    </>
  );
}

function LogoVariation({
  src,
  width,
  height,
  className,
  style,
  name,
  ...props
}: ExtraImageProps & {
  src: string;
  width: number;
  height: number;
  name: string;
  lazy?: boolean;
}): JSX.Element | null {
  if (!src) return null;
  return (
    <Image
      alt={`${name} Logo`}
      className={className}
      height={height}
      loading={props.lazy ? 'lazy' : 'eager'}
      src={src}
      style={style}
      width={width}
      {...props}
    />
  );
}
